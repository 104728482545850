import {
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import React, { useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../../store'
import { getUserSpecialties } from '../../store/case/actions'

interface IDataGridTableHeaderProps {
  title: string
  onHandleSearch: (searchInput: string) => void
}

const DataGridTableHeader: React.FC<IDataGridTableHeaderProps> = ({
  title,
  onHandleSearch,
}) => {
  const userdata = useAppSelector((state) => state.auth.userdata)
  const specialtyList = useAppSelector((state) => state.case.specialtyList)

  const dispatch = useAppDispatch()

  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    const practiceData = {
      practiceId: userdata.practiceId,
      fetchUsers: false,
    }
    dispatch(getUserSpecialties(practiceData))
  }, [dispatch, userdata.practiceId])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { name, value } = e.target
    if (name === 'selectedSpecialty') {
      setSearchInput(value)
    }
    onHandleSearch(value)
  }

  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        style={{
          paddingTop: 6,
          paddingLeft: 15,
          paddingRight: 15,
          paddingBottom: 20,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '200px',
        }}
      >
        <Typography color="textSecondary" variant="h5">
          {title}
        </Typography>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <TextField
          fullWidth
          select
          id="outlined-adornment-password"
          size="small"
          variant="outlined"
          type={'text'}
          label="Filter by Specialty"
          name={'selectedSpecialty'}
          value={searchInput}
          onChange={handleSearch}
          data-selector="filter-by-specialty"
          style={{
            display:
              userdata.userType === 'Admin' || userdata.userType === 'PCP'
                ? 'flex'
                : 'none',
          }}
        >
          <MenuItem
            data-selector="all-specialties"
            key={'All Specialties'}
            value={'All Specialties'}
          >
            {'All Specialties'}
          </MenuItem>
          {specialtyList.map((specialty) => (
            <MenuItem
              data-selector={specialty.value}
              key={specialty.id}
              value={specialty.value}
            >
              {specialty.title}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search Patient Name"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Grid>
    </Grid>
  )
}

export default DataGridTableHeader
