import { makeStyles, Theme } from '@material-ui/core'

export const useMyDocumentStyles = makeStyles((theme: Theme) => ({
  body: {
    paddingTop: 0,
    marginTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 0,
  },
  buttonDiv: {
    paddingTop: 0,
    paddingBottom: 10,
    textAlign: 'right',
  },
  title: {
    paddingTop: 0,
    marginTop: 0,
    fontSize: 35,
    textAlign: 'center',
    fontFamily: 'Times-Roman',
  },
  author: {
    fontSize: 20,
    textAlign: 'center',
    marginTop: 12,
    marginBottom: 24,
  },
  demoTitle: {
    fontSize: 20,
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 0,
  },
  demo: {
    fontSize: 13,
    textAlign: 'left',
    marginTop: 12,
    marginBottom: 24,
  },
  legal: {
    textAlign: 'center',
    fontSize: 20,
    //width: "90%",
    alignSelf: 'center',
    fontFamily: 'Times-Roman',
  },
  detail: {
    fontSize: 10,
    margin: 5,
    fontFamily: 'Times-Roman',
  },
  text: {
    margin: 5,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    margin: 10,
    height: 300,
    width: '46%',
    //float:"left"
  },
  hide: { display: 'none' },
  show: {
    display: '',
  },
  docContainer: {
    width: '98%',
    height: 'auto',
    float: 'left',
  },
  closedCasePdfCl: {
    paddingTop: 0,
    marginTop: 0,
    overflow: 'auto',
    width: '95%',
  },
}))
