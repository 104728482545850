import { makeStyles, Theme } from '@material-ui/core'

export const useChatStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 10,
    marginBottom: 0,
  },
  chattingBox: {
    width: '100%',
    height: '37vh',
    boxShadow: 'none',
    overflow: 'auto',
  },
  chatSpecialist: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 25,
    },
    paddingTop: 0,
    paddingLeft: 10,
  },
  chatPatient: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 22,
    },
  },
  inputWrapper: {
    borderRadius: 24,
    height: '100%',
    backgroundColor: '#F1F1F1',
    boxShadow: 'none',
    padding: theme.spacing(1, 2, 1, 2),
  },
  bottom: {
    width: '100%',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    backgroundColor: '#fff',
  },
  sendMessageItemWrapper: {
    display: 'flex',
    float: 'right',
    backgroundColor: '#EBF2FB',
    padding: 10,
    borderRadius: 10,
    margin: 10,
    maxWidth: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
    },
    position: 'relative',
  },
  receiveMessageItemWrapper: {
    display: 'flex',
    float: 'left',
    backgroundColor: '#F4F4F4',
    padding: 10,
    borderRadius: 10,
    margin: 10,
    maxWidth: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
    },
    position: 'relative',
  },
  buttonCenter: {
    margin: 'auto',
  },
  senderNameLabel: {
    position: 'absolute',
    top: -24,
    left: 5,
  },
  senderMessageContainer: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
  receiveNameLabel: {
    position: 'absolute',
    top: -25,
    left: 2,
    height: 20,
    width: 400,
  },
  receiveMessageContainer: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
  senderDate: {
    position: 'absolute',
    fontSize: 9,
    bottom: -15,
    right: 5,
    width: 100,
    textAlign: 'right',
  },
  receiverDate: {
    position: 'absolute',
    fontSize: 9,
    bottom: -15,
    width: 100,
    textAlign: 'left',
  },
}))
