import {
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Cookies from 'universal-cookie'

import textLogo from '../../shared/assets/images/text-logo.png'
import { useAppDispatch, useAppSelector } from '../../shared/store'
import { loginUser } from '../../shared/store/auth/actions'
import { decodeString, encodeString } from '../../shared/utils/functions'
import { useSignInPageStyles } from './SignInPage.styles'

const cookies = new Cookies()

interface MatchParams {
  [key: string]: string
}

interface MockType {
  [key: string]: string
}

interface LocationState {
  from: string
}

const SignInPage: React.FC<
  RouteComponentProps<MatchParams, MockType, LocationState>
> = ({ history, location }) => {
  const classes = useSignInPageStyles()
  const dispatch = useAppDispatch()

  const auth = useAppSelector((state) => state.auth)
  const errors: any = useAppSelector((state) => state.errors)

  const getEmail = cookies.get('email')
  const getPassword = getEmail ? decodeString(cookies.get('password')) : ''
  const getRemember = getEmail ? true : false

  const [email, setEmail] = useState(getEmail)
  const [password, setPassword] = useState(getPassword)
  const [remember, setRemember] = useState(getRemember)

  const forgotPassword = () => {
    history.push({
      pathname: '/forgotpassword',
    })
  }

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleRememberCheck = () => {
    setRemember(!remember)
  }

  const previousLocation = location.state?.from
  useEffect(() => {
    if (auth.isAuthenticated) {
      const caseId = localStorage.getItem('caseId')
      if (previousLocation?.startsWith('/register/identification/hint/')) {
        history.push(previousLocation)
      } else if (caseId) {
        localStorage.removeItem('caseId')
        history.push('/?caseId=' + caseId)
      } else {
        history.push('/inbox/cases')
      }
    }
  }, [auth.isAuthenticated, history, previousLocation])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const userData = {
      email,
      password,
    }
    if (remember) {
      const encodedPassword = encodeString(password)
      cookies.set('email', email, { path: '/' })
      cookies.set('password', encodedPassword, { path: '/' })
    } else {
      cookies.remove('email', { path: '/' })
      cookies.remove('password', { path: '/' })
    }
    dispatch(loginUser(userData))
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div style={{ display: 'flex' }}>
          <img src={textLogo} alt="required" style={{ width: '100%' }} />
        </div>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>

        {/* Added the error message for Email and Password fields here */}
        <Typography
          data-testid="signin-errors"
          color="error"
          className={classes.errorText}
        >
          {errors.emailNotFound}
          {errors.passwordIncorrect}
        </Typography>

        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="off"
            autoFocus
            value={email}
            onChange={onEmailChange}
            className={classnames('', {
              invalid: errors.email || errors.emailNotFound,
            })}
          />
          <Typography color="error" className={classes.errorText}>
            {errors.email}
          </Typography>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            autoComplete="off"
            onChange={onPasswordChange}
            className={classnames('', {
              invalid: errors.password || errors.passwordIncorrect,
            })}
          />
          <Typography color="error" className={classes.errorText}>
            {errors.password}
          </Typography>

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
            checked={remember}
            onChange={handleRememberCheck}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            data-testid="signin-submit-btn"
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs style={{ display: 'flex', justifyContent: 'center' }}>
              <Link
                style={{ cursor: 'pointer' }}
                onClick={forgotPassword}
                variant="body2"
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

export default SignInPage
