import { Fab, Hidden } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
//import AddIcon from "@material-ui/icons/Add";
import CreateIcon from '@material-ui/icons/Create'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const styles = (theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3.5),
    zIndex: 9,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
})

export class CreateButton extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <>
        {(this.props.auth?.userType === 'PCP' || this.props.auth?.userType === 'PCPSUPERUSER') && (
          <div>
            <Hidden smUp>
              <Fab
                color="primary"
                aria-label="Create Case"
                component={Link}
                to="/create-new-case"
                variant="extended"
                className={classes.fab}
                data-selector="create-new-case"
              >
                <CreateIcon className={classes.extendedIcon} />
                Create a Case
              </Fab>
            </Hidden>
          </div>
        )}
      </>
    )
  }
}

CreateButton.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth.userdata,
})

export default connect(mapStateToProps, { Link })(
  withStyles(styles)(CreateButton)
)
