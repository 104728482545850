import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  Modal,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
// import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../shared/store'
import {
  clearAthenaDataAndCurrentCaseData,
  createAthenaClaimAndModifyCase,
  getAthenaPatientDiagnoses,
  getDiagnoses,
  getListOfAthenaProcedureCodes,
  modifyCase,
  setCurrentCase,
} from '../../shared/store/case/actions'
import { sendAmplitudeData } from '../../shared/utils/amplitude'
import { amplitudeEvents } from '../../shared/utils/constant'
import _ from '../../shared/utils/lodashExtensions'
import Chat from '../Inbox/Chat/Chat'
import InsideMessageRightCard from '../Inbox/InsideMessageRightCard'
import MyDocument from '../MyDocument'
import { useInsideMessagePageStyles } from './InsideMessagePage.styles'

interface MatchParams {
  channelId: string
}

interface MockType {
  [key: string]: string
}

interface LocationState {
  from: string
}

interface IAdditionFromChild {
  downloadPDF: () => void
  savePDFFileToDrive: () => void
  closeCerboCaseAndUploadDocument: () => void
  closeAllInOneCaseAndUploadDocument: () => void
  createAthenaClinicalDocumentRecordAndModifyCase: () => void
  createElationDocumentReportRecordAndModifyCase: () => void
  createAthenaClinicalDocumentRecord: () => void
  sendCloseCaseFax: () => void
}

interface IDiagnoseICD10Code {
  code: string
  name: string
  group: string
}
interface ICPTCode {
  servicetype: string
  description: string
  procedurecode: string
}
interface ICPTCodeFormatted extends ICPTCode {
  group: string
  length: number
}

type IChildRef = (IAdditionFromChild & React.ReactNode) | null

const expanded = false

const InsideMessagePage: React.FC<
  RouteComponentProps<MatchParams, MockType, LocationState>
> = ({ location, match }) => {
  const classes = useInsideMessagePageStyles()

  const caseLoader = useAppSelector((state) => state.case.caseLoader)
  const currentCase = useAppSelector((state) => state.case.currentCase)
  const patientAthenaDiagnoses = useAppSelector(
    (state) => state.case.patientAthenaDiagnoses
  )
  const searchedDiagnoses = useAppSelector(
    (state) => state.case.searchedDiagnoses
  )

  const userdata = useAppSelector((state) => state.auth.userdata)
  const practiceData = useAppSelector((state) => state.auth.practiceData)
  const athenaPracticeId = useAppSelector(
    (state) => state.auth.practiceData?.athenaPracticeId
  )
  const elationPracticeId = useAppSelector(
    (state) => state.auth.practiceData?.elationPracticeId
  )
  const elationPatientId = useAppSelector(
    (state) => state.case.currentCase?.patientData?.elationPatientId
  )

  const [selectedCPTCodeList, setSelectedCPTCodeList] = useState<
    ICPTCodeFormatted[]
  >([])
  const [selectedDiagnoseCodeList, setSelectedDiagnoseCodeList] = useState<
    IDiagnoseICD10Code[]
  >([])

  const [diagnoseTerm, setDiagnoseTerm] = useState('')

  const [
    isOpenSelectDiagnosesAutocomplete,
    setIsOpenSelectDiagnosesAutocomplete,
  ] = useState(false)

  const [displayCloseCaseModal, setDisplayCloseCaseModal] = useState(false)

  const [displayCloseCaseAthenaModal, setDisplayCloseCaseAthenaModal] =
    useState(false)

  const [errorCloseCaseAthenaModal, setErrorCloseCaseAthenaModal] = useState('')
  const [
    isCheckedBillForThisConsultAthena,
    setIsCheckedBillForThisConsultAthena,
  ] = useState(true)

  const [caseDisplay, setCaseDisplay] = useState('')
  const [chatDisplay, setChatDisplay] = useState('')
  const [exportPDF, setExportPDF] = useState(false)

  const childRef = React.useRef<IChildRef>(null)

  const dispatch = useAppDispatch()

  useEffect(() => {
    // set the default value in diagnoses field in modal. Typical defaultValue prop in MaterialUI doesn't work.
    if (currentCase?.caseData?.diagnoses?.length) {
      setSelectedDiagnoseCodeList(currentCase.caseData.diagnoses)
    }
  }, [currentCase, currentCase.caseData])

  useEffect(() => {
    const { channelId } = match.params
    const currentCaseCondition = {
      caseId: channelId,
      currentUserRole: userdata.userType,
      athenaPracticeId,
    }
    dispatch(setCurrentCase(currentCaseCondition))
    return () => {
      dispatch(clearAthenaDataAndCurrentCaseData())
    }
  }, [
    athenaPracticeId,
    dispatch,
    match.params,
    practiceData,
    userdata.userType,
  ])

  // pull the data before openning the modal, because this request takes some time since it relates to Athena api
  useEffect(() => {
    if (
      athenaPracticeId &&
      currentCase.patientData?.athenaPatientId &&
      match.params.channelId
    ) {
      dispatch(
        getListOfAthenaProcedureCodes({
          athenaPatientId: currentCase.patientData.athenaPatientId,
          athenaPracticeId,
          practiceId: userdata.practiceId,
          athenaDepartmentId: 1,
        })
      )
    }
  }, [
    athenaPracticeId,
    currentCase.patientData,
    dispatch,
    match.params.channelId,
    userdata.practiceId,
  ])

  useEffect(() => {
    if (!diagnoseTerm) {
      return
    }
    // debounce
    const handler = setTimeout(
      () => dispatch(getDiagnoses({ term: diagnoseTerm })),
      1000
    )
    return () => clearTimeout(handler)
  }, [diagnoseTerm, dispatch])

  const athenaPatientId = currentCase.patientData?.athenaPatientId
  useEffect(() => {
    if (athenaPracticeId && athenaPatientId && match.params.channelId) {
      dispatch(
        getAthenaPatientDiagnoses({
          athenaPracticeId,
          practiceId: userdata.practiceId,
          athenaDepartmentId: 1,
          athenaPatientId,
        })
      )
    }
  }, [
    currentCase,
    practiceData,
    dispatch,
    match.params.channelId,
    athenaPracticeId,
    userdata.practiceId,
  ])

  const handleCloseButton = () => {
    if (athenaPracticeId && currentCase.patientData.athenaPatientId) {
      setDisplayCloseCaseAthenaModal(true)
    } else {
      setDisplayCloseCaseModal(true)
    }
  }

  const handleCloseModal = () => {
    setDisplayCloseCaseModal(false)
    setDisplayCloseCaseAthenaModal(false)
  }

  const reopenCase = () => {
    const { channelId } = match.params
    const newCaseData = {
      caseId: channelId,
      lastUpdatedById: userdata.id,
      isRead: true,
      isDeleted: false,
      currentUserRole: userdata.userType,
    }

    dispatch(modifyCase(newCaseData))
  }
  const downloadPDFfn = () => {
    childRef.current?.downloadPDF()
  }

  const createAthenaClinicalDocumentRecordAndModifyCase = () => {
    childRef.current?.createAthenaClinicalDocumentRecordAndModifyCase()
  }

  const createElationDocumentReportRecordAndModifyCase = () => {
    childRef.current?.createElationDocumentReportRecordAndModifyCase()
  }

  const createAthenaClinicalDocumentRecord = () => {
    childRef.current?.createAthenaClinicalDocumentRecord()
  }

  const savePdfFileToDrive = () => {
    childRef.current?.savePDFFileToDrive()
  }

  // const sendCloseCaseFax = () => {
  //   childRef.current?.sendCloseCaseFax()
  // }

  const handleViewCase = () => {
    setCaseDisplay('none')
    setChatDisplay('block')
  }

  const handleViewChat = () => {
    setCaseDisplay('block')
    setChatDisplay('none')
  }

  const handleCloseCase = () => {
    const { channelId } = match.params

    // closing case for athena patients
    if (displayCloseCaseAthenaModal) {
      if (isCheckedBillForThisConsultAthena) {
        if (selectedCPTCodeList.length && selectedDiagnoseCodeList.length) {
          dispatch(
            createAthenaClaimAndModifyCase({
              athenaPatientId: currentCase.patientData.athenaPatientId,
              athenaPracticeId,
              practiceId: practiceData.id,
              procedureCodeList: selectedCPTCodeList.map(
                (code) => code.procedurecode
              ),
              diagnoseCodeList: selectedDiagnoseCodeList.map(
                (item) => item.code
              ),
              caseId: channelId,
            })
          )
          createAthenaClinicalDocumentRecord()
          setDisplayCloseCaseAthenaModal(false)
        } else {
          setErrorCloseCaseAthenaModal('All fields must be filled.')
        }
      } else {
        createAthenaClinicalDocumentRecordAndModifyCase()
        setDisplayCloseCaseAthenaModal(false)
      }
    } else {
      const newCaseData = {
        caseId: channelId,
        lastUpdatedById: userdata.id,
        isRead: true,
        isDeleted: true,
        emailToID: true,
        practiceId: userdata.practiceId,
        currentUserRole: userdata.userType,
      }
      if (currentCase.patientData.cerboPatientId) {
        // closing case for cerbo patients
        childRef.current?.closeCerboCaseAndUploadDocument()
      } else if (currentCase.patientData.hintPatientId) {
        // closing case for allinone patients
        //TODO: decide on sticking w/ childRef here or using elation pattern below
        childRef.current?.closeAllInOneCaseAndUploadDocument()
      } else if (elationPracticeId && elationPatientId) {
        // closing case for elation patients
        createElationDocumentReportRecordAndModifyCase()
      } else {
        // closing case for other patients
        //dispatch(modifyCase(newCaseData))
        console.log(newCaseData)
      }

      const eventData = { ...userdata, ...{ caseId: channelId } }
      sendAmplitudeData(userdata.email, amplitudeEvents.CASE_CLOSED, eventData)

      setDisplayCloseCaseModal(false)

      if (exportPDF) {
        downloadPDFfn()
      }

      // if (practiceData?.faxNotification && practiceData?.faxNumber && practiceData?.faxNumber !== '') {
      //   sendCloseCaseFax()
      // }
    }

    savePdfFileToDrive()
  }

  const prevRoute = location.state && location.state.from
  const route = '/inbox/'.concat(prevRoute)
  const { channelId } = match.params

  const procedureCodesFormatted: ICPTCodeFormatted[] =
    currentCase.procedureCodes?.map((code: ICPTCode) =>
      code.procedurecode.includes('99452' || '99417' || '99358')
        ? { ...code, group: 'Commonly Selected Codes:' }
        : { ...code, group: 'Other codes:' }
    )

  const diagnosesFormatted = [
    patientAthenaDiagnoses?.map((diagnose) => ({
      ...diagnose,
      group: 'Recently Selected Diagnoses:',
    })),
    searchedDiagnoses?.map((diagnose) => ({
      ...diagnose,
      group: 'Searched Diagnoses:',
    })),
  ]
    .filter((diagnose) => diagnose !== undefined)
    .flat()

  const emrText =
    (athenaPracticeId && currentCase.patientData?.athenaPatientId) ||
    currentCase?.patientData?.cerboPatientId ||
    (elationPracticeId && elationPatientId)
      ? 'PDF will be uploaded to your EMR'
      : undefined

  return (
    <div className={classes.container}>
      <Grid container justifyContent="center" style={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={expanded ? 11 : 5}
          className={classes.mainBody}
        >
          <Grid container justifyContent="flex-start" style={{ width: '100%' }}>
            <Grid item xs={7}>
              <div style={{ display: 'flex' }}>
                <Link to={route} data-selector="back-to-inbox">
                  <Fab
                    color="inherit"
                    aria-label="add"
                    className={classes.addButton}
                  >
                    <ArrowBackIcon color="action" />
                  </Fab>
                </Link>
                <Typography color="textSecondary" className={classes.backFAB}>
                  Back to Inbox
                </Typography>
              </div>
            </Grid>
            <Grid item xs={5}>
              {!!currentCase?.caseData?.isDeleted ? (
                <div>
                  <Fab
                    color="default"
                    variant="extended"
                    aria-label="close-case"
                    className={classes.closeButton}
                    onClick={reopenCase}
                    style={{
                      display:
                        userdata.userType === 'PCP' ||
                        userdata.userType === 'PCPSUPERUSER'
                          ? 'flex'
                          : 'none',
                    }}
                  >
                    Reopen Case
                  </Fab>

                  <Fab
                    color="default"
                    variant="extended"
                    aria-label="close-case"
                    className={`${classes.closeButton}`}
                    onClick={downloadPDFfn}
                    style={{
                      backgroundColor: '#b2b2b2',
                      marginTop: 10,
                    }}
                  >
                    Download PDF
                  </Fab>
                </div>
              ) : (
                <Fab
                  disabled={caseLoader}
                  color="default"
                  variant="extended"
                  aria-label="close-case"
                  className={classes.closeButton}
                  onClick={handleCloseButton}
                  style={{
                    display:
                      userdata.userType === 'PCP' ||
                      userdata.userType === 'PCPSUPERUSER'
                        ? 'flex'
                        : 'none',
                  }}
                >
                  Close Case
                </Fab>
              )}
              <Hidden smUp>
                <Fab
                  color="default"
                  variant="extended"
                  className={classes.closeButton}
                  onClick={handleViewCase}
                  style={{ display: caseDisplay, marginTop: 10 }}
                >
                  View Data
                </Fab>
                <Fab
                  color="default"
                  variant="extended"
                  className={classes.closeButton}
                  onClick={handleViewChat}
                  style={{
                    display: chatDisplay ? chatDisplay : 'none',
                    marginTop: 10,
                  }}
                >
                  View Chat
                </Fab>
              </Hidden>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            className="grid-inside-left"
            style={{ height: '95%', width: '100%', display: caseDisplay }}
          >
            <Grid
              item
              xs={expanded ? 6 : 12}
              style={{ paddingTop: 10, height: '100%' }}
            >
              <Card className={classes.leftCard}>
                <Chat channelId={channelId} />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={expanded ? 1 : 7}
          className="grid-inside-right"
          style={{ height: '100%', display: chatDisplay }}
        >
          <InsideMessageRightCard expanded={expanded} />
        </Grid>
      </Grid>

      {/* CLOSE CASE MODAL  */}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-selector="close-case-modal"
        open={displayCloseCaseModal}
        onClose={handleCloseModal}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div className={classes.modalContainer}>
          <Card className={classes.modalCard}>
            <Typography className={classes.modalCardTitle}>
              Are you sure you want to close this case?
              <Typography style={{ marginTop: emrText ? 5 : 0 }}>
                {emrText}
              </Typography>
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="select_all"
                  data-selector="download-pdf-checkbox"
                  onChange={() => {
                    setExportPDF(!exportPDF)
                  }}
                  checked={exportPDF}
                />
              }
              label="Download a copy of the PDF to my computer"
            />
            <Fab
              color="default"
              variant="extended"
              aria-label="close-case"
              className={classes.closeCaseButtonInModal}
              onClick={handleCloseCase}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'Open Sans',
                    fontSize: 18,
                    color: '#FFF',
                  }}
                >
                  Close Consult
                </p>
                {athenaPracticeId && currentCase.patientData?.athenaPatientId && (
                  <p
                    style={{
                      fontWeight: 500,
                      fontFamily: 'Open Sans',
                      fontSize: 14,
                      color: '#FFF',
                    }}
                  >
                    Upload to EMR
                  </p>
                )}
              </div>
            </Fab>
            <Typography className={classes.modalCardDetail}>
              Closed cases will always be available for reference in the “Closed
              Cases” tab in your inbox.
            </Typography>
          </Card>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              paddingTop: 5,
            }}
          >
            <Fab
              variant="round"
              className={classes.modalCloseButton}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </Fab>
          </div>
        </div>
      </Modal>

      {/* CLOSE CASE MODAL MATCHED WITH ATHENA */}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-selector="close-modal-athena"
        open={displayCloseCaseAthenaModal}
        onClose={handleCloseModal}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div className={classes.modalContainer}>
          <Card className={classes.modalCard}>
            <Typography className={classes.modalCardTitle}>
              {`Close ${currentCase?.caseData?.specialty
                ?.map((item) => item.title)
                .join(', ')} Consult for ${currentCase?.patientData?.fullName}`}
            </Typography>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      data-selector="bill-for-consult-checkbox"
                      checked={isCheckedBillForThisConsultAthena}
                      onChange={() =>
                        setIsCheckedBillForThisConsultAthena(
                          !isCheckedBillForThisConsultAthena
                        )
                      }
                    />
                  }
                  label="Bill for this consult"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
            {isCheckedBillForThisConsultAthena && (
              <>
                <Typography style={{ color: 'red', fontSize: '11px' }}>
                  {errorCloseCaseAthenaModal}
                </Typography>
                <FormControl
                  style={{
                    width: '80%',
                    margin: '10px 0',
                    paddingTop: '4px',
                    maxHeight: '200px',
                  }}
                >
                  <Autocomplete
                    open={isOpenSelectDiagnosesAutocomplete}
                    onOpen={() => {
                      setIsOpenSelectDiagnosesAutocomplete(true)
                    }}
                    onClose={() => {
                      setIsOpenSelectDiagnosesAutocomplete(false)
                    }}
                    multiple
                    loading={isOpenSelectDiagnosesAutocomplete}
                    options={_.sortBy(diagnosesFormatted, ['group']) ?? []}
                    getOptionLabel={(option: IDiagnoseICD10Code) =>
                      `${option.code}: ${option.name}`
                    }
                    groupBy={(option) => {
                      return option.group
                    }}
                    value={selectedDiagnoseCodeList}
                    onChange={(_event, newValue) => {
                      if (newValue && newValue.length < 5) {
                        setSelectedDiagnoseCodeList(newValue)
                      }
                    }}
                    getOptionSelected={(option, value) => {
                      return value.code === option.code
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Max 4 Diagnoses"
                        data-selector="select-diagnoses"
                        onChange={(ev) => {
                          if (
                            ev.target.value !== '' ||
                            ev.target.value !== null
                          ) {
                            setDiagnoseTerm(ev.target.value)
                            setErrorCloseCaseAthenaModal('')
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isOpenSelectDiagnosesAutocomplete ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl
                  style={{
                    width: '80%',
                    margin: '10px 0',
                    paddingTop: '4px',
                    maxHeight: '200px',
                  }}
                >
                  <Autocomplete
                    multiple
                    loading={!procedureCodesFormatted?.length}
                    options={_.sortBy(procedureCodesFormatted, ['group']) ?? []}
                    getOptionLabel={(option: ICPTCodeFormatted) =>
                      `${option?.procedurecode}: ${option?.description}`
                    }
                    groupBy={(option) => {
                      return option.group
                    }}
                    value={selectedCPTCodeList}
                    onChange={(_event, newValue) => {
                      setSelectedCPTCodeList(newValue)
                    }}
                    getOptionSelected={() => false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        data-selector="Select CPT Code(s)"
                        label="Select CPT Code(s)"
                      />
                    )}
                  />
                </FormControl>
                {/* <Link
                  to={{
                    pathname: 'https://www.google.com/',
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography
                    color="primary"
                    style={{ display: 'flex', textDecoration: 'underline' }}
                  >
                    Learn more about common codes <OpenInNewIcon />
                  </Typography>
                </Link>*/}
              </>
            )}

            <Button
              color="primary"
              onClick={handleCloseCase}
              aria-label="close-case"
              variant="contained"
              style={{
                width: '80%',
                marginTop: '20px',
              }}
            >
              {isCheckedBillForThisConsultAthena
                ? 'Sign, Close, and Submit Claim'
                : 'Sign, Close, and Upload to EMR'}
            </Button>
            <Typography className={classes.modalCardDetail}>
              {isCheckedBillForThisConsultAthena
                ? `Closed cases will always be available for reference in the “Closed
              Cases” tab in your inbox. We do not verify any billing or coding
              information ensure that you have entered the correct codes and are
              able to bill for this consult.`
                : `Documentation will be automatically uploaded into Athena. Closed cases will
                always be available for reference in the “Closed Cases” tab in your inbox.`}
            </Typography>
          </Card>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              paddingTop: 5,
            }}
          >
            <Fab
              variant="round"
              className={classes.modalCloseButton}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </Fab>
          </div>
        </div>
      </Modal>

      <Card
        className={classes.modalCard}
        style={{
          display: 'none',
        }}
      >
        <MyDocument ref={childRef} />
      </Card>
    </div>
  )
}

export default InsideMessagePage
