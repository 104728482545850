import { makeStyles } from '@material-ui/core/styles'

export const useStepsStyles = makeStyles(() => ({
  stepIcon: {
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    zIndex: 1,
    alignItems: 'center',
    borderRadius: '50%',
    justifyContent: 'center',
    backgroundColor: '#ccc',
  },
  stepperMain: {
    float: 'left',
    width: '30%',
    marginTop: '8%',
    marginLeft: '5%',
  },
  connectorVertical: {
    padding: 0,
    marginLeft: 24,
  },
  connectorLine: {
    height: 70,
  },
  stepActive: {
    backgroundColor: '#13c1cf',
  },
  connectorHorizontal: {
    marginTop: 15,
  },
  stepLabelActive: {
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
}))
