import { Button, Card, CircularProgress, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
// import Papa from 'papaparse';
import axios from 'axios'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { getServerUrl } from '../../shared/utils/functions'
import _ from '../../shared/utils/lodashExtensions'
import { uploadFile } from '../../shared/utils/s3'

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    height: '100vh',
    display: 'flex',
    marginRight: 0,
    marginLeft: 0,
    justifyContent: 'center', //stretch, flex-start, center, flex-end, space-between, space-around
  },
  containCard: {
    width: '100%',
  },
  container: {
    width: '100%',
    height: `calc(100% - (90px))`,
    fallbacks: [
      { height: `-moz-calc(100% - (70px))` },
      { height: `-webkit-calc(100% - (70px))` },
      { height: `-o-calc(100% - (70px))` },
    ],
  },
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainText: {
    fontSize: 15,
    textAlign: 'center',
    color: '#818181',
  },
  // p: {
  //   textAlign: "center",
  // },
  // p: {
  //   fontSize: "1.3rem",
  // }
})

const acceptedImportFiles =
  'application/pdf, image/*, video/mp4,video/x-m4v,video/*, .doc, .docx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
class ImportPage extends Component {
  constructor() {
    super()
    this.state = {
      csvfile: undefined,
      oldFileList: [],
      filePaths: [],
      isLoading: false,
    }
  }

  uploadFile = async () => {
    this.setState({ isLoading: true })
    const { oldFileList, filePaths } = this.state
    const promise = await Promise.all(
      oldFileList.map(async (file) => {
        return await uploadFile(file)
      })
    )

    promise.map((path) => {
      path[0].url = path[1]
      filePaths.push(path[0])
    })

    axios
      .post(getServerUrl() + '/api/v1/case/getFileLink', filePaths)
      .then((res) => {
        this.setState({
          uploadedLink: res.data.data,
          isLoading: false,
          oldFileList: [],
        })
      })
      .catch((err) => {
        this.setState({ isLoading: false })
      })
  }

  onDrop = (uploadedFiles) => {
    const { oldFileList } = this.state
    oldFileList.push(uploadedFiles)
    const files = _.uniqBy(oldFileList.flat(), function (e) {
      return JSON.stringify(e)
    })
    this.setState({ oldFileList: files })
  }

  render() {
    const { classes } = this.props
    const { uploadedLink, oldFileList, isLoading } = this.state

    return (
      <div className={classes.root}>
        <Grid item xs={12} md={6} sm={9} lg={6} xl={6}>
          <Card className={classes.containCard}>
            <Dropzone data-cy="drag-and-drop" onDrop={this.onDrop}>
              {({ getRootProps, getInputProps }) => (
                <section className={classes.container}>
                  <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={{ height: '100%' }}
                  >
                    <input {...getInputProps()} />
                    <div className={classes.dropzone}>
                      <h2 style={{ lineHeight: '70px', textAlign: 'center' }}>
                        Import Data!
                      </h2>
                      <CloudUploadIcon style={{ width: 80, height: 80 }} />
                      <p className={classes.mainText}>
                        Click here or Drag/Drop to import data
                      </p>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                height: 70,
                alignItems: 'center',
                padding: 40,
              }}
            >
              <Button
                fullWidth
                size="large"
                variant="contained"
                onClick={this.uploadFile}
                color="primary"
                disabled={!Boolean(oldFileList.length >= 1)}
                data-selector="upload-btn"
              >
                Upload
              </Button>
            </div>
            {uploadedLink && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: 10,
                }}
              >
                <p style={{ color: '#0000EE' }}>
                  Upload Complete! &nbsp;
                  <a href={uploadedLink} target="_blank" rel="noreferrer">
                    Click here to download the uploaded file.
                  </a>
                </p>
              </div>
            )}
            {isLoading && (
              <CircularProgress
                disableShrink
                style={{
                  zIndex: 100,
                  position: 'relative',
                  top: '-20vh',
                  left: '48%',
                }}
              />
            )}
          </Card>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(ImportPage)
