import React from 'react'

interface IEmptyLayoutProps {
  children: React.ReactNode
}

const EmptyLayout: React.FC<IEmptyLayoutProps> = ({ children }) => {
  return (
    <div data-selector="empty-layout" style={{ height: 'inherit' }}>
      {children}
    </div>
  )
}

export default EmptyLayout
