import { makeStyles, Theme } from '@material-ui/core'

export const useAddPracticeMemberStyles = makeStyles((theme: Theme) => ({
  root: {},
  errorText: {
    fontSize: 12,
    fontWeight: 500,
  },
  cardBottom: {
    marginLeft: '0.5rem',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
}))
