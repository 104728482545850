import { makeStyles, Theme } from '@material-ui/core'

export const useDashboardStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 2,
    padding: theme.spacing(0),
    paddingTop: theme.spacing(8),
    overflowX: 'hidden',
    backgroundColor: '#f5f7ff',
  },
}))

export const useAdminLayoutStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 2,
    overflowX: 'hidden',
  },
}))

export const useAssignConsultLayoutStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 2,
    paddingLeft: theme.spacing(0),
    padding: theme.spacing(0),
    paddingTop: theme.spacing(8),
    backgroundColor: '#f5f7ff',
  },
}))

export const useInboxLayoutStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 2,
    paddingLeft: theme.spacing(0),
    padding: theme.spacing(0),
    paddingTop: theme.spacing(8),
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundColor: '#f5f7ff',
  },
}))

export const useInsideMessageLayoutStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 2,
    paddingLeft: theme.spacing(0),
    padding: theme.spacing(0),
    paddingTop: theme.spacing(8),
    backgroundColor: '#f5f7ff',
  },
}))

export const useNewCaseLayoutStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 2,
    overflowX: 'hidden',
    backgroundColor: '#f5f7ff',
  },
}))
