import base64 from 'base-64'
import utf8 from 'utf8'

import { env } from '../constants/env'

export const generateCaseID = (
  arg1: string | number,
  arg2: string | number
): string => {
  return `${arg1}${arg2}`
}

export const isEmpty = (obj: Record<string, unknown>): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

// export const getFileNameFromUrl=(url)=> {
//   if (url) {
//     var filename = url.substring(url.lastIndexOf('/') + 1)
//     return filename.split('.')[0]
//   }
// }

// export const getFileExtensionFromUrl=(url)=> {
//   var filename = url.substring(url.lastIndexOf('.') + 1)
//   return filename.split('?')[0]
// }

export const encodeString = (input: string): string => {
  const bytes = utf8.encode(input)
  const encoded = base64.encode(bytes)
  return encoded
}

export const decodeString = (input: string): string => {
  try {
    const bytes = base64.decode(input)
    const text = utf8.decode(bytes)
    return text
  } catch (e) {
    // console.error('could not decode: ', input);
    return 'invalid content'
  }
}

// export const convertTimeStampToDateAndTime=(timestamp)=> {
//   var day = new Date(Number(timestamp))
//   var date =
//     day.getFullYear() +
//     '-' +
//     ('0' + (day.getMonth() + 1)).slice(-2) +
//     '-' +
//     ('0' + day.getDate()).slice(-2)
//   var time =
//     ('0' + (day.getHours() + 1)).slice(-2) +
//     ':' +
//     ('0' + (day.getMinutes() + 1)).slice(-2)
//   return `${date} ${time}`
// }

export const getServerUrl = (): string => {
  return env.REACT_APP_API_SERVER ? env.REACT_APP_API_SERVER : ''
}

export const convertTimeStampToFormatDate = (timestamp: string): string => {
  const day = new Date(Number(timestamp))
  const month = day.toLocaleString('default', { month: 'short' })
  const date =
    month + '. ' + ('0' + day.getDate()).slice(-2) + ' ' + day.getFullYear()
  return `${date}`
}
export const convertTimeStampZToFormatDate = (timestamp: Date): string => {
  const day = new Date(timestamp)
  const month = day.toLocaleString('default', { month: 'short' })
  const date =
    month + '. ' + ('0' + day.getDate()).slice(-2) + ' ' + day.getFullYear()
  return `${date}`
}

export const isDateValid = (date: Date): boolean => {
  return !isNaN(date.getTime())
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isOfType = () => {
  // create a plain object with no prototype
  const type = Object.create(null)

  // check for null type
  type.null = (x) => x === null
  // check for undefined type
  type.undefined = (x) => x === undefined
  // check for nil type. Either null or undefined
  type.nil = (x) => type.null(x) || type.undefined(x)
  // check for strings and string literal type. e.g: 's', "s", `str`, new String()
  type.string = (x) =>
    !type.nil(x) && (typeof x === 'string' || x instanceof String)
  // check for number or number literal type. e.g: 12, 30.5, new Number()
  type.number = (x) =>
    !type.nil(x) && // NaN & Infinity have typeof "number" and this excludes that
    ((!isNaN(x) && isFinite(x) && typeof x === 'number') || x instanceof Number)
  // check for boolean or boolean literal type. e.g: true, false, new Boolean()
  type.boolean = (x) =>
    !type.nil(x) && (typeof x === 'boolean' || x instanceof Boolean)
  // check for array type
  type.array = (x) => !type.nil(x) && Array.isArray(x)
  // check for object or object literal type. e.g: {}, new Object(), Object.create(null)
  type.object = (x) => ({}.toString.call(x) === '[object Object]')
  // check for provided type instance
  type.type = (x, X) => !type.nil(x) && x instanceof X
  // check for set type
  type.set = (x) => type.type(x, Set)
  // check for map type
  type.map = (x) => type.type(x, Map)
  // check for date type
  type.date = (x) => type.type(x, Date)

  return type
}
