import { io } from 'socket.io-client'

import { IOnMessageReceivedEntry } from '../../components/Inbox/Chat/Chat'
import { env } from '../constants/env'

const socketInstance = () => {
  const socket = io(env.REACT_APP_WEB_SOCKET_URL!, {
    secure: true,
    reconnection: true,
    rejectUnauthorized: false,
    transports: ['websocket'],
    autoConnect: true,
  })

  function registerHandler(listener: (...args: any[]) => void) {
    console.info('register handler')
    socket.on('message', listener)
  }

  function unregisterHandler() {
    console.info('unregister handler')
    socket.off('message')
  }

  socket.on('error', (err) => {
    console.error('received socket error: ', err)
  })

  function register(...args: any[]) {
    socket.emit('register')
  }

  function join(chatroomName: string) {
    socket.emit('join', chatroomName)
  }

  function leave(chatroomName: string) {
    socket.emit('leave', chatroomName)
  }

  function message(room: string, newMessageData: IOnMessageReceivedEntry) {
    console.info('sending message')
    console.info(room)
    console.info(newMessageData)
    socket.emit('message', room, newMessageData)
  }

  function getChatrooms(...args: any[]) {
    socket.emit('chatrooms')
  }

  function getAvailableUsers(...args: any[]) {
    socket.emit('availableUsers', args)
  }

  return {
    register,
    join,
    leave,
    message,
    getChatrooms,
    getAvailableUsers,
    registerHandler,
    unregisterHandler,
  }
}

export const socketInstanceClient = socketInstance()
