/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'

import { getServerUrl } from './functions'

// TODO: add correct typings
export const getSignedUrl = async (
  operation: string,
  file?: any,
  type?: any
): Promise<any> => {
  const payload = { operation, file, type }
  return axios.post(getServerUrl() + '/api/v1/case/presign', payload)
}

// TODO: add correct typings
export const removeSignedUrl = async (
  file: any,
  type: any,
  operation: any
): Promise<any> => {
  const payload = { operation, file, type }
  return axios.post(getServerUrl() + '/api/v1/case/remove_file', payload)
}

// TODO: add correct typings
export const uploadFile = async (file: any): Promise<any> => {
  const method = 'put'
  let fileName = new Date().getTime() + '_' + file.name
  //fileName = fileName.split(' ').join('_');
  fileName = fileName.replace(/[^a-zA-Z0-9.]/g, '')
  const response = await getSignedUrl(method, fileName, file.type)
  await axios({
    method,
    url: response.data.url,
    headers: {
      'Content-Type': file.type,
      'Access-Control-Allow-Origin': '*',
    },
    data: file,
    transformRequest: [
      (data, headers) => {
        // do not pass on the global authorization header to AWS
        delete headers.common.Authorization
        return data
      },
    ],
  })
  const fileObj = {
    name: fileName.replace(/ /g, ''),
    type: file.type,
  }

  const fileResponse = await getSignedUrl('get', fileName)
  const fileUrl = fileResponse.data.url

  return [fileObj, fileUrl]
}

// TODO: add correct typings
export const removeFileFromS3 = async (
  file: any,
  index?: string | number
): Promise<any> => {
  const method = 'put'
  const res = await removeSignedUrl(file.name, file.type, method)
  if (res.data.status === 200) {
    return index || 1
  } else {
    throw new Error('Remove file from S3 has failed.')
  }
}
