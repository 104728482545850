import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { fetchUserData } from '../../shared/store/auth/actions'
import { modifyCase } from '../../shared/store/case/actions'
import _ from '../../shared/utils/lodashExtensions'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
  },
  table: {
    // minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableCellBold: {
    color: '#1C1C1C',
    fontSize: 20,
    fontWeight: 500,
  },
  tableCellLight: {
    color: '#1c1c1c',
    fontSize: 18,
    fontWeight: 'normal',
  },
}))

//This is not that imp in terms of testing

export function ConsultList(props) {
  const classes = useStyles()
  const [order] = React.useState('desc')
  const [orderBy] = React.useState('id')
  const [page, setPage] = React.useState(0)
  const [dense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [selectedConsult, setSelected] = React.useState(props.selectedConsult)
  const [selectedtemp, setSelectedtemp] = React.useState(null)
  const [emailTo, setEmailTo] = React.useState(null)
  const [currentCaseData] = React.useState(props.currentCaseData)

  let rows = [],
    rowsLength = 0
  if (props.rows) {
    rows = props.rows
    rowsLength = rows.length
  }
  //Confirm Dialog
  const [open, setOpen] = React.useState(false)
  const [unassignmodal, setUnassignmodal] = React.useState(false)

  const handleConfirmDialog = (e, id, emailOfSpecialist) => {
    setSelectedtemp(id)
    setEmailTo(emailOfSpecialist)
    setOpen(true)
  }

  const handleClose = () => {
    setEmailTo(null)
    setOpen(false)
  }

  const assignConsultant = (e, consultId, emailTo, caseData) => {
    console.log('in Assign')
    console.log('consultId: ' + consultId)
    console.log('emailTo: ' + emailTo)
    console.log(caseData)
    console.log('caseData: ' + caseData)

    if (consultId) {
      const { caseId } = props.params
      const newCaseData = {
        caseId: caseId,
        lastUpdatedById: props.userdata.id,
        specialistId: consultId,
        emailTo,
        action: 'assign',
        currentUserRole: props.userdata.userType,
      }
      let readByOthers = caseData.isReadByOther ? caseData.isReadByOther : []
      let ifIndex = _.indexOf(readByOthers, props.userdata.id)
      if (ifIndex < 0) {
        readByOthers.push(props.userdata.id)
        newCaseData['isReadByOther'] = readByOthers
      }
      let specialistIds = selectedConsult
      specialistIds.push(consultId)
      props.modifyCase(newCaseData)
      setSelected(specialistIds)
      setEmailTo(null)
      setOpen(false)
    }
  }

  const handleUnassignDialog = (e, id) => {
    setSelectedtemp(id)
    setUnassignmodal(true)
    setOpen(true)
  }

  const unassignConsultant = (e, consultId) => {
    const { caseId } = props.params
    const newCaseData = {
      caseId: caseId,
      lastUpdatedById: props.userdata.id,
      specialistId: consultId,
      action: 'unassign',
      currentUserRole: props.userdata.userType,
    }

    let specialistIds = selectedConsult
    let index = specialistIds.indexOf(consultId)
    if (index > -1) {
      specialistIds.splice(index, 1)
    }
    props.modifyCase(newCaseData)
    setSelected(specialistIds)
    setUnassignmodal(false)
    setOpen(false)
    //}
  }

  const handleUnassignClose = () => {
    setUnassignmodal(false)
    setOpen(false)
  }

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" align="center">
                  Select Specialist
                </TableCell>
                <TableCell align="center">First Name</TableCell>
                <TableCell align="center">Last Name</TableCell>
                <TableCell align="center">Specialty</TableCell>
                <TableCell align="center">Provider Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-selector="assign-specialist-list">
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      selected={selectedConsult.includes(row.id) ? true : false}
                      tabIndex={-1}
                      key={index}
                      data-selector={`specialist-row-${index}`}
                    >
                      <TableCell align="center" padding="checkbox">
                        <div>
                          {
                            //selectedConsult === row.id ?
                            selectedConsult.includes(row.id) ? (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={(event) =>
                                  handleUnassignDialog(event, row.id)
                                }
                              >
                                Unassign
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                onClick={(event) =>
                                  handleConfirmDialog(event, row.id, row.email)
                                }
                              >
                                Assign
                              </Button>
                            )
                          }
                        </div>
                      </TableCell>

                      <TableCell
                        align="center"
                        className={classes.tableCellBold}
                      >
                        {row.firstName ? row.firstName : null}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={classes.tableCellBold}
                      >
                        {row.lastName ? row.lastName : null}
                      </TableCell>

                      <TableCell
                        align="center"
                        className={classes.tableCellBold}
                      >
                        {row.specialty ? row.specialty : row.specialtyTitle ? row.specialtyTitle : null}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellBold}
                      >
                        {currentCaseData.providerFirstName ? currentCaseData.providerFirstName + ' ' + currentCaseData.providerLastName : null}
                      </TableCell>
                    </TableRow>
                  )
                })}

              {rowsLength === 0 && (
                <TableRow style={{ height: 49 }}>
                  <TableCell colSpan={6}> No Consult.. </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={unassignmodal ? handleUnassignClose : handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            data-selector="assign-unassign-specialist-dialog"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {'Please confirm'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to {unassignmodal ? 'unassign' : 'assign'}{' '}
                this specialist {unassignmodal ? 'from' : 'to'}{' '}
                {props.patientName}?
              </DialogContentText>
            </DialogContent>
            {unassignmodal ? (
              <DialogActions>
                <Button onClick={handleUnassignClose} color="primary">
                  No
                </Button>
                <Button
                  onClick={(event) => unassignConsultant(event, selectedtemp)}
                  color="primary"
                >
                  Yes
                </Button>
              </DialogActions>
            ) : (
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  No
                </Button>
                <Button
                  onClick={(event) =>
                    assignConsultant(
                      event,
                      selectedtemp,
                      emailTo,
                      props.currentCaseData
                    )
                  }
                  color="primary"
                >
                  Yes
                </Button>
              </DialogActions>
            )}
          </Dialog>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

ConsultList.propsTypes = {
  fetchUserData: PropTypes.func.isRequired,
  userdata: PropTypes.object.isRequired,
  modifyCase: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  userdata: state.auth.userdata,
})

export default connect(mapStateToProps, { fetchUserData, modifyCase })(
  ConsultList
)
