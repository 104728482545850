import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import AdminLayout from '../layouts/AdminLayout'

const AdminRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  if (!component) {
    throw Error('Component is Undefined')
  }
  const Component = component

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <AdminLayout>
          <Component {...matchProps} />
        </AdminLayout>
      )}
    />
  )
}

export default AdminRoute
