import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { AnyAction } from 'redux'

import { CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from './types'

export interface INotification {
  key: SnackbarKey
  message: SnackbarMessage
  options: OptionsObject
  dismissed: boolean
}

export interface INotificationState {
  notifications: INotification[]
}

const defaultState: INotificationState = {
  notifications: [],
}

const snackbarReducer = (
  state = defaultState,
  action: AnyAction
): INotificationState => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.payload.key,
            ...action.payload,
          },
        ],
      }

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification: INotification) =>
          action.payload.dismissAll || notification.key === action.payload.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      }

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification: INotification) =>
            notification.key !== action.payload.key
        ),
      }

    default:
      return state
  }
}

export default snackbarReducer
