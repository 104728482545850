import React from 'react'

import Header from '../Header/Header'
import HelpButton from '../Help/HelpButton'
import { useInsideMessageLayoutStyles } from './Layout.styles'

interface IInsideMessageLayoutProps {
  children: React.ReactNode
}

const InsideMessageLayout: React.FC<IInsideMessageLayoutProps> = ({
  children,
}) => {
  const classes = useInsideMessageLayoutStyles()
  return (
    <div data-selector="inside-message-layout" className={classes.root}>
      <Header />
      <main className={classes.content}>{children}</main>
      <HelpButton />
    </div>
  )
}

export default InsideMessageLayout
