import { Grid, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { useAppSelector } from '../../shared/store'
import { sendAmplitudeData } from '../../shared/utils/amplitude'
import { amplitudeEvents } from '../../shared/utils/constant'
import AccountProfile from '../Account/AccountProfile'
import AddPracticeMember from '../Account/AddPracticeMember/AddPracticeMember'
import CardDetails from '../Account/CardDetails'
import MemberInviteTable from '../Account/MemberInviteTable/MemberInviteTable'
import NotificationSettings from '../Account/NotificationSettings'
import Password from '../Account/Password'
//import ReferYourFriend from '../Account/ReferYourFriend/ReferYourFriend'
import ReferPcp from '../Account/ReferPcp'
import ReferSpecialist from '../Account/ReferSpecialist'

/*******
TODO:
- set number of invites in an account through practice db
*******/

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '60%',
    display: 'flex',
    marginRight: 0,
    marginLeft: 0,
    justifyContent: 'center', //flex-start, center, flex-end
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  uploadButton: {
    // width: "100%",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 15,
    },
  },
}))

const AccountPage: React.FC = (props) => {
  const classes = useStyles()

  const [isAvatarLoader, setIsAvatarLoader] = useState(false)
  const [showTable, setShowTable] = useState(true)
  const [showAddMember, setShowAddMember] = useState(false)

  const auth = useAppSelector((state) => state.auth)
  const errors = useAppSelector((state) => state.errors)
  // const dispatch = useAppDispatch()

  useEffect(() => {
    if (auth.userdata.avatar?.name || auth.userdata.avatar?.name === null) {
      setIsAvatarLoader(false)
    }
  }, [auth.userdata.avatar])

  useEffect(() => {
    sendAmplitudeData(
      auth.userdata.email,
      amplitudeEvents.ACCOUNT_PAGE,
      auth.userdata
    )
  }, [auth.userdata])

  const setLoader = (value: boolean) => {
    setIsAvatarLoader(value)
  }

  const handleShowTable = () => {
    setShowTable(true)
    setShowAddMember(false)
  }

  const handleShowAddMember = () => {
    setShowTable(false)
    setShowAddMember(true)
  }

  const propsToPass = {
    setLoader,
    auth,
    isAvatarLoader,
    errors,
  }

  return (
    <div className={classes.root} data-selector="account-page">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid item lg={8} md={7} xl={9} xs={12}>
            <AccountProfile {...propsToPass} />
          </Grid>

          <Grid item lg={8} md={7} xl={9} xs={12}>
            <NotificationSettings {...propsToPass} />
          </Grid>

          <Grid item lg={8} md={7} xl={9} xs={12}>
            <Password />
          </Grid>

          {auth.userdata.isAdmin && (
            <Grid item lg={8} md={7} xl={9} xs={12}>
              {/* @ts-ignore */}
              <CardDetails {...propsToPass} />
            </Grid>
          )}

          {auth.userdata.isAdmin && (
            <>
              {showTable && (
                <Grid item lg={8} md={7} xl={9} xs={12}>
                  <MemberInviteTable
                    handleShowAddMember={handleShowAddMember}
                  />
                </Grid>
              )}
              {showAddMember && (
                <Grid item lg={8} md={7} xl={9} xs={12}>
                  <AddPracticeMember handleShowTable={handleShowTable} />
                </Grid>
              )}
            </>
          )}
          {/*
          <Grid item lg={8} md={7} xl={9} xs={12}>
            <ReferYourFriend />
          </Grid>*/}

          {auth.userdata.userType === 'PCP' && (
            <>
              <Grid item lg={8} md={7} xl={9} xs={12}>
                <ReferPcp />
              </Grid>
              <Grid item lg={8} md={7} xl={9} xs={12}>
                <ReferSpecialist />
              </Grid>
            </>
          )}

          {auth.userdata.userType === 'Specialty' && (
            <>
              <Grid item lg={8} md={7} xl={9} xs={12}>
                <ReferSpecialist />
              </Grid>
              <Grid item lg={8} md={7} xl={9} xs={12}>
                <ReferPcp />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </div>
  )
}

export default AccountPage
