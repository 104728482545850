import {
  Card,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../shared/store'
import {
  modifyUserData,
  updatePracticeData,
} from '../../shared/store/auth/actions'

const useStyles = makeStyles(() => ({
  errorText: {
    fontSize: 12,
    fontWeight: 500,
    marginLeft: 15,
  },
}))

const NotificationSettings = (props) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const userdata = useAppSelector((state) => state.auth.userdata)
  const practiceData = useAppSelector((state) => state.auth.practiceData)

  const [checked, setChecked] = React.useState(userdata.notification)
  const [phoneChecked, setPhoneChecked] = React.useState(
    userdata.phoneNotification
  )
  const [faxChecked, setFaxChecked] = React.useState(
    practiceData?.faxNotification
  )
  const [errors, setErrors] = React.useState({})

  const handleChangeSwitch = (event) => {
    setChecked(event.target.checked)
    dispatch(
      modifyUserData({
        userID: userdata.id,
        notification: event.target.checked,
      })
    )
  }

  const handleTextChangeSwitch = (event) => {
    if (
      !userdata.phoneNumber ||
      userdata.phoneNumber === '' ||
      userdata.phoneNumber === null
    ) {
      setErrors({ phone: 'Please save your phone number before moving on.' })
    } else {
      setErrors({})
      setPhoneChecked(event.target.checked)
      dispatch(
        modifyUserData({
          userID: userdata.id,
          phoneNotification: event.target.checked,
        })
      )
    }
  }

  const handleFaxChangeSwitch = (event) => {
    if (
      !practiceData.faxNumber ||
      practiceData.faxNumber === '' ||
      practiceData.faxNumber === null
    ) {
      setErrors({ fax: 'Please save your fax number before moving on.' })
      // to hide error message after few seconds
      setTimeout(() => {
        setErrors({})
      }, 5000)
    } else {
      setErrors({})
      setFaxChecked(event.target.checked)
      dispatch(
        updatePracticeData({
          practiceId: practiceData.id,
          faxNotification: event.target.checked,
          successMessage: true,
        })
      )
    }
  }

  return (
    <Card>
      <CardHeader title="Notification Settings" />
      <Divider />
      <Grid item lg={8} sm={12} xl={5}>
        <FormControlLabel
          checked={checked}
          style={{ margin: '1rem 1rem 0 1rem' }}
          onChange={handleChangeSwitch}
          control={<Switch color="primary" display="flex-start" />}
          label="Send me emails when there is activity on any of my cases."
          labelPlacement="start"
          data-selector="send-me-emails"
        />
        <Typography color="error" className={classes.errorText}>
          {errors.phone}
        </Typography>
        <FormControlLabel
          checked={phoneChecked}
          style={{ margin: '1rem 1rem 0 1rem' }}
          onChange={handleTextChangeSwitch}
          control={<Switch color="primary" display="flex-start" />}
          label="Send me texts when there is activity on any of my cases."
          labelPlacement="start"
          data-selector="send-me-texts"
        />
        {userdata.isAdmin === true && (
          <React.Fragment>
            <FormControlLabel
              checked={faxChecked}
              style={{ margin: '1rem 1rem 1rem' }}
              onChange={handleFaxChangeSwitch}
              control={<Switch color="primary" display="flex-start" />}
              label="Send me faxes when any of my cases are closed."
              labelPlacement="start"
              data-selector="send-me-faxes"
            />
            <Typography color="error" className={classes.errorText}>
              {errors.fax}
            </Typography>
          </React.Fragment>
        )}
      </Grid>
    </Card>
  )
}

export default NotificationSettings
