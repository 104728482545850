import React from 'react'

import { useAssignConsultLayoutStyles } from './Layout.styles'

interface IAssignConsultLayoutProps {
  children: React.ReactNode
}
const AssignConsultLayout: React.FC<IAssignConsultLayoutProps> = ({
  children,
}) => {
  const classes = useAssignConsultLayoutStyles()

  return (
    <div data-selector="assign-consult-layout" className={classes.root}>
      <main className={classes.content}>{children}</main>
    </div>
  )
}

export default AssignConsultLayout
