import React from 'react'

import Header from '../Header/Header'
import HelpButton from '../Help/HelpButton'
import { useNewCaseLayoutStyles } from './Layout.styles'

interface INewCaseLayoutProps {
  children: React.ReactNode
}

const NewCaseLayout: React.FC<INewCaseLayoutProps> = ({ children }) => {
  const classes = useNewCaseLayoutStyles()
  return (
    <div data-selector="new-case-layout" className={classes.root}>
      <Header />
      <main className={classes.content}>{children}</main>
      <HelpButton />
    </div>
  )
}

export default NewCaseLayout
