import {
  Card,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom"
import { useAppDispatch, useAppSelector } from '../../shared/store'
import { getAllSpecialties, saveDefaultSpecialist } from '../../shared/store/case/actions'
import { useDefaultSpecialtiesPageStyles } from './DefaultSpecialtiesPage.styles'

const DefaultSpecialtiesPage: React.FC = () => {

  const history = useHistory()
  const userdata = useAppSelector((state) => state.auth.userdata)
  const rows = useAppSelector((state) => state.case.allSpecialtiesUsers)

  const classes = useDefaultSpecialtiesPageStyles()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if(userdata.userType !== 'Admin') {
      history.push('/inbox')
    }

    dispatch(getAllSpecialties())
  }, [])

  const handleChangeSpecialistName = (
    opt: any,
    specialtiesId: number,
  ) => {
    const defaultSpecialist = {
      id: specialtiesId,
      defaultSpecialistId: opt ? opt.id : null,
    }
    dispatch(saveDefaultSpecialist(defaultSpecialist))
  }

  const _renderAutoComplete = (row) => {
    let specialistName = ''
    if (row.firstName) {
      specialistName = row.firstName
      if (row.lastName) {
        specialistName += ' ' + row.lastName
      }
    }
    let specialtyUsers = []
    if (row.users) {
      specialtyUsers = row.users
    }
    return (
      <Autocomplete
        disablePortal
        options={specialtyUsers}
        getOptionLabel={(option: any) =>
          typeof option === 'string' ? option : option.firstName + ' ' + option.lastName
        }
        value={specialistName}
        onChange={(_event, opt) => {
          handleChangeSpecialistName(opt, row.id)}
        }
        className={classes.autoComplete}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            variant="outlined"
            size="small"
            fullWidth
            label="Type Specialist Name"
            value={specialistName}
          />
        )}
      />
    )
  }

  return (
    <div className={classes.container}>
      <Grid container justifyContent="flex-start" spacing={0}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Grid item className={classes.grid}>
              <Typography color="textSecondary" variant="h5">
                All Specialties
              </Typography>
              <Divider />
            </Grid>

            <div className={classes.root}>
              <Paper className={classes.paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: 280 }}>Specialty</TableCell>
                      <TableCell style={{ width: 350 }}>Default Specialist</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, key) => (
                      <TableRow
                        key={row.id}
                      >
                        <TableCell component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell>
                          {_renderAutoComplete(row)}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default DefaultSpecialtiesPage
