import {
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Zoom,
} from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import React, { useEffect } from 'react'

import ConfirmationDialog from '../../../shared/components/ConfirmationDialog'
import { useAppDispatch, useAppSelector } from '../../../shared/store'
import {
  deleteUserFromPractice,
  fetchPracticeMemberData,
} from '../../../shared/store/auth/actions'
import { enqueueSnackbar } from '../../../shared/store/snackbar/actions'
import { snackbarMessages } from '../../../shared/utils/constant'
import { encodeString } from '../../../shared/utils/functions'
import { useMemberInviteTableStyles } from './MemberInviteTable.styles'

const MemberInviteTable: React.FC<{ handleShowAddMember: () => void }> = (
  props
) => {
  const dispatch = useAppDispatch()

  const classes = useMemberInviteTableStyles()
  const auth = useAppSelector((state) => state.auth)

  const [order, setOrder] = React.useState<'asc' | 'desc'>('desc')
  const [orderBy, setOrderBy] = React.useState('id')
  const [page, setPage] = React.useState(0)
  const [dense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [confirmDelete, setConfirmDelete] = React.useState(false)
  const [userId, setUserId] = React.useState('')

  const descendingComparator = (a: any, b: any, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order: string, orderBy: string) => {
    return order === 'desc'
      ? (a: string, b: string) => descendingComparator(a, b, orderBy)
      : (a: string, b: string) => -descendingComparator(a, b, orderBy)
  }

  const stableSort = (array: any, comparator: any) => {
    const stabilizedThis = array.map((el: any, index: any) => [el, index])
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el: any) => el[0])
  }

  useEffect(() => {
    const { practiceId } = auth.userdata
    dispatch(
      fetchPracticeMemberData({
        practiceId,
      })
    )
  }, [auth.userdata, dispatch])

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const openRemoveDialog = (userId: string) => {
    setConfirmDelete(true)
    setUserId(userId)
  }

  const handleClose = () => {
    setConfirmDelete(false)
    setUserId('')
  }

  const removeUser = () => {
    setConfirmDelete(false)
    dispatch(
      deleteUserFromPractice({
        userId,
        practiceId: auth.userdata.practiceId,
      })
    )
    setUserId('')
  }

  const createSortHandler = (property: string) => () => {
    const isAsc = orderBy === property && order === 'asc'
    const orderVal = isAsc ? 'desc' : 'asc'
    setOrder(orderVal)
    setOrderBy(property)
  }

  const copyInviteLink = () => {
    const params = {
      practiceId: auth.userdata.practiceId,
      isAdmin: 'false',
    }

    let newParams = JSON.stringify(params)
    newParams = encodeString(newParams)
    const link = window.location.origin + '/register/identification/hash_' + newParams

    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(link)
    } else {
      document.execCommand('copy', true, link)
    }

    dispatch(
      enqueueSnackbar({
        message: snackbarMessages.INVITE_LINK_COPIES,
        options: {
          variant: 'success',
        },
      })
    )
  }

  let rows = []
  if (auth.practiceMemberData && auth.practiceMemberData.length) {
    rows = auth.practiceMemberData
  }

  const headCells: {
    id: string
    disablePadding: boolean
    label: string
    isSortable: boolean
  }[] = [
    {
      id: 'firstName',
      disablePadding: true,
      label: 'Name',
      isSortable: true,
    },
    {
      id: 'email',
      disablePadding: false,
      label: 'Email',
      isSortable: true,
    },
    {
      id: 'userType',
      disablePadding: false,
      label: 'User Type',
      isSortable: true,
    },
    {
      id: 'active',
      disablePadding: false,
      label: 'Status',
      isSortable: true,
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'Action',
      isSortable: false,
    },
  ]

  return (
    <Card>
      <CardHeader title="Members in your practice" />
      <Divider />

      <div className={classes.root}>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCell.id ? order : false}
                    style={{ fontWeight: 600 }}
                  >
                    {headCell.isSortable ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`
                    const {
                      id,
                      firstName,
                      lastName,
                      email,
                      userType: providerType,
                      active,
                      isAdmin,
                    } = row
                    return (
                      <TableRow tabIndex={-1} key={id}>
                        <TableCell padding="checkbox"></TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {firstName + ' ' + lastName}
                        </TableCell>
                        <TableCell>{email}</TableCell>
                        <TableCell>{providerType}</TableCell>
                        <TableCell>{active ? 'Active' : 'Inactive'}</TableCell>
                        <TableCell>
                          {isAdmin === false && (
                            <Tooltip
                              TransitionComponent={Zoom}
                              title="Delete Member"
                            >
                              <Delete
                                onClick={(event) => openRemoveDialog(row.id)}
                                style={{ cursor: 'pointer' }}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
              {!rows.length && (
                <TableRow style={{ height: 35 }}>
                  <TableCell colSpan={6}> No record found </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          //rowsPerPageOptions={[5, 10, 25]}
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.footerPagination}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={() => props.handleShowAddMember()}
          className={classes.addMemberBtn}
        >
          Add Member
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={copyInviteLink}
          className={classes.inviteLinkBtn}
        >
          Copy Invite Link
        </Button>

        <ConfirmationDialog
          message="You will lose all the data of this member. Are you sure you want to delete?"
          title="Delete Member"
          okOperationDialog={removeUser}
          handleClose={handleClose}
          dialogState={confirmDelete}
        />
      </div>
    </Card>
  )
}

export default MemberInviteTable
