import { makeStyles, Theme } from '@material-ui/core'

export const useAttachCardStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(0),
    alignItems: 'center',
  },
  attachName: {
    fontSize: 20,
    color: '#0B0B0B',
    marginLeft: 10,
  },
  cardWrapper: {
    width: '100%',
  },
  contentCard: {
    backgroundColor: 'white',
  },
  cardAction: {
    maxWidth: '90vw',
    height: '24vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  modalContainer: {
    //Edit this to resize image viewer modal
    [theme.breakpoints.down('sm')]: {
      top: '0%',
      left: '0%',
      //right: "2%",
      width: '100%',
      height: '100%',
      // maxHeight: "100%",
      // height: "23vh"
    },
    [theme.breakpoints.up('sm')]: {
      top: '12%',
      left: '4%',
      right: '4%',
      width: '92%',
      // maxHeight: "30%",
    },
    [theme.breakpoints.up('md')]: {
      top: '0%',
      left: '0%',
      // bottom: "90%",
      width: '100%',
      height: '100%',
      // maxHeight: "30%",
    },
    position: 'absolute',
    outline: 'none',
    alignItems: 'center',
    // backgroundColor: "green",
  },
  // modalName: {
  //     color: "#ffffff",
  //     fontSize: 32,
  //     fontWeight: "600",
  //     marginLeft: 10
  // },
  modalCard: {
    width: '100%',
    maxWidth: '100%',
    height: '82%',
    marginTop: 5,
    justifyContent: 'center',
    display: 'flex',
    // overflow:'scroll',
  },
  closeButton: {
    backgroundColor: '#ffffff',
    //marginBottom: 500
  },
  viewButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  root: {
    maxWidth: 600,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: '100%',
    overflow: 'hidden',
    display: 'block',
    width: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pdfView: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    overflow: 'scroll',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  floatLeft: {
    float: 'left',
    display: 'inline-block',
  },
  floatRight: {
    float: 'right',
    display: 'inline-block',
  },
  textAlign: {
    textAlign: 'center',
  },
}))
