import {
  AppBar,
  Badge,
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import CreateNewFolderSharpIcon from '@material-ui/icons/CreateNewFolderSharp'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DraftsSharpIcon from '@material-ui/icons/DraftsSharp'
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial'
import InputIcon from '@material-ui/icons/Input'
import MailIcon from '@material-ui/icons/Mail'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import textLogo from '../../assets/images/text-logo.png'
import { useAppDispatch, useAppSelector } from '../../store'
import { logoutUser } from '../../store/auth/actions'
import { getNumOfNewMessage } from '../../store/case/actions'
import { sendAmplitudeData } from '../../utils/amplitude'
import { amplitudeEvents } from '../../utils/constant'
import { useHeaderStyles } from './Header.styles'
import UserMenu from './UserMenu'

const Header: React.FC = () => {
  const classes = useHeaderStyles()
  const auth = useAppSelector((state) => state.auth.userdata)
  const numberOfNewMessage = useAppSelector(
    (state) => state.case.numberOfNewMessage
  )
  const caseLoader = useAppSelector((state) => state.case.caseLoader)
  const draftLoader = useAppSelector((state) => state.draft.draftLoader)
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState(null)

  React.useEffect(() => {
    const { id, userType, practiceId } = auth
    const userData = {
      userId: id,
      userRole: userType,
      practiceId,
    }
    dispatch(getNumOfNewMessage(userData))
  }, [auth, dispatch])

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onLogoutClick = (e: any) => {
    e.preventDefault()
    sendAmplitudeData(auth.email, amplitudeEvents.LOGOUT, auth)
    dispatch(logoutUser())
  }

  return (
    <AppBar position="fixed">
      <Toolbar disableGutters={true} classes={{ root: classes.toolbarRoot }}>
        <Link to="/">
          <div
            style={{
              marginLeft: 20,
              display: 'flex',
            }}
          >
            <img
              src={textLogo}
              alt="required"
              className={`${classes.logo} header-logo`}
            />
          </div>
        </Link>
        <Typography //This component adds space between the logo and menu items but needs to be
          variant="h2" //removed as it is garbage code- doesn't serve any purpose.
          color="textSecondary"
          noWrap
          className={classes.title}
        ></Typography>

        <Hidden smDown>
          <div className={classes.navItem}>
            <Button
              color="default"
              aria-label="Add"
              component={Link}
              to="/inbox/cases"
              data-selector="inbox"
            >
              <Badge
                badgeContent={numberOfNewMessage}
                color="primary"
                classes={{
                  root: classes.badgeRoot,
                  badge: classes.badgeSpan,
                }}
              >
                <MailIcon color={'action'} style={{ width: 25, height: 25 }} />
              </Badge>
              Inbox
            </Button>
          </div>

          <div className={classes.navItemDashboard}>
            <Button
              color="default"
              component={Link}
              to="/dashboard"
              data-selector="dashboard"
            >
              Dashboard
            </Button>
          </div>

          {(auth?.userType === 'PCP' || auth?.userType === 'PCPSUPERUSER') && (
            <div className={classes.navItem}>
              <Button
                color="primary"
                component={Link}
                to="/create-new-case"
                variant="contained"
                style={{
                  width: 170,
                }}
                data-selector="create-new-case"
              >
                Create new case
              </Button>
            </div>
          )}
          <UserMenu />
        </Hidden>
        <Hidden only={['xs', 'md', 'lg', 'xl']}>
          {(auth?.userType === 'PCP' || auth?.userType === 'PCPSUPERUSER') && (
            <div className={classes.navItem}>
              <Button
                color="primary"
                component={Link}
                to="/create-new-case"
                variant="contained"
                style={{
                  fontSize: 17,
                  width: 170,
                }}
                data-selector="create-new-case"
              >
                Create new case
              </Button>
            </div>
          )}
        </Hidden>

        <Hidden mdUp>
          <div>
            <IconButton
              aria-label="More"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: 170,
                },
              }}
            >
              {/* why handleClick method? */}
              <Link to="/dashboard">
                <MenuItem onClick={handleClose}>
                  <IconButton color="default" className={classes.iconButton}>
                    <DashboardIcon />
                  </IconButton>
                  <Typography color="textSecondary" data-selector="dashboard">
                    Dashboard
                  </Typography>
                </MenuItem>
              </Link>

              {/* //why handleClose method? */}
              <Link to="/import">
                <MenuItem onClick={handleClose}>
                  <IconButton color="default" className={classes.iconButton}>
                    <CloudUploadOutlinedIcon fontSize={'inherit'} />
                  </IconButton>
                  <Typography color="textSecondary" data-selector="import">
                    Import
                  </Typography>
                </MenuItem>
              </Link>

              <Divider />
              <h5 style={{ marginLeft: 10, marginTop: 15, marginBottom: 5 }}>
                FOLDERS
              </h5>
              <Link to="/inbox/all_cases">
                <MenuItem onClick={handleClose}>
                  <IconButton color="default" className={classes.iconButton}>
                    <AssignmentIcon fontSize={'inherit'} />
                  </IconButton>
                  <Typography color="textSecondary" data-selector="open-cases">
                    All Cases
                  </Typography>
                </MenuItem>
              </Link>
              {/* //why handleClose method? */}
              <Link to="/inbox/cases">
                <MenuItem onClick={handleClose}>
                  <IconButton color="default" className={classes.iconButton}>
                    <Badge badgeContent={numberOfNewMessage} color="primary">
                      <DraftsSharpIcon fontSize={'inherit'} />
                    </Badge>
                  </IconButton>
                  <Typography color="textSecondary" data-selector="open-cases">
                    Open Cases
                  </Typography>
                </MenuItem>
              </Link>
              {/* //why handleClose method? */}
              <Link to="/inbox/closed_cases">
                <MenuItem onClick={handleClose}>
                  <IconButton color="default" className={classes.iconButton}>
                    <Badge badgeContent={numberOfNewMessage} color="primary">
                      <MailIcon fontSize={'inherit'} />
                    </Badge>
                  </IconButton>
                  <Typography
                    color="textSecondary"
                    data-selector="closed-cases"
                  >
                    Closed Cases
                  </Typography>
                </MenuItem>
              </Link>
              {/* //why handleClose method? */}
              <Link to="/inbox/drafts">
                <MenuItem onClick={handleClose}>
                  <IconButton color="default" className={classes.iconButton}>
                    <Badge badgeContent={numberOfNewMessage} color="primary">
                      <CreateNewFolderSharpIcon fontSize={'inherit'} />
                    </Badge>
                  </IconButton>
                  <Typography color="textSecondary" data-selector="drafts">
                    Drafts
                  </Typography>
                </MenuItem>
              </Link>
              {auth && auth.userType === 'Admin' && (
                <Link to="/specialties">
                  <MenuItem
                    onClick={handleClose}
                    data-selector="nav-to-specialties"
                  >
                    <IconButton color="default" className={classes.iconButton}>
                      <Badge badgeContent={numberOfNewMessage} color="primary">
                        <FolderSpecialIcon fontSize={'inherit'} />
                      </Badge>
                    </IconButton>
                    <Typography color="textSecondary">Specialties</Typography>
                  </MenuItem>
                </Link>
              )}
              <Divider />

              <Grid
                style={{
                  backgroundColor: 'e2e2e2',
                }}
                component="div"
              >
                <Link to="/account">
                  <MenuItem
                    onClick={handleClose}
                    data-selector="nav-to-account"
                  >
                    <IconButton color="default" className={classes.iconButton}>
                      <AccountBoxIcon />
                    </IconButton>
                    <Typography color="textSecondary" data-selector="account">
                      Account
                    </Typography>
                  </MenuItem>
                </Link>
                <MenuItem onClick={onLogoutClick} data-selector="nav-to-logout">
                  <IconButton color="default" className={classes.iconButton}>
                    <InputIcon />
                  </IconButton>
                  <Typography color="textSecondary">Log out</Typography>
                </MenuItem>
              </Grid>
            </Menu>
          </div>
        </Hidden>
      </Toolbar>
      {(caseLoader || draftLoader) && (
        <LinearProgress
          style={{
            height: '5px',
          }}
        />
      )}
    </AppBar>
  )
}

export default Header
