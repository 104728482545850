import { useEffect, useRef } from 'react'

/**
 * it is not possible to simply access state in useEffect cleanup function,
 * because of the common closure problem, and this custom hook helps with that
 * READ THIS: https://www.timveletta.com/blog/2020-07-14-accessing-react-state-in-your-component-cleanup-with-hooks/
 */
const useComponentWillUnmountWithStateAccessInCleanup = (
  func: () => void
): void => {
  const componentWillUnmount = useRef(false)

  // helper useEffect, so we know when we unmount
  useEffect(() => {
    return () => {
      componentWillUnmount.current = true
    }
  }, [])

  // if unmount, trigger function
  useEffect(() => {
    return () => {
      if (componentWillUnmount.current) {
        func()
      }
    }
  }) // leave without dependency array so it can always pick up the latest state
}

export default useComponentWillUnmountWithStateAccessInCleanup
