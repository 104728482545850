import {
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AssignmentIcon from '@material-ui/icons/Assignment'
import DraftsIcon from '@material-ui/icons/Drafts'
import FolderIcon from '@material-ui/icons/Folder'
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../shared/store'

const drawerWidth = 200

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: drawerWidth,
    margin: 0,
    padding: 0,
    // height: '100vh',
    paddingTop: theme.spacing(8),
    boxShadow: 'none',
    borderRadius: 0,
    overflow: 'visible',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    // backgroundColor: "#06596b",
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
  toolbar: theme.mixins.toolbar,
  listItemTextRoot: {
    padding: 0,
  },
  listItemTextLabel: {
    //color: "#7e8fa7",
    fontSize: 15,
    fontWeight: 600,
  },
}))

export default function InboxSideBar(props) {
  const classes = useStyles()
  let repVal = ''

  const userdata = useAppSelector((state) => state.auth.userdata)
  const routeName = props.routeName.substr(props.routeName.length - 1, 1)
  if (routeName === '/') {
    repVal = props.routeName.substr(0, props.routeName.length - 1)
  } else {
    repVal = props.routeName
  }

  return (
    <Card className={classes.root}>
      <div className={classes.drawer}>
        <List>
          {/* <Divider variant="fullWidth" style={{ marginTop: 12 }}/> */}
          <Typography
            color="textSecondary"
            variant="subtitle1"
            style={{
              marginLeft: 14,
              marginTop: 45,
              marginBottom: 10,
              fontWeight: 800,
              color: '#fff',
            }}
          >
            FOLDERS
          </Typography>
          {/* <Divider variant="fullWidth" style={{ marginBottom: 10 }}/> */}

          <Link to="/inbox/all_cases">
            <ListItem button data-selector="side-nav-bar-all-cases">
              <ListItemIcon>
                <AssignmentIcon
                  style={{
                    color: `${
                      repVal === '/inbox/all_cases' ? '#fff' : '#87b9c4'
                    }`,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: `${
                    repVal === '/inbox/all_cases' ? '#fff' : '#87b9c4'
                  }`,
                }}
                primary="All Cases"
                classes={{
                  root: classes.listItemTextRoot,
                  primary: classes.listItemTextLabel,
                }}
              />
            </ListItem>
          </Link>
          <Link to="/inbox/cases">
            <ListItem button data-selector="side-nav-bar-open-cases">
              <ListItemIcon>
                <QuestionAnswerIcon
                  style={{
                    color: `${
                      repVal === '' ||
                      repVal === '/inbox' ||
                      repVal === '/inbox/cases'
                        ? '#fff'
                        : '#87b9c4'
                    }`,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: `${
                    repVal === '' ||
                    repVal === '/inbox' ||
                    repVal === '/inbox/cases'
                      ? '#fff'
                      : '#87b9c4'
                  }`,
                }}
                primary="Open Cases"
                classes={{
                  root: classes.listItemTextRoot,
                  primary: classes.listItemTextLabel,
                }}
              />
            </ListItem>
          </Link>
          <Link to="/inbox/closed_cases">
            <ListItem button data-selector="side-nav-bar-closed-cases">
              <ListItemIcon>
                <FolderIcon
                  style={{
                    color: `${
                      repVal === '/inbox/closed_cases' ? '#fff' : '#87b9c4'
                    }`,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: `${
                    repVal === '/inbox/closed_cases' ? '#fff' : '#87b9c4'
                  }`,
                }}
                primary="Closed Cases"
                classes={{
                  root: classes.listItemTextRoot,
                  primary: classes.listItemTextLabel,
                }}
              />
            </ListItem>
          </Link>
          <Link to="/inbox/drafts">
            <ListItem button data-selector="side-nav-bar-draft-cases">
              <ListItemIcon>
                <DraftsIcon
                  style={{
                    color: `${repVal === '/inbox/drafts' ? '#fff' : '#87b9c4'}`,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color: `${repVal === '/inbox/drafts' ? '#fff' : '#87b9c4'}`,
                }}
                primary="Drafts"
                classes={{
                  root: classes.listItemTextRoot,
                  primary: classes.listItemTextLabel,
                }}
              />
            </ListItem>
          </Link>
          {userdata && userdata.userType === 'Admin' && (
            <Link to="/specialties">
              <ListItem button data-selector="side-nav-bar-specialties">
                <ListItemIcon>
                  <FolderSpecialIcon
                    style={{
                      color: `${
                        repVal === '/specialties' ? '#fff' : '#87b9c4'
                      }`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{
                    color: `${repVal === '/specialties' ? '#fff' : '#87b9c4'}`,
                  }}
                  primary="Specialties"
                  classes={{
                    root: classes.listItemTextRoot,
                    primary: classes.listItemTextLabel,
                  }}
                />
              </ListItem>
            </Link>
          )}
        </List>
      </div>
    </Card>
  )
}
