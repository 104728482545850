import CssBaseline from '@material-ui/core/CssBaseline'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import AccountPage from './components/AccountPage'
import AllCasesPage from './components/AllCasesPage'
import AssignConsultPage from './components/AssignConsultPage'
import CheckoutPage from './components/CheckoutPage/CheckoutPage'
import ClosedCasesPage from './components/ClosedCasesPage'
import DashboardPage from './components/DashboardPage'
import DefaultSpecialtiesPage from './components/DefaultSpecialtiesPage'
import DraftCasesPage from './components/DraftCasesPage'
import ForgotPasswordPage from './components/ForgotPasswordPage'
import HintInstantOnPage from './components/HintInstantOnPage'
import ImportPage from './components/ImportPage'
import InsideMessagePage from './components/InsideMessagePage'
import MyDocument from './components/MyDocument'
import NewCasePage from './components/NewCasePage'
import NotFoundPage from './components/NotFoundPage'
import OpenCasesPage from './components/OpenCasesPage'
import PaymentInfoPage from './components/PaymentInfoPage'
import PricingPage from './components/PricingPage'
import ReferFriendPage from './components/ReferFriendPage'
import SignInPage from './components/SignInPage'
import SignUpPage from './components/SignUpPage'
import TermServicePage from './components/TermServicePage'
import AdminRoute from './shared/components/routes/AdminRoute'
import AssignConsultRoute from './shared/components/routes/AssignConsultRoute'
import DashboardRoute from './shared/components/routes/DashboardRoute'
import EmptyRoute from './shared/components/routes/EmptyRoute'
import InboxRoute from './shared/components/routes/InboxRoute'
import InsideMessageRoute from './shared/components/routes/InsideMessageRoute'
import NewCaseRoute from './shared/components/routes/NewCaseRoute'
import SnackbarNotifier from './shared/components/SnackbarNotifier'
import { logoutUser, setCurrentUser } from './shared/store/auth/actions'
import store, { useAppDispatch } from './shared/store/store'
import setAuthToken from './shared/utils/setAuthToken'

//const stripePromise = getPublicStripeKey().then(key => loadStripe(key));

const THEME = createTheme({
  typography: {
    //  "fontFamily": "\"Open Sans\", Semibold",
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#13c1cf',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#ffffff', // could also remove and will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#004866',
    },
    background: {
      default: '#f5f7ff', // '#004866',
    },
  },
})

if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken
  setAuthToken(token)
  const decoded: JwtPayload = jwt_decode(token)

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded))
  // Check for expired token
  const currentTime = Date.now() / 1000 // to get in milliseconds
  if (decoded.exp && decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser())
    // Redirect to login
    window.location.href = './login'
  }
}

const timeout = 1000 * 60 * 30

const App: React.FC = () => {
  const dispatch = useAppDispatch()

  const onIdle = () => {
    console.info('user idle ', isIdle())
    dispatch(logoutUser())
  }

  const { isIdle } = useIdleTimer({
    timeout,
    onIdle,
  })

  return (
    <MuiThemeProvider theme={THEME}>
      <CssBaseline />
      <SnackbarNotifier />
      <div style={{ minHeight: '100vh' }}>
        <Router>
          <Switch>
            <AdminRoute path="/pdf_:channelId" component={MyDocument} />
            <AdminRoute path="/login" component={SignInPage} />
            <AdminRoute path="/forgotpassword" component={ForgotPasswordPage} />

            {/* this is used in account creation when you refer someone or an admin adds a user to their practice
                I forget what the hash includes exactly */}
            <AdminRoute
              path="/register/identification/hash_:hash"
              component={SignUpPage}
            />

            {/* This route is used if we already have an existing practice and want to create a new user (account) in it. */}
            {/* Usage sample: http://localhost:3000/register/identification/practiceId_51/isAdmin_false */}
            <AdminRoute
              path="/register/identification/practiceId_:practiceId/isAdmin_:isAdmin"
              component={SignUpPage}
            />

            {/* This is meant to set the max number of users in a practice but Harry also used that key to set pricing because he
              had to find a way to do it in like 20 min for a new user and haven’t gotten around to updating it to be something better.
              It should be refactored. This route also creates a practice in addition to a user. */}
            <AdminRoute
              path="/register/identification/signUpKey_:maxUser/isAdmin_:isAdmin"
              component={SignUpPage}
            />
            {/* This is the same as the one above but it creates a user in existing practice. */}
            <AdminRoute
              path="/register/identification/signUpKey_:maxUser/practiceId_:practiceId/isAdmin_:isAdmin"
              component={SignUpPage}
            />

            <AdminRoute path="/term-service" component={TermServicePage} />
            <AdminRoute path="/payment-info" component={PaymentInfoPage} />
            <AdminRoute path="/referFriend" component={ReferFriendPage} />
            <DashboardRoute path="/checkout" component={CheckoutPage} />
            <DashboardRoute path="/pricing" component={PricingPage} />

            <DashboardRoute path="/dashboard" component={DashboardPage} />
            <DashboardRoute path="/account" component={AccountPage} />
            <DashboardRoute path="/import" component={ImportPage} />
            <DashboardRoute
              path="/dashboard/utilization"
              component={DashboardPage}
            />
            <DashboardRoute
              path="/dashboard/savings-reimbursements"
              component={DashboardPage}
            />
            <DashboardRoute
              path="/dashboard/consult-efficiency"
              component={DashboardPage}
            />
            <InboxRoute exact path="/" component={OpenCasesPage} />
            <InboxRoute exact path="/inbox" component={OpenCasesPage} />
            <InboxRoute path="/inbox/cases" component={OpenCasesPage} />
            <InboxRoute path="/inbox/all_cases" component={AllCasesPage} />
            <InboxRoute
              path="/inbox/closed_cases"
              component={ClosedCasesPage}
            />
            <InboxRoute path="/inbox/drafts" component={DraftCasesPage} />
            <InboxRoute
              path="/specialties"
              component={DefaultSpecialtiesPage}
            />
            <AssignConsultRoute
              path="/assign-consult/case_:caseId/specialty_:type"
              component={AssignConsultPage}
            />
            <InsideMessageRoute
              path="/insidemessage/room_:channelId"
              component={InsideMessagePage}
            />
            {/* hint InstantOn */}
            <AdminRoute
              path="/register/identification/hint/:hintAuthCode"
              component={HintInstantOnPage}
            />
            <NewCaseRoute path="/create-new-case" component={NewCasePage} />
            <NewCaseRoute path="/edit-new-case-:id" component={NewCasePage} />
            <EmptyRoute component={NotFoundPage} />
          </Switch>
        </Router>
      </div>
    </MuiThemeProvider>
  )
}

export default App
