import {
  Card,
  Grid,
  Paper,
  Tooltip,
  useMediaQuery,
  Zoom,
} from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import { DataGrid, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import deepEqual from 'fast-deep-equal/es6/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router'

import ConfirmationDialog from '../../shared/components/ConfirmationDialog'
import DataGridTableHeader from '../../shared/components/DataGridTableHeader'
import useDataGridColumns, {
  HideableDataGridColumns,
} from '../../shared/hooks/useDataGridColumns'
import { useAppDispatch, useAppSelector } from '../../shared/store'
import {
  fetchAllCases,
  modifyCaseOnTableRowClick,
  reOpenCases,
  setCurrentCase,
} from '../../shared/store/case/actions'
import { readMessages } from '../../shared/store/chat/actions'
import { sendAmplitudeData } from '../../shared/utils/amplitude'
import { amplitudeEvents } from '../../shared/utils/constant'
import { decodeString } from '../../shared/utils/functions'
import MyDocument from '../MyDocument'
import { ISearchResultsForCases } from '../OpenCasesPage/OpenCasesPage'
import { useClosedCasesPageStyles } from './ClosedCasesPage.styles'

const ClosedCasesPage: React.FC<RouteComponentProps> = ({ history }) => {
  const [searchResultsForCases, setSearchResultsForCases] = useState<
    ISearchResultsForCases[]
  >([])
  const [channelId, setChannelId] = useState('')
  const [confirmReOpenCase, setConfirmReOpenCase] = useState(false)
  const [caseIds, setCaseIds] = useState<{ caseId: string }[]>([])

  const childRef = useRef(null)

  const classes = useClosedCasesPageStyles()

  const userdata = useAppSelector((state) => state.auth.userdata, deepEqual)
  const caseStore = useAppSelector((state) => state.case)
  const readAllCases = useAppSelector(
    (state) => state.case.readAllCases,
    deepEqual
  )

  const dispatch = useAppDispatch()

  const onHandleSearch = (searchInput: string) => {
    if (searchInput !== '') {
      let resultsForCases: ISearchResultsForCases[] = [
        ...caseStore.readAllCases,
      ]

      if (searchInput !== 'All Specialties') {
        resultsForCases = caseStore.readAllCases?.filter(
          (item: ISearchResultsForCases) =>
            (item.caseData?.specialty || '')
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            item.caseData?.patientName
              .toLowerCase()
              .includes(searchInput.toLowerCase())
        )
      }

      setSearchResultsForCases(resultsForCases)
    } else {
      setSearchResultsForCases(caseStore.readAllCases)
    }
  }

  const handleTableRowClick = (id: any, caseData: any) => {
    const newCaseData = {
      caseId: id,
      isRead: true,
      currentUserRole: userdata.userType,
    }
    if (!caseData.isRead) {
      dispatch(modifyCaseOnTableRowClick(newCaseData as any))
    }
    history.push({
      pathname: `/insidemessage/room_${id}`,
      state: { from: 'closed_cases' },
    })
  }

  useEffect(() => {
    const currentUserData = {
      currentUserId: userdata.id,
      currentUserRole: userdata.userType,
      currentUserPracticeId: userdata.practiceId,
      isDeleted: true,
      // isCompleted: false,
    }
    dispatch(fetchAllCases(currentUserData as any))
  }, [dispatch, userdata.id, userdata?.practiceId, userdata?.userType])

  useEffect(() => {
    setSearchResultsForCases(readAllCases as any)
    sendAmplitudeData(userdata.email, amplitudeEvents.CLOSE_CASE_PAGE, userdata)
  }, [readAllCases, userdata])

  const handleClose = () => {
    setConfirmReOpenCase(false)
  }

  const openReOpenCaseDialog = (caseIds: any) => {
    setConfirmReOpenCase(true)
    setCaseIds(caseIds)
  }

  const downloadPDF = (caseData: any) => {
    setChannelId(caseData.caseId)

    const currentCaseCondition = {
      caseId: caseData.caseId,
      currentUserRole: userdata.userType,
    }
    dispatch(setCurrentCase(currentCaseCondition))

    const channelData = {
      channelId: caseData.caseId,
      type: 'message',
    }
    dispatch(readMessages(channelData))

    setTimeout(() => {
      ;(childRef.current as any).downloadPDF!()

      setTimeout(() => {
        setChannelId('')
      }, 500)
    }, 1000)
  }

  const handleReOpenCases = () => {
    setConfirmReOpenCase(false)

    const caseData = {
      caseIds,
      lastUpdatedById: userdata.id,
      isRead: true,
      isDeleted: false,
    }
    dispatch(reOpenCases(caseData as any))
  }

  const [selected, setSelected] = useState<string[]>([])

  const caseLoader = useAppSelector((state) => state.case.caseLoader)
  const draftLoader = useAppSelector((state) => state.draft.draftLoader)
  const loading = caseLoader || draftLoader

  const renderActions = useCallback((params: GridRenderCellParams) => {
    return (
      <>
        {params.row.fullData.caseData &&
          params.row.fullData.caseData.isDeleted && (
            <Tooltip
              TransitionComponent={Zoom}
              title="Download PDF"
              onClick={(_event) => downloadPDF!(params.row.fullData.caseData)}
            >
              <GetAppIcon style={{ cursor: 'pointer', margin: 8 }} />
            </Tooltip>
          )}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isWideScreen = useMediaQuery('(min-width: 960px)')
  const { columns } = useDataGridColumns(
    isWideScreen
      ? []
      : [
          HideableDataGridColumns.question,
          HideableDataGridColumns.updatedAt,
          HideableDataGridColumns.claimStatus,
        ],
    renderActions,
    userdata
  )

  const rows = useMemo(
    () =>
      searchResultsForCases
        .map((item: any) => {
          let updatedAt = item.caseData.updatedAt
            ? item.caseData.updatedAt
            : item.caseData.updatedAtOld
          if (!item.caseData.updatedAt) {
            updatedAt = new Date(
              Number(item.caseData.updatedAtOld)
            ).toISOString()
          }
          return {
            id: item.caseData.id,
            fullData: item, // used only to track full object for selected item
            specialty: item.caseData.specialty
              ?.map((item) => item.title)
              .join(', '),
            patientName: item.caseData.patientName,
            question: decodeString(item.caseData.questions),
            updatedAt,
            claimStatus: item.caseData.claimStatus,
          }
        })
        .sort(
          (x, y) =>
            new Date(y.updatedAt).getTime() - new Date(x.updatedAt).getTime()
        ) || [],
    [searchResultsForCases]
  )

  const handleReopenAction = () => {
    const caseIds: string[] = rows
      .filter((item) => selected.includes(item.id))
      .map((item) => item.fullData.caseData.caseId)
    openReOpenCaseDialog!(caseIds)
  }

  return (
    <div className={classes.container}>
      <Grid container justifyContent="flex-start" spacing={0}>
        <Grid item xs={12}>
          <Card
            style={{
              backgroundColor: '#ffffff',
              padding: 20,
              overflow: 'auto',
              height: '100%',
            }}
          >
            <DataGridTableHeader
              title="Closed Cases"
              onHandleSearch={onHandleSearch}
            />

            <div className={classes.root} style={{ width: '100%' }}>
              <Paper
                className={classes.paper}
                style={{ height: '100%', width: '100%' }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  style={{
                    height: '50px',
                    visibility:
                      rows.length && userdata.userType === 'PCP'
                        ? 'visible'
                        : 'hidden',
                  }}
                >
                  {Boolean(
                    !draftLoader &&
                      !caseLoader &&
                      rows.length > 0 &&
                      userdata.userType === 'PCP' &&
                      selected.length
                  ) && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <label
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: '15px',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleReopenAction()}
                      >
                        <span style={{ float: 'left' }}>
                          <UnarchiveIcon style={{ margin: '8px 8px 8px 0' }} />
                        </span>
                        <span
                          style={{
                            float: 'left',
                            marginTop: 10,
                            fontSize: '1rem',
                          }}
                          data-selector="reopen-cases-toolbar"
                        >
                          Reopen Cases
                        </span>
                      </label>
                    </Grid>
                  )}
                </Grid>
                <DataGrid
                  className={classes.dataGrid}
                  rows={!loading ? rows : []}
                  columns={columns}
                  loading={loading}
                  autoPageSize
                  sortingOrder={['asc', 'desc']}
                  onRowClick={(data) => {
                    handleTableRowClick(
                      data.row.fullData.caseData.caseId,
                      data.row.fullData.caseData && data.row.fullData.caseData
                    )
                  }}
                  onCellClick={(params, event) => {
                    if (params.colDef.headerName === 'Actions') {
                      event.stopPropagation()
                    }
                  }}
                  isRowSelectable={(params: GridRowParams) =>
                    params.row.specialty !== '0AlwaysFirstValue'
                  }
                  onSelectionModelChange={(newSelection, details) => {
                    setSelected(newSelection as string[])
                  }}
                  checkboxSelection
                  disableSelectionOnClick
                  disableColumnSelector
                />
              </Paper>
            </div>
          </Card>
          <Card style={{ display: 'none' }}>
            {channelId && <MyDocument ref={childRef} />}
          </Card>
        </Grid>
      </Grid>

      <ConfirmationDialog
        message="Are you sure you want to reopen selected cases?"
        title="Reopen Cases"
        okOperationDialog={handleReOpenCases}
        handleClose={handleClose}
        dialogState={confirmReOpenCase}
      />
    </div>
  )
}

export default ClosedCasesPage
