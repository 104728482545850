import { makeStyles, Theme } from '@material-ui/core'

export const useHeaderStyles = makeStyles((theme: Theme) => ({
  toolbarRoot: {
    paddingRight: theme.spacing(3),
    backgroundColor: '#fff',
  },
  media: {
    height: 30,
    width: 210,
    marginLeft: 30,
    margin: 0,
  },
  title: {
    marginLeft: 100,
    flexGrow: 1,
  },
  badgeRoot: {
    marginRight: 10,
  },
  badgeSpan: {
    top: 2,
    right: 2,
  },
  navItemDashboard: {
    borderLeft: '0.1em solid #d8d8d8',
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
    },
  },
  navItem: {
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 18,
    },
  },
  iconButton: {
    padding: 5,
  },
  logo: {
    width: '55%',
    height: 'auto',
  },
}))
