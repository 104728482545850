import { makeStyles, Theme } from '@material-ui/core'

export const useDefaultSpecialtiesPageStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('md')]: {
      padding: 20,
    },
  },
  root: {
    width: '100%',
  },
  card: {
    backgroundColor: '#ffffff',
    padding: 20,
    overflow: 'auto',
    height: '100%',
  },
  grid: {
    width: 200,
    paddingTop: 6,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  paper: {
    height: '100%',
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    boxShadow: 'none',
  },
  autoComplete: {
    minWidth: 210
  },
}))
