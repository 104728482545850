/* eslint-disable */
import { CssBaseline, Grid, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchAllCases } from '../../shared/store/case/actions'
import ConsultGraph from '../Dashboard/ConsultGraph'

const AdapterLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
))

//look into why we did this in this way vs reg import
const moment = require('moment')

const styles = (theme) => ({
  grid: {
    width: 1080, //Width of the overall grid area
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      margin: 1,
    },
    [theme.breakpoints.up('md')]: {
      margin: 20,
    },
  },
  paper: {
    // for one individual Card
    padding: '32px 20px',
    margin: theme.spacing(0),
    textAlign: 'left', //To change the alignment of the upper text in the cards
    color: theme.palette.text.primary,
    bgcolor: theme.palette.secondary.main,
  },
  dashboardPaper: {
    [theme.breakpoints.down('sm')]: {
      padding: '25px 0px 5px 15px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px 0px 20px 20px',
    },
    //padding: '32px 20px',
    margin: theme.spacing(0),
    textAlign: 'left', //To change the alignment of the upper text in the cards
    color: theme.palette.text.primary,
    bgcolor: theme.palette.secondary.main,
    // backgroundColor: "#1cacc7"
  },
  metricsPaper: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      padding: theme.spacing(2),
    },
    margin: theme.spacing(0),
    textAlign: 'left', //To change the alignment of the upper text in the cards
    color: theme.palette.primary.contrastText,
    background: theme.palette.secondary.main, //"#303f9f" 				 // this should be done in a better way using primary but it won't work for some reason
  },
  metrics: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 40,
    },
  },
  metricsText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  blockCenter: {
    // for the useful space inside individual cards
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      padding: theme.spacing(1),
    },
    textAlign: 'center', //To change the alignment of the center text in the cards
    fontSize: 90,
  },
  block: {
    padding: theme.spacing(0.5),
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1),
  },
  buttonBar: {
    display: 'flex',
  },
})

export class DashboardPage extends Component {
  constructor(props) {
    super(props)

    this.shouldComponentRender = this.shouldComponentRender.bind(this)
  }

  state = {
    readAllCases: [],
    loading: true,
    amount: 9500,
    period: 4,
    start: 0,
    data: [],
    labels: [],
  }

  componentDidMount() {
    const currentUserData = {
      currentUserId: this.props.userdata.id,
      currentUserRole: this.props.userdata.userType,
      isCompleted: false,
      isDeleted: "",
    }

    this.props.fetchAllCases(currentUserData)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { readAllCases, errors } = nextProps
    if (errors) {
      this.setState({
        errors,
      })
    }

    this.setState((state) => ({
      readAllCases,
    }))
  }

  shouldComponentRender() {
    const { isDeleted } = this.props
    if (this.isDeleted === false) {
      return false
    }
    // and something here
    {
      return true
    }
  }

  getOpenConsults() {
    var count = 0
    var i
    for (i = 0; i < this.state.readAllCases.length; i++) {
      if (this.state.readAllCases[i].caseData.isDeleted == false) {
        count++
      }
    }
    return count
  }

  // TODO: this is super hacky fix it

  getStartDate() {
    const len = this.state.readAllCases.length
    if (len > 0) {
      const jsStartDate = new Date(
        Number(this.state.readAllCases[len - 1].caseData.createdAt)
      )
      const laterStartDate = moment(jsStartDate)
      const startDate = laterStartDate.subtract(1, 'month')
      return startDate
    } else {
      return moment()
    }
  }

  getMonthArray(startDate) {
    const months = []
    const endDate = moment()
    while (endDate.diff(startDate, 'months') >= 1) {
      months.push(startDate)
      startDate = moment(startDate.add(1, 'month'))
    }
    return months
  }

  getMonthlyTotals(months) {
    var totals = new Array(months.length).fill(0)
    for (var i = 0; i < this.state.readAllCases.length; i++) {
      for (var j = 0; j < months.length; j++) {
        const compareDate = this.state.readAllCases[i].caseData.createdAtOld
          ? new Date(Number(this.state.readAllCases[i].caseData.createdAtOld))
          : new Date(Number(this.state.readAllCases[i].caseData.createdAt))

        const compareMoment = moment(compareDate)

        if (compareMoment.isSame(months[j], 'month')) {
          totals[j]++
        }
      }
    }
    return totals
  }

  render() {
    const { classes } = this.props
    const labels = this.getMonthArray(this.getStartDate())
    const data = this.getMonthlyTotals(labels)
    const { readAllCases, amount, period, start } = this.state

    return (
      <>
        <CssBaseline />
        <div className={classes.root}>
          <Grid container justifyContent="center">
            <Grid
              spacing={1}
              alignItems="center"
              justifyContent="center"
              container
              className={classes.grid}
            >
              <Grid item xs={12} md={4}>
                <Paper
                  className={classes.metricsPaper}
                  color="#303f9f"
                  elevation={5}
                >
                  <div>
                    <h2>Total Consults</h2>
                    <div className={classes.blockCenter}>
                      <Typography className={classes.metrics} gutterBottom>
                        {this.state.readAllCases.length.toLocaleString()}
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper className={classes.metricsPaper} elevation={5}>
                  <div>
                    <h2>Open Consults</h2>

                    <div className={classes.blockCenter}>
                      <Typography className={classes.metrics} gutterBottom>
                        {this.getOpenConsults().toLocaleString()}
                      </Typography>
                    </div>
                  </div>
                  {/* </Box> */}
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper className={classes.metricsPaper} elevation={5}>
                  <div>
                    <h2>Estimated Cost Savings</h2>

                    <div className={classes.blockCenter}>
                      <Typography className={classes.metrics} gutterBottom>
                        $
                        {(
                          this.state.readAllCases.length * 541
                        ).toLocaleString()}
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12}>
                <Paper className={classes.dashboardPaper} elevation={5}>
                  <h2 style={{ marginBottom: 20 }}>Consults Over Time</h2>
                  <ConsultGraph data={data} labels={labels} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }
}

DashboardPage.propTypes = {
  userdata: PropTypes.object.isRequired,
  fetchAllCases: PropTypes.func.isRequired,
  readAllCases: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
  userdata: state.auth.userdata,
  readAllCases: state.case.readAllCases,
  readAllDrafts: state.draft.readAllDrafts,
  caseLoader: state.case.caseLoader,
})

export default connect(mapStateToProps, {
  fetchAllCases,
})(withStyles(styles)(DashboardPage))
