import axios from 'axios'

import { getServerUrl } from '../../utils/functions'
import { checkAuthorization } from '../auth/actions'
import { AppDispatch } from '../store'
import {
  ADD_DRAFT,
  CURRENT_DRAFT,
  DELETE_DRAFT,
  DELETE_DRAFTS,
  DRAFT_LOADER,
  FETCH_ALL_DRAFT,
  GET_ERRORS,
  SAVE_DRAFT
} from './types'

export interface ISaveDraft {
  caseId: string
  providerId: string
  specialistId: string
  patientId: string
  specialtyIds?: number[]
  patientName: string
  dob: string
  questions: string
  files: { name: string; type: string }[]
  isCompleted: boolean
  patientConsentGiven: boolean
}
export const saveDraft = (draftData: ISaveDraft) => (
  dispatch: AppDispatch
): void => {
  dispatch({
    type: DRAFT_LOADER,
    payload: true,
  })
  axios
    .post(getServerUrl() + '/api/v1/drafts/saveDraft', draftData)
    .then((res) => {
      const { status } = res.data
      if (status === '1') {
        dispatch({
          type: SAVE_DRAFT,
          payload: res.data.data,
        })
        dispatch({
          type: ADD_DRAFT,
          payload: res.data.data,
        })
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.errors,
        })
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err,
      })
    )
    .finally(() => {
      dispatch({
        type: DRAFT_LOADER,
        payload: false,
      })
    })
}

//Read messages with channel data
export const setCurrentDraft = (request: { caseId: string }) => (
  dispatch: AppDispatch
): void => {
  dispatch({
    type: CURRENT_DRAFT,
    payload: [],
  })
  axios
    .post(getServerUrl() + '/api/v1/drafts/fetchDraft', request)
    .then((res) => {
      dispatch(checkAuthorization(res))
      const { status } = res.data
      if (status === '1') {
        const { data } = res.data
        dispatch({
          type: CURRENT_DRAFT,
          payload: data,
        })
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.message,
        })
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    })
}

//Get all drafts from database
export const fetchAllDrafts = (currentUserData: {
  currentUserId: string
  currentUserRole: string
  currentUserPracticeId: string
  isDeleted: boolean
  isCompleted: boolean
}) => (dispatch: AppDispatch): void => {
  dispatch({
    type: DRAFT_LOADER,
    payload: true,
  })
  // dispatch({
  //   type: FETCH_ALL_DRAFT,
  //   payload: [],
  // })
  dispatch({
    type: CURRENT_DRAFT,
    payload: [],
  })
  axios
    .post(getServerUrl() + '/api/v1/drafts/fetchAllDrafts', currentUserData)
    .then((res) => {
      dispatch(checkAuthorization(res))
      const { status } = res.data
      if (status === '1') {
        const { data } = res.data
        dispatch({
          type: FETCH_ALL_DRAFT,
          payload: data,
        })
      } else {
        dispatch({
          type: FETCH_ALL_DRAFT,
          payload: [],
        })
        dispatch({
          type: GET_ERRORS,
          payload: res.data.message,
        })
      }
    })
    .catch((errors) => {
      dispatch({
        type: FETCH_ALL_DRAFT,
        payload: [],
      })
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    })
    .finally(() => {
      dispatch({
        type: DRAFT_LOADER,
        payload: false,
      })
    })
}

export interface IModifyDraft {
  caseId: string
  providerId: string
  specialistId: string
  patientId: string
  specialtyIds?: number[]
  patientName: string
  dob: string
  questions: string
  files: { name: string; type: string }[]
  isCompleted: boolean
  patientConsentGiven: boolean
}

export const modifyDraft = (draftData: IModifyDraft) => (
  dispatch: AppDispatch
): void => {
  axios
    .post(getServerUrl() + '/api/v1/drafts/modifyDraftDataById', draftData)
    .then((res) => {
      dispatch(checkAuthorization(res))
      const { status } = res.data
      if (status === '1') {
        return
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.message,
        })
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    })
}

interface ISetDraftAsCompleted {
  caseId: string
  isCompleted: boolean
}
export const setDraftAsCompleted = (data: ISetDraftAsCompleted) => (
  dispatch: AppDispatch
): void => {
  axios
    .post(getServerUrl() + '/api/v1/drafts/modifyDraftDataById', data)
    .then((res) => {
      dispatch(checkAuthorization(res))
      const { status } = res.data
      if (status === '1') {
        return
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.message,
        })
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    })
}

//Read messages with channel data
export const removeDraft = (request: { caseId: string }) => (
  dispatch: AppDispatch
): void => {
  dispatch({
    type: DRAFT_LOADER,
    payload: true,
  })
  axios
    .post(getServerUrl() + '/api/v1/drafts/removeDraft', request)
    .then(function (res) {
      const { status } = res.data

      if (status === '1') {
        dispatch({
          type: DELETE_DRAFT,
          payload: request.caseId,
        })
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.message,
        })
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    })
    .finally(() => {
      dispatch({
        type: DRAFT_LOADER,
        payload: false,
      })
    })
}

export const removeDraftByIds = (request: { caseIds: string[] }) => (
  dispatch: AppDispatch
): void => {
  dispatch({
    type: DRAFT_LOADER,
    payload: true,
  })
  axios
    .post(getServerUrl() + '/api/v1/drafts/removeDraftByIds', request)
    .then((res) => {
      dispatch(checkAuthorization(res))
      const { status } = res.data
      if (status === '1') {
        dispatch({
          type: DELETE_DRAFTS,
          payload: request.caseIds,
        })
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.message,
        })
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    })
    .finally(() => {
      dispatch({
        type: DRAFT_LOADER,
        payload: false,
      })
    })
}
