import classnames from 'classnames'
import React from 'react'

import CreateButton from '../../../components/CreateButton/CreateButton'
import Header from '../Header/Header'
import HelpButton from '../Help/HelpButton'
import { useDashboardStyles } from './Layout.styles'

interface IDashboardLayoutProps {
  children: React.ReactNode
}

const DashboardLayout: React.FC<IDashboardLayoutProps> = ({ children }) => {
  const classes = useDashboardStyles()

  return (
    <div data-selector="dashboard-layout" className={classes.root}>
      <Header />
      <CreateButton />
      <main className={classnames(classes.content)}>{children}</main>
      <HelpButton />
    </div>
  )
}

export default DashboardLayout
