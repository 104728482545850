import * as Amplitude from '@amplitude/node'

import { env } from '../constants/env'

// TODO add amplitude key in .env file
const key = env.REACT_APP_AMPLITUDE ? env.REACT_APP_AMPLITUDE : ''
const client = Amplitude.init(key)

export const sendAmplitudeData = async (
  userEmail: string,
  eventType: string,
  data: Record<string, unknown>
): Promise<void> => {
  if (key) {
    let eventProperties = data
    if (data.id) {
      eventProperties = {
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        userType: data.userType,
        isAdmin: data.isAdmin,
        practiceId: data.practiceId,
        specialty: data.specialty,
      }
      if (data.caseId) {
        eventProperties.caseId = data.caseId
      }
    }
    await client.logEvent({
      user_id: userEmail,
      event_type: eventType,
      event_properties: eventProperties,
    })
    client.flush()
  }
}
