import {
  Button,
  Card,
  CardActions,
  Fab,
  Link,
  MobileStepper,
  Modal,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import GetAppIcon from '@material-ui/icons/GetApp'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import React, { useState } from 'react'
import { pdfjs } from 'react-pdf'

import ViewFile from '../../../shared/components/ViewFile/ViewFile'
import ViewFileThumbnail from '../../../shared/components/ViewFileThumbnail/ViewFileThumbnail'
import { useAppSelector } from '../../../shared/store'
import { useAttachCardStyles } from './AttachCard.styles'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const AttachCard: React.FC<{
  fileState: { fileData: { name: string; type: string }; fileUrl: string }
  fileIndex: number
  actionIcon: React.ReactNode
}> = ({ fileState, actionIcon, fileIndex }) => {
  const [displayModal, setDisplayModal] = useState(false)
  const [activeStep, setActiveStep] = useState(0)

  const currentCase = useAppSelector((state) => state.case.currentCase)
  const userdata = useAppSelector((state) => state.auth.userdata)

  const styles = useAttachCardStyles()

  const handleCardClick = (fileIndex: number) => {
    setDisplayModal(true)
    setActiveStep(fileIndex)
  }

  const handleClose = () => {
    setDisplayModal(false)
  }

  const handleNext = () => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handleBack = () => {
    setActiveStep((prevState) => prevState - 1)
  }

  const downloadFile = (url: string) => {
    fetch(url, {
      method: 'GET',
      headers: {},
    })
      .then((response: any) => {
        response.arrayBuffer().then(function (buffer: Buffer) {
          const ext = response.url.split('.').pop().split(/#|\?/)[0] //TODO: This may error with old files find example and fix
          const fileName = 'file_' + new Date().getTime() + '.' + ext
          const url = window.URL.createObjectURL(new Blob([buffer]))

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const caseDataFiles = currentCase.caseData ? currentCase.caseData.files : []
  const maxSteps = caseDataFiles.length

  const keyboardEvents = (e: React.KeyboardEvent) => {
    const backDisabled = activeStep === 0
    const nextDisabled = activeStep === maxSteps - 1

    if (e.keyCode === 37 && !backDisabled) {
      handleBack()
    } else if (e.keyCode === 39 && !nextDisabled) {
      handleNext()
    }
  }

  return (
    <div>
      <div className={styles.container}>
        <Card
          className={styles.contentCard}
          data-selector={`file-card-${fileIndex}`}
        >
          {userdata.userType === 'PCP' || userdata.userType === 'Admin'
            ? actionIcon
            : null}

          <CardActions
            className={styles.cardAction}
            onClick={() => handleCardClick(fileIndex)}
          >
            <ViewFileThumbnail
              fileData={fileState.fileData}
              fileUrl={fileState.fileUrl}
            />
          </CardActions>

          <div>
            <p>
              <Link
                onClick={() => downloadFile(fileState.fileUrl)}
                style={{ float: 'right' }}
              >
                <IconButton data-selector={`download-file-${fileIndex}-btn`}>
                  <GetAppIcon style={{ marginLeft: 5, color: '#004866' }} />
                </IconButton>
              </Link>
            </p>
          </div>
        </Card>
      </div>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={displayModal}
        onClose={handleClose}
        BackdropProps={{ style: { top: 64, left: '0%' } }} //The grey Modal background when a file is opened
        onKeyDown={keyboardEvents}
      >
        <div className={styles.modalContainer}>
          <Card
            data-selector="file-card-modal"
            className={styles.modalCard}
            style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
          >
            <ViewFile
              file={caseDataFiles[activeStep].fileData}
              fileUrl={caseDataFiles[activeStep].fileUrl}
            />
          </Card>

          <MobileStepper
            steps={maxSteps}
            position="static"
            variant="text"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
                style={{ color: '#fff' }}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                style={{ color: '#fff' }}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              paddingTop: 5,
            }}
          >
            <Fab
              variant="round"
              className={styles.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </Fab>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AttachCard
