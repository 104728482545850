import produce from 'immer'
import { AnyAction } from 'redux'

import { ICase } from '../../types'
import {
  ADD_DRAFT,
  CURRENT_DRAFT,
  DELETE_DRAFT,
  DELETE_DRAFTS,
  DRAFT_LOADER,
  FETCH_ALL_DRAFT,
  SAVE_DRAFT,
  UPDATE_DRAFT,
} from './types'

export interface IDraftReducer {
  saveDraft: ICase[]
  currentDraft: { draftData?: ICase }
  readAllDrafts: { draftData: ICase }[]
  draftLoader: boolean
}

const initialState: IDraftReducer = {
  saveDraft: [],
  currentDraft: {},
  readAllDrafts: [],
  draftLoader: false,
}
const draftReducer = (
  state = initialState,
  action: AnyAction
): IDraftReducer => {
  switch (action.type) {
    case SAVE_DRAFT:
      return {
        ...state,
        saveDraft: action.payload,
      }
    case UPDATE_DRAFT:
      return produce(state, (draftState) => {
        const indexOfItem = draftState.readAllDrafts.findIndex(
          (item) => item.draftData.caseId === action.payload.caseId
        )
        if (indexOfItem !== -1) {
          draftState.readAllDrafts[indexOfItem] = { draftData: action.payload }
        }
      })
    case ADD_DRAFT:
      return produce(state, (draftState) => {
        draftState.readAllDrafts.push({ draftData: action.payload })
      })
    case DELETE_DRAFT:
      return produce(state, (draftState) => {
        const indexOfItem = draftState.readAllDrafts.findIndex(
          (item) => item.draftData.caseId === action.payload
        )
        if (indexOfItem !== -1) {
          draftState.readAllDrafts.splice(indexOfItem, 1)
        }
      })
    case DELETE_DRAFTS:
      return {
        ...state,
        readAllDrafts: state.readAllDrafts.filter(
          (item) => !action.payload.includes(item.draftData.caseId)
        ),
      }
    case CURRENT_DRAFT:
      return {
        ...state,
        currentDraft: action.payload,
      }
    case FETCH_ALL_DRAFT:
      return {
        ...state,
        readAllDrafts: action.payload,
      }
    case DRAFT_LOADER:
      return {
        ...state,
        draftLoader: action.payload,
      }
    default:
      return state
  }
}
export default draftReducer
