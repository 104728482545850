import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useAppSelector } from '../../store'
import AssignConsultLayout from '../layouts/AssignConsultLayout'

const AssignConsultRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  if (!component) {
    throw Error('Component is Undefined')
  }
  const auth = useAppSelector((state) => state.auth)
  const Component = component

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        auth.isAuthenticated === true &&
        auth.userdata &&
        auth.userdata.userType === 'Admin' ? (
          <AssignConsultLayout>
            <Component {...matchProps} />
          </AssignConsultLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

export default AssignConsultRoute
