import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'

const styles = (theme) => ({
  container: {
    height: '96%',
    width: '100%',
  },
  activeStyle: {
    borderColor: '#2196f3',
  },
  mainDropzone: {
    position: 'absolute',
    top: '9%',
    left: 0,
    zIndex: 1000,
    width: '100%',
    height: '91%',
    padding: 15,
    visibility: 'hidden',
    background: '#fff',
    opacity: 1,
    outline: 'none',
  },
  dropzoneInner: {
    color: '#13c1cf',
    border: '1px dashed #13c1cf',
    height: '100%',
    outline: 'none',
  },
  mainText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
    textAlign: 'center',
    color: '#13c1cf',
    position: 'absolute',
    top: '40%',
    width: '92%',
  },
})
const acceptedFileTypes =
  'application/*, image/*, video/mp4,video/x-m4v,video/*, text/*, audio/*, document/*, .rtf, .dot, .heic, .heif'

class DropzonePlugin extends Component {
  onDrop = (file) => {
    this.props.handleFileUpload(file, true)
    const div = document.getElementById('webapp-drag-drop-div')
    div.style.visibility = 'hidden'
  }
  onDragLeave = () => {
    const div = document.getElementById('webapp-drag-drop-div')
    div.style.visibility = 'hidden'
  }

  render() {
    const { classes } = this.props
    return (
      <Dropzone
        data-cy="drag-and-drop"
        accept={acceptedFileTypes}
        onDrop={this.onDrop}
        onDragLeave={this.onDragLeave}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps({
              className: `${classes.activeStyle} ${classes.mainDropzone}`,
            })}
            id="webapp-drag-drop-div"
          >
            <div className={classes.dropzoneInner}>
              <h2 className={classes.mainText}>Drop to upload files</h2>
            </div>
          </div>
        )}
      </Dropzone>
    )
  }
}

export default withStyles(styles)(DropzonePlugin)
