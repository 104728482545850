import produce from 'immer'
import { AnyAction } from 'redux'

import { ICase } from '../../types'
import {
  ACTION_PERFORMED,
  ADD_NEW_CASE,
  CASE_LOADER,
  CLEAR_ATHENA_DATA_AND_CURRENT_CASE_DATA,
  CURRENT_CASE,
  FETCH_ALL_CASES,
  FETCH_ATHENA_DATA,
  GET_ALL_SPECIALTIES_USERS,
  GET_NUM_NEW_MESSAGE,
  SEND_CASE,
  SET_ATHENA_LATEST_ENCOUNTER_SUMMARY,
  SET_CERBO_LATEST_ENCOUNTER_SUMMARY,
  SET_CURRENT_CASE_PROCEDURE_CODES,
  SET_ELATION_LATEST_ENCOUNTER_SUMMARY,
  SET_PATIENT_ATHENA_DIAGNOSES,
  SET_SEARCHED_DIAGNOSES,
  SET_USER_PATIENT_IS_LOADING,
  SET_USER_PATIENT_LIST,
  SPECIALTY_LIST,
  UPDATE_CASE,
} from './types'

export interface ICaseReducer {
  sendCases: ICase[]
  currentCase: ICase | any
  readAllCases: { caseData: ICase }[]
  userPatient: {
    list: any[]
    isLoading: boolean
  }
  specialtyList: any[]
  athenaData: any
  athenaLatestEncouterSummary: {
    pdfFileObj: {
      name: string
      type: string
    } | null
    pdfUrl: string
    isLoading: boolean
  }
  numberOfNewMessage: number
  caseLoader: boolean
  isActionPerformed: boolean
  searchedDiagnoses: {
    code: string
    name: string
  }[]
  patientAthenaDiagnoses: {
    code: string
    name: string
  }[]
  allSpecialtiesUsers: any[]
  cerboLatestEncouterSummary: {
    pdfFileObj: {
      name: string
      type: string
    } | null
    pdfUrl: string
    files: any[]
    isLoading: boolean
  }
  elationLatestEncouterSummary: {
    pdfFileObj: {
      name: string
      type: string
    } | null
    pdfUrl: string
    isLoading: boolean
  }
}

const initialState: ICaseReducer = {
  sendCases: [],
  currentCase: {},
  readAllCases: [],
  userPatient: {
    list: [],
    isLoading: false,
  },
  specialtyList: [],
  athenaData: {},
  athenaLatestEncouterSummary: {
    pdfFileObj: null,
    pdfUrl: '',
    isLoading: false,
  },
  numberOfNewMessage: 0,
  caseLoader: false,
  isActionPerformed: false,
  searchedDiagnoses: [],
  patientAthenaDiagnoses: [],
  allSpecialtiesUsers: [],
  cerboLatestEncouterSummary: {
    pdfFileObj: null,
    pdfUrl: '',
    files: [],
    isLoading: false,
  },
  elationLatestEncouterSummary: {
    pdfFileObj: null,
    pdfUrl: '',
    isLoading: false,
  },
}

const caseReducer = (state = initialState, action: AnyAction): ICaseReducer => {
  switch (action.type) {
    case SEND_CASE:
      return {
        ...state,
        sendCases: action.payload,
      }
    case CURRENT_CASE:
      return {
        ...state,
        currentCase: {
          procedureCodes: state.currentCase.procedureCodes,
          ...action.payload,
        },
      }
    case SET_CURRENT_CASE_PROCEDURE_CODES:
      return produce(state, (draftState) => {
        draftState.currentCase.procedureCodes = action.payload
      })
    case SET_SEARCHED_DIAGNOSES:
      return produce(state, (draftState) => {
        draftState.searchedDiagnoses = action.payload
      })
    case FETCH_ALL_CASES:
      return {
        ...state,
        readAllCases: action.payload,
      }
    case ADD_NEW_CASE:
      return produce(state, (draftState) => {
        draftState.readAllCases.push({ caseData: action.payload })
      })
    case UPDATE_CASE:
      return produce(state, (draftState) => {
        const indexOfItem = draftState.readAllCases.findIndex(
          (item) => item.caseData.caseId === action.payload.caseId
        )
        if (indexOfItem !== -1) {
          draftState.readAllCases[indexOfItem] = { caseData: action.payload }
        }
      })
    case CASE_LOADER:
      return {
        ...state,
        caseLoader: action.payload,
      }
    case GET_NUM_NEW_MESSAGE:
      return {
        ...state,
        numberOfNewMessage: action.payload,
      }
    case SET_USER_PATIENT_LIST:
      return produce(state, (draftState) => {
        draftState.userPatient.list = action.payload
      })
    case SET_USER_PATIENT_IS_LOADING:
      return produce(state, (draftState) => {
        draftState.userPatient.isLoading = action.payload
      })
    case ACTION_PERFORMED:
      return {
        ...state,
        isActionPerformed: action.payload,
      }
    case SPECIALTY_LIST:
      return {
        ...state,
        specialtyList: action.payload,
      }
    case SET_ATHENA_LATEST_ENCOUNTER_SUMMARY:
      return {
        ...state,
        athenaLatestEncouterSummary: {
          pdfFileObj: action.payload.pdfFileObj,
          pdfUrl: action.payload.pdfUrl,
          isLoading: action.payload.isLoading,
        },
      }
    case SET_CERBO_LATEST_ENCOUNTER_SUMMARY:
      return {
        ...state,
        cerboLatestEncouterSummary: {
          pdfFileObj: action.payload.pdfFileObj,
          pdfUrl: action.payload.pdfUrl,
          files: action.payload.files,
          isLoading: action.payload.isLoading,
        },
      }
    case SET_ELATION_LATEST_ENCOUNTER_SUMMARY:
      return {
        ...state,
        elationLatestEncouterSummary: {
          pdfFileObj: action.payload.pdfFileObj,
          pdfUrl: action.payload.pdfUrl,
          isLoading: action.payload.isLoading,
        },
      }
    case SET_PATIENT_ATHENA_DIAGNOSES:
      return {
        ...state,
        patientAthenaDiagnoses: action.payload,
      }
    case FETCH_ATHENA_DATA:
      return {
        ...state,
        athenaData: action.payload,
      }
    case CLEAR_ATHENA_DATA_AND_CURRENT_CASE_DATA:
      return {
        ...state,
        athenaData: {},
        currentCase: {},
      }
    case GET_ALL_SPECIALTIES_USERS:
      return {
        ...state,
        allSpecialtiesUsers: action.payload,
      }
    default:
      return state
  }
}
export default caseReducer
