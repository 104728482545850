import { CircularProgress, Hidden } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AddAPhoto from '@material-ui/icons/AddAPhoto'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'

const styles = (theme) => ({
  container: {
    height: '40%',
    // marginTop: '7%',
    // width: "20%"
  },
  mainText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
    textAlign: 'center',
    color: '#818181',
  },
})
const acceptedFileTypes =
  'application/*, image/*, video/mp4,video/x-m4v,video/*, text/*, audio/*, document/*, .rtf, .dot, .heic, .heif'
class DropzonePlugin extends Component {
  onDrop = (file) => {
    this.props.handleFileUpload(file)
  }

  // removeFile = (fileIndex) => {
  //   this.props.removeFile(fileIndex)
  // }

  render() {
    const { classes } = this.props

    return (
      <Dropzone
        data-cy="drag-and-drop"
        accept={acceptedFileTypes}
        onDrop={this.onDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <section className={classes.container}>
            {this.props.isLoading && (
              <CircularProgress size={20} disableShrink />
            )}
            <div
              {...getRootProps({ className: 'dropzone' })}
              style={{ height: '100%', cursor: 'pointer' }}
            >
              <input {...getInputProps()} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Hidden only={['xs']}>
                  <CloudUploadIcon style={{ width: 80, height: 80 }} />
                  <p className={classes.mainText}>
                    Drag and drop or click here to upload files
                  </p>
                </Hidden>
                <Hidden smUp>
                  <AddAPhoto style={{ width: 80, height: 80 }} />
                  <p className={classes.mainText}>Take or upload a photo</p>
                </Hidden>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    )
  }
}

export default withStyles(styles)(DropzonePlugin)
