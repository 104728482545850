import { Button, Divider, Typography } from '@material-ui/core'
import html2pdf from 'html2pdf.js'
import moment from 'moment'
import { forwardRef, useImperativeHandle } from 'react'
import { v4 } from 'uuid'

import { useAppDispatch, useAppSelector } from '../../shared/store'
import {
  closeAllInOneCaseAndUploadDocument,
  closeCerboCaseAndUploadDocument,
  createAthenaClinicalDocumentRecord,
  createAthenaClinicalDocumentRecordAndModifyCase,
  createElationDocumentReportRecordAndModifyCase,
  sendCloseCasePdfFax,
} from '../../shared/store/case/actions'
import { getDefaultHtml2PdfOptions } from '../../shared/utils/constant'
import { decodeString } from '../../shared/utils/functions'
import { useMyDocumentStyles } from './MyDocument.styles'

const MyDocument = forwardRef((_props, ref) => {
  const practiceData = useAppSelector((state) => state.auth.practiceData)
  const currentCase = useAppSelector((state) => state.case.currentCase)
  const userData = useAppSelector((state) => state.auth.userdata)
  const readedMessages = useAppSelector((state) => state.chat.readMessages)

  const dispatch = useAppDispatch()

  const classes = useMyDocumentStyles()

  const downloadPDF = () => {
    const date = new Date()
    const d = date.getDate()
    const m: any = date.getMonth() + 1
    const y = date.getFullYear()
    let dateString: any = (m <= 9 ? '0' + m : m) + (d <= 9 ? '0' + d : d) + y

    let fileName = 'TempFile_' + dateString
    if (currentCase && currentCase.caseData) {
      const day = currentCase.caseData.createdAtOld
        ? new Date(Number(currentCase.caseData.createdAtOld))
        : new Date(currentCase.caseData.createdAt)
      dateString =
        ('0' + (day.getMonth() + 1)).slice(-2) +
        ('0' + day.getDate()).slice(-2) +
        day.getFullYear()
      fileName =
        userData.firstName +
        '_' +
        userData.lastName +
        '_' +
        currentCase.caseData.specialty?.map((item) => item.title).join(', ') +
        '_' +
        dateString
      fileName = fileName.replace(/,\s/g, '')
    }
    if (userData.userType === 'Biller') {
      fileName = dateString + '_consult_' + currentCase.caseData.caseId
    }

    const element = document.getElementById('closedCasePdf')
    html2pdf(element, getDefaultHtml2PdfOptions(fileName))

    // download extra file with pdf
    if (
      currentCase.caseData &&
      currentCase.caseData.files &&
      currentCase.caseData.files.length > 0
    ) {
      const files = currentCase.caseData.files
      files.forEach(function (fData) {
        const file = fData.fileData
        const url = fData.fileUrl
        const ftype = file.type.substring(0, file.type.indexOf('/'))
        if (ftype && ftype !== 'image') {
          fetch(url, {
            method: 'GET',
            headers: {},
          })
            .then((response) => {
              // if (response?.url) {
              response.arrayBuffer().then(function (buffer) {
                const ext = response.url.split('.').pop()!.split(/#|\?/)[0]
                const fileName = 'file_' + new Date().getTime() + '.' + ext
                const url = window.URL.createObjectURL(new Blob([buffer]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                link.remove()
                // }
              })
            })
            .catch((err) => {
              console.info('error from html2pdf ', err)
            })
        }
      })
    }
  }

  const savePDFFileToDrive = () => {
    const date = new Date()
    const d = date.getDate()
    const m: any = date.getMonth() + 1
    const y = date.getFullYear()
    const dateString = (m <= 9 ? '0' + m : m) + (d <= 9 ? '0' + d : d) + y

    let fileName = 'TempFile_' + dateString
    if (currentCase && currentCase.caseData) {
      fileName =
        userData.firstName +
        '_' +
        userData.lastName +
        '_' +
        currentCase.caseData.specialty?.map((item) => item.title).join(', ') +
        '_' +
        dateString
      fileName = fileName.replace(/,\s/g, '')
    }
    if (userData.userType === 'Biller') {
      fileName = dateString + '_consult_' + currentCase.caseData.caseId
    }

    const element = document.getElementById('closedCasePdf')

    html2pdf()
      .from(element)
      .set(getDefaultHtml2PdfOptions(fileName))
      .toPdf()
      .output('arraybuffer')
      .then((arrayBuffer) => {
        // const pdfFile = new File([arrayBuffer], `${fileName}.pdf`, {
        //   type: 'application/pdf',
        // })
        // const fileData = {
        //   practiceId: practiceData.id,
        //   caseId: currentCase.caseData.caseId,
        //   folderId: practiceData.gdrive_folder_id
        //     ? practiceData.gdrive_folder_id
        //     : '',
        //   prevFileId: currentCase.caseData.gdrive_file_id
        //     ? currentCase.caseData.gdrive_file_id
        //     : '',
        //   currentUserRole: userData.userType,
        //   attachmentContents: pdfFile,
        // }
        //this.props.uploadFileToDrive(fileData) // TODO: remember what this is used for and get rid of it if not used
      })
      .catch((err) => {
        console.info('error from html2pdf ', err)
      })
  }

  const sendCloseCaseFax = () => {
    const file_name = new Date().getTime() + '.pdf'
    const element = document.getElementById('closedCasePdf')

    html2pdf()
      .from(element)
      .set(getDefaultHtml2PdfOptions(file_name))
      .toPdf()
      .output('arraybuffer')
      .then((arrayBuffer) => {
        const pdfFile = new File([arrayBuffer], `${file_name}`, {
          type: 'application/pdf',
        })
        const fileData = {
          practiceId: practiceData.id,
          caseId: currentCase.caseData.caseId,
          currentUserRole: userData.userType,
          attachmentContents: pdfFile,
        }
        dispatch(sendCloseCasePdfFax(fileData))
      })
      .catch((err) => {
        console.info('error from html2pdf ', err)
      })
  }

  const createAthenaClinicalDocumentRecordAndModifyCaseInDocument = () => {
    const date = new Date()
    const d = date.getDate()
    const m: any = date.getMonth() + 1
    const y = date.getFullYear()
    const dateString = (m <= 9 ? '0' + m : m) + (d <= 9 ? '0' + d : d) + y

    let fileName = 'TempFile_' + dateString
    if (currentCase && currentCase.caseData) {
      fileName =
        userData.firstName +
        '_' +
        userData.lastName +
        '_' +
        currentCase.caseData.specialty?.map((item) => item.title).join(', ') +
        '_' +
        dateString
      fileName = fileName.replace(/,\s/g, '')
    }
    if (userData.userType === 'Biller') {
      fileName = dateString + '_consult_' + currentCase.caseData.caseId
    }

    const element = document.getElementById('closedCasePdf')

    html2pdf()
      .from(element)
      .set(getDefaultHtml2PdfOptions(fileName))
      .toPdf()
      .output('arraybuffer')
      .then((arrayBuffer) => {
        const pdfFile = new File([arrayBuffer], `${fileName}.pdf`, {
          type: 'application/pdf',
        })

        dispatch(
          createAthenaClinicalDocumentRecordAndModifyCase({
            departmentId: 1,
            documentSubclass: 'CLINICALDOCUMENT',
            athenaPracticeId: practiceData.athenaPracticeId,
            practiceId: practiceData.id,
            patientId: currentCase.patientData.athenaPatientId,
            attachmentContents: pdfFile,
            caseId: currentCase.caseData.caseId,
          } as any)
        )
      })
      .catch((err) => {
        console.info('error from html2pdf ', err)
      })
  }

  const createElationDocumentReportRecordAndModifyCaseInDocument = () => {
    const date = new Date()
    const d = date.getDate()
    const m: any = date.getMonth() + 1
    const y = date.getFullYear()
    const dateString = (m <= 9 ? '0' + m : m) + (d <= 9 ? '0' + d : d) + y

    let fileName = 'TempFile_' + dateString
    if (currentCase && currentCase.caseData) {
      fileName =
        userData.firstName +
        '_' +
        userData.lastName +
        '_' +
        currentCase.caseData.specialty?.map((item) => item.title).join(', ') +
        '_' +
        dateString
      fileName = fileName.replace(/,\s/g, '')
    }
    if (userData.userType === 'Biller') {
      fileName = dateString + '_consult_' + currentCase.caseData.caseId
    }

    const element = document.getElementById('closedCasePdf')

    html2pdf()
      .from(element)
      .set(getDefaultHtml2PdfOptions(fileName))
      .outputPdf()
      .then(function (pdf) {
        const pdfFileBase64 = btoa(pdf)

        dispatch(
          createElationDocumentReportRecordAndModifyCase({
            elationPatientId: currentCase.patientData.elationPatientId,
            elationPatientPhysicianId:
              currentCase.patientData.elationPatientPhysicianId,
            elationPatientPracticeId: practiceData.elationPracticeId,
            practiceId: practiceData.id,
            base64Content: pdfFileBase64,
          })
        )
      })
      .catch((err) => {
        console.info('error from html2pdf ', err)
      })
  }

  const closeCerboCaseAndUploadDocumentInDocument = () => {
    const date = new Date()
    const d = date.getDate()
    const m: any = date.getMonth() + 1
    const y = date.getFullYear()
    const dateString = (m <= 9 ? '0' + m : m) + (d <= 9 ? '0' + d : d) + y

    let fileName = 'TempFile_' + dateString
    if (currentCase && currentCase.caseData) {
      fileName =
        userData.firstName +
        '_' +
        userData.lastName +
        '_' +
        currentCase.caseData.specialty?.map((item) => item.title).join(', ') +
        '_' +
        dateString
      fileName = fileName.replace(/,\s/g, '')
    }
    if (userData.userType === 'Biller') {
      fileName = dateString + '_consult_' + currentCase.caseData.caseId
    }

    const element = document.getElementById('closedCasePdf')

    html2pdf()
      .from(element)
      .set(getDefaultHtml2PdfOptions(fileName))
      .outputPdf()
      .then(function (pdf) {
        const pdfFileBase64 = btoa(pdf)

        const uploadDocumentData = {
          cerboPatientId: currentCase.patientData.cerboPatientId,
          documentMimeType: 'application/pdf',
          documentName: `${currentCase.patientData.firstName}.${
            currentCase.patientData.lastName
          }.${v4()}`,
          documentBase64Content: pdfFileBase64,
          practiceId: practiceData.id,
        }

        dispatch(
          closeCerboCaseAndUploadDocument({
            caseId: currentCase.caseData.caseId,
            uploadDocumentData,
          })
        )
      })
      .catch((err) => {
        console.info('error from html2pdf ', err)
      })
  }

  //TODO: This sucks and should be refactored to have one doc creation function w/ helpers just for the uploads rather than whatever this is
  // TODO: also the naming convention is so long and terrible
  const closeAllInOneCaseAndUploadDocumentInDocument = () => {
    const date = new Date()
    const d = date.getDate()
    const m: any = date.getMonth() + 1
    const y = date.getFullYear()
    const dateString = (m <= 9 ? '0' + m : m) + (d <= 9 ? '0' + d : d) + y

    let fileName = 'TempFile_' + dateString
    if (currentCase && currentCase.caseData) {
      //fileName = `public/pat-ZR5sqfyKoAcy/${
      fileName = `public/${
        currentCase.patientData.hintPatientId
      }/TheaConsults/${currentCase.caseData.specialty
        ?.map((item) => item.title)
        .join(', ')} eConsult Report for ${currentCase.patientData.firstName} ${
        currentCase.patientData.lastName
      } Closed ${new Date().toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })}.pdf__keenhp-file__${v4()}.pdf`
      // fileName =
      //   userData.firstName +
      //   '_' +
      //   userData.lastName +
      //   '_' +
      //   currentCase.caseData.specialty?.map((item) => item.title).join(', ') +
      //   '_' +
      //   dateString
      // fileName = fileName.replace(/,\s/g, '')
    }
    if (userData.userType === 'Biller') {
      fileName = dateString + '_consult_' + currentCase.caseData.caseId
    }

    const element = document.getElementById('closedCasePdf')

    html2pdf()
      .from(element)
      .set(getDefaultHtml2PdfOptions(fileName))
      .outputPdf()
      .then(function (pdf) {
        const pdfFileBase64 = btoa(pdf)
        console.log(pdfFileBase64)
        //TODO uncomment when db stuff is done
        const uploadDocumentData = {
          hintPatientId: currentCase.patientData.hintPatientId, //'pat-ZR5sqfyKoAcy',
          documentMimeType: 'application/pdf',
          //documentName: `public/pat-ZR5sqfyKoAcy/${
          documentName: fileName,

          // `${
          //   currentCase.patientData.hintPatientId
          // }/ConsultReportFor${currentCase.patientData.firstName}${
          //   currentCase.patientData.lastName
          // }_${v4()}.pdf__keenhp-file__${v4()}.pdf`,
          // documentName: `public/pat-ZR5sqfyKoAcy/${
          //   // documentName: `public/${currentCase.patientData.hintPatientId}/${
          //   currentCase.patientData.firstName
          // }_${currentCase.patientData.lastName}_${currentCase.caseData.specialty
          //   ?.map((item) => item.title)
          //   .join(
          //     '_'
          //   )}_report_${new Date().toString()}.pdf__keenhp-file__${v4()}.pdf`, //TODO declare some of this as constants elsewhere or even move this logic to the backend
          document: pdfFileBase64, //pdf,
          //documentBase64Content: pdfFileBase64,
          practiceId: practiceData.id,
        }

        dispatch(
          closeAllInOneCaseAndUploadDocument({
            caseId: currentCase.caseData.caseId,
            uploadDocumentData,
          })
        )
      })
      .catch((err) => {
        console.info('error from html2pdf ', err)
      })
  }

  const createAthenaClinicalDocumentRecordInDocument = () => {
    const date = new Date()
    const d = date.getDate()
    const m: any = date.getMonth() + 1
    const y = date.getFullYear()
    const dateString = (m <= 9 ? '0' + m : m) + (d <= 9 ? '0' + d : d) + y

    let fileName = 'TempFile_' + dateString
    if (currentCase && currentCase.caseData) {
      fileName =
        userData.firstName +
        '_' +
        userData.lastName +
        '_' +
        currentCase.caseData.specialty?.map((item) => item.title).join(', ') +
        '_' +
        dateString
      fileName = fileName.replace(/,\s/g, '')
    }
    if (userData.userType === 'Biller') {
      fileName = dateString + '_consult_' + currentCase.caseData.caseId
    }

    const element = document.getElementById('closedCasePdf')

    html2pdf()
      .from(element)
      .set(getDefaultHtml2PdfOptions(fileName))
      .toPdf()
      .output('arraybuffer')
      .then((arrayBuffer) => {
        const pdfFile = new File([arrayBuffer], `${fileName}.pdf`, {
          type: 'application/pdf',
        })

        dispatch(
          createAthenaClinicalDocumentRecord({
            departmentId: 1,
            documentSubclass: 'CLINICALDOCUMENT',
            athenaPracticeId: practiceData.athenaPracticeId,
            practiceId: practiceData.id,
            patientId: currentCase.patientData.athenaPatientId,
            attachmentContents: pdfFile,
          } as any)
        )
      })
      .catch((err) => {
        console.info('error from html2pdf ', err)
      })
  }

  const _renderResponseDate = (chats) => {
    for (const chat of chats) {
      if (chat.sender_id != userData.id) {
        return (
          <Typography className={classes.demoTitle}>
            Date of specialist interpretation:{' '}
            {moment(chat.created).format('MM/DD/YYYY HH:mm')}
          </Typography>
        )
      }
    }
  }

  const _renderChatting = (chats) => {
    return chats.map((message, key) => {
      let userName = ''
      if (currentCase && currentCase.caseData) {
        userName =
          currentCase.caseData.providerFirstName +
          ' ' +
          currentCase.caseData.providerLastName
      }
      if (message.senderId === userData.id) {
        userName = userData.firstName + ' ' + userData.lastName
      } else if (currentCase.senderData && currentCase.senderData.length) {
        currentCase.senderData.forEach((sender) => {
          if (message.senderId === sender.id) {
            userName = sender.firstName + ' ' + sender.lastName
          }
        })
      }
      return (
        <div key={message.created}>
          <Typography className={classes.detail}>
            {userName}
            {` at `}
            {moment.utc(message.created).format('YYYY-MM-DD HH:mm')}
          </Typography>
          <Typography className={classes.text}>
            {decodeString(message.content)}
          </Typography>
        </div>
      )
    })
  }

  const _toDataUrl = (url, callback) => {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      const reader = new FileReader()
      reader.onloadend = () => {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }

  const _renderImages = (images) => {
    return images.map((image, key) => {
      _toDataUrl(image.fileUrl, (myBase64) => {})
      return (
        <img
          alt=""
          key={image.fileUrl}
          className={`${classes.image} pdf-avoid-split`}
          src={image.fileUrl}
        />
      )
    })
  }

  useImperativeHandle(ref, () => ({
    downloadPDF: () => downloadPDF(),
    savePDFFileToDrive: () => savePDFFileToDrive(),
    closeCerboCaseAndUploadDocument: () =>
      closeCerboCaseAndUploadDocumentInDocument(),
    closeAllInOneCaseAndUploadDocument: () =>
      closeAllInOneCaseAndUploadDocumentInDocument(),
    createAthenaClinicalDocumentRecordAndModifyCase: () =>
      createAthenaClinicalDocumentRecordAndModifyCaseInDocument(),
    createElationDocumentReportRecordAndModifyCase: () =>
      createElationDocumentReportRecordAndModifyCaseInDocument(),
    createAthenaClinicalDocumentRecord: () =>
      createAthenaClinicalDocumentRecordInDocument(),
    sendCloseCaseFax: () => sendCloseCaseFax(),
  }))

  const patientName =
    currentCase && currentCase.caseData && currentCase.caseData.patientName
  const specialty = currentCase?.caseData?.specialty
    ?.map((item) => item.title)
    .join(', ')
  const providerName =
    currentCase &&
    currentCase.caseData &&
    currentCase.caseData.providerFirstName +
      ' ' +
      currentCase.caseData.providerLastName

  const senderNames: any = []
  let sender_names: any = ''
  if (currentCase && currentCase.senderData) {
    currentCase.senderData.forEach((sender) => {
      let sname = ''
      if (sender.firstName) {
        sname = sender.firstName
        if (sender.lastName) {
          sname = sender.firstName + ' ' + sender.lastName
        }
        senderNames.push(sname)
      }
    })
    sender_names = senderNames.join(' / ')
  }

  return (
    <div id="closedCasePdf" className={classes.closedCasePdfCl}>
      <div className={classes.body}>
        <Typography className={classes.title} component="div">
          Patient Demographics
        </Typography>
        <Typography className={classes.demoTitle} component="div">
          Patient Information
        </Typography>
        <Divider />
        <Typography className={classes.demo} component="div">
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.fullName && (
              <p>Patientss Name: {currentCase.patientData.fullName}</p>
            ) && <p>Patient Name: {currentCase.patientData.fullName}</p>}

          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.dob && (
              <p>
                Date of Birth:{' '}
                {moment(currentCase.patientData.dob).format('MM/DD/YYYY')}
              </p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.gender && (
              <p>Gender: {currentCase.patientData.gender}</p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.ssn && (
              <p>SSN: {currentCase.patientData.ssn}</p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.address &&
            currentCase.patientData.city &&
            currentCase.patientData.state && <p>Address:</p>}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.address && (
              <p>{currentCase.patientData.address}</p>
            )}

          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.city &&
            currentCase.patientData.state && (
              <p>
                {currentCase.patientData.city}, {currentCase.patientData.state}
              </p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.zip && <p>{currentCase.patientData.zip}</p>}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.mobile_no && (
              <p>Mobile Phone: {currentCase.patientData.mobile_no}</p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.homephone && (
              <p>Home Phone: {currentCase.patientData.homephone}</p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.email && (
              <p>Email: {currentCase.patientData.email}</p>
            )}
        </Typography>
        <Typography className={classes.demoTitle} component="div">
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.guarantor_frstnm && (
              <p> Guarantor Information </p>
            )}
        </Typography>
        {currentCase &&
          currentCase.patientData &&
          currentCase.patientData.guarantor_frstnm && <Divider />}
        <Typography className={classes.demo} component="div">
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.guarantor_frstnm &&
            currentCase.patientData.guarantor_lastnm && (
              <p>
                Guarantor Name: {currentCase.patientData.guarantor_frstnm}{' '}
                {currentCase.patientData.guarantor_lastnm}
              </p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.guarantor_addr &&
            currentCase.patientData.guarantor_city && <p>Address:</p>}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.guarantor_addr &&
            currentCase.patientData.guarantor_city && (
              <p>{currentCase.patientData.guarantor_addr}</p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.guarantor_city &&
            currentCase.patientData.guarantor_state && (
              <p>
                {currentCase.patientData.guarantor_city},{' '}
                {currentCase.patientData.guarantor_state}
              </p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.guarantor_zip && (
              <p>{currentCase.patientData.guarantor_zip}</p>
            )}
        </Typography>
        <Typography className={classes.demoTitle} component="div">
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.primary_ins_name &&
            currentCase.patientData.primary_ins_id && (
              <p>Primary Insurance Information</p>
            )}
        </Typography>
        <Divider />
        <Typography className={classes.demo} component="div">
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.primary_ins_name && (
              <p>
                Primary Insurance: {currentCase.patientData.primary_ins_name}
              </p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.primary_ins_id && (
              <p>
                Primary Subscriber Id: {currentCase.patientData.primary_ins_id}
              </p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.primary_grp_no && (
              <p>
                Primary Group Number: {currentCase.patientData.primary_grp_no}
              </p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.primary_ins_addr &&
            currentCase.patientData.primary_ins_id && <p>Primary Address:</p>}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.primary_ins_addr &&
            currentCase.patientData.primary_ins_id && (
              <p>{currentCase.patientData.primary_ins_addr}</p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.primary_ins_city &&
            currentCase.patientData.primary_ins_state && (
              <p>
                {currentCase.patientData.primary_ins_city},{' '}
                {currentCase.patientData.primary_ins_state}
              </p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.primary_ins_zip && (
              <p>{currentCase.patientData.primary_ins_zip}</p>
            )}
        </Typography>
        <Typography className={classes.demoTitle} component="div">
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.secondary_ins_name &&
            currentCase.patientData.secondary_ins_id && (
              <p>Secondary Insurance Information</p>
            )}
        </Typography>
        {currentCase &&
          currentCase.patientData &&
          currentCase.patientData.secondary_ins_name &&
          currentCase.patientData.secondary_ins_id && <Divider />}
        <Typography className={classes.demo} component="div">
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.secondary_ins_name &&
            currentCase.patientData.secondary_ins_id && (
              <p>
                Secondary Insurance:{' '}
                {currentCase.patientData.secondary_ins_name}
              </p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.secondary_ins_id &&
            currentCase.patientData.secondary_ins_id && (
              <p>
                Secondary Subscriber Id:{' '}
                {currentCase.patientData.secondary_ins_id}
              </p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.secondary_grp_no && (
              <p>
                Secondary Group Number:{' '}
                {currentCase.patientData.secondary_grp_no}
              </p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.secondary_ins_addr &&
            currentCase.patientData.secondary_ins_id && (
              <p>Secondary Address:</p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.secondary_ins_addr &&
            currentCase.patientData.secondary_ins_id && (
              <p>{currentCase.patientData.secondary_ins_addr}</p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.secondary_ins_city &&
            currentCase.patientData.secondary_ins_state && (
              <p>
                {currentCase.patientData.secondary_ins_city},{' '}
                {currentCase.patientData.secondary_ins_state}
              </p>
            )}
          {currentCase &&
            currentCase.patientData &&
            currentCase.patientData.secondary_ins_zip && (
              <p>{currentCase.patientData.secondary_ins_zip}</p>
            )}
        </Typography>

        <Typography variant="h1" className={`${classes.title}`}>
          {specialty} Consult Report for {patientName}
        </Typography>
        <Typography className={classes.author}>
          Requesting Provider:{' '}
          {userData.userType === 'Biller' || userData.userType === 'Admin'
            ? providerName
            : userData.firstName + ' ' + userData.lastName}{' '}
          {`\n\n`} <br />
          Responding Provider: {/*firstName} {lastName*/} {sender_names}{' '}
          {`\n\n`}
        </Typography>

        <Typography className={classes.demo} component="div">
          {userData && userData.npi && <p>NPI: {userData.npi}</p>}
          {userData && userData.licenseNumber && (
            <p>License Number: {userData.licenseNumber}</p>
          )}
        </Typography>
        {readedMessages && readedMessages.length >= 1 && (
          <Typography className={classes.demoTitle}>
            Date consultation was created by PCP:{' '}
            {moment(readedMessages[0].created).format('MM/DD/YYYY HH:mm')}
          </Typography>
        )}
        {readedMessages &&
          readedMessages.length >= 1 &&
          _renderResponseDate(readedMessages)}
        {currentCase.caseData && currentCase.caseData.patientConsentGiven && (
          <Typography className={classes.legal}>
            Patient has verbally consented to this consultation including any
            financial responsibilities that may arise from the consultation.
          </Typography>
        )}

        {readedMessages && readedMessages.length >= 1 ? (
          _renderChatting(readedMessages)
        ) : (
          <div></div>
        )}
        <div className={`${classes.docContainer}`}>
          {currentCase &&
          currentCase.caseData &&
          currentCase.caseData.files &&
          currentCase.caseData.files.length >= 1 &&
          patientName !== 'Christopher Puzio' ? (
            _renderImages(currentCase.caseData.files)
          ) : (
            <div></div>
          )}
          <Divider />
        </div>
        <Button
          id="downloadButton"
          className={classes.hide}
          variant="contained"
          color="primary"
          style={{ marginRight: 32, marginTop: 20, float: 'right' }}
          onClick={downloadPDF}
        >
          <Typography
          //   className={classes.sendButton}
          >
            Download Pdf
          </Typography>
        </Button>
      </div>
    </div>
  )
})
MyDocument.displayName = 'MyDocument'
export default MyDocument
