import {
  Avatar,
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import InputIcon from '@material-ui/icons/Input'
import LockIcon from '@material-ui/icons/Lock'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../store'
import { logoutUser } from '../../store/auth/actions'
import { sendAmplitudeData } from '../../utils/amplitude'
import { amplitudeEvents } from '../../utils/constant'

const UserMenu: React.FC = () => {
  const auth = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const [userMenu, setUserMenu] = useState<HTMLButtonElement | null>(null)

  const userMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserMenu(event.currentTarget)
  }

  const userMenuClose = () => {
    setUserMenu(null)
  }

  const onLogoutClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault()
    sendAmplitudeData(
      auth.userdata.email,
      amplitudeEvents.LOGOUT,
      auth.userdata
    )
    dispatch(logoutUser())
  }

  const userName = `${auth.userdata.firstName} ${auth.userdata.lastName}`
  const userAvatar = auth.userdata.avatar ? auth.userdata.avatar.name : null

  return (
    <>
      <Button onClick={userMenuClick} data-selector="nav-right-dropdown-button">
        {userAvatar ? <Avatar src={userAvatar} /> : <AccountCircleIcon />}
        <div className="hidden md:flex flex-col ml-12 items-start">
          <Typography
            component="span"
            className="normal-case font-600 flex"
            style={{
              paddingLeft: '5px',
            }}
          >
            {userName}
          </Typography>
        </div>
      </Button>

      <Popover
        open={!!userMenu}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {!auth.isAuthenticated ? (
          <>
            <MenuItem component={Link} to="/login">
              <ListItemIcon className="min-w-40">
                <LockIcon />
              </ListItemIcon>
              <ListItemText className="pl-0" primary="Login" />
            </MenuItem>
            <MenuItem component={Link} to="/register">
              <ListItemIcon className="min-w-40">
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText className="pl-0" primary="Register" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              component={Link}
              to="/account"
              onClick={userMenuClose}
              data-selector="account-button"
            >
              <ListItemIcon className="min-w-40">
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText className="pl-0" primary="Account" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/import"
              onClick={userMenuClose}
              data-selector="import-button"
            >
              <ListItemIcon className="min-w-40">
                <CloudUploadOutlinedIcon />
              </ListItemIcon>
              <ListItemText className="pl-0" primary="Import" />
            </MenuItem>
            <MenuItem onClick={onLogoutClick} data-selector="logout-button">
              <ListItemIcon className="min-w-40">
                <InputIcon />
              </ListItemIcon>
              <ListItemText className="pl-0" primary="Logout" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  )
}

export default UserMenu
