import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import React from 'react'

export function ConfirmationDialog(props) {
  function okOperationDialog() {
    props.okOperationDialog()
  }
  function handleClose() {
    props.handleClose()
  }
  return (
    <Dialog
      onClose={handleClose}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={props.dialogState}
    >
      <DialogTitle id="confirmation-dialog-title">{props.title}</DialogTitle>
      <DialogContent dividers>{props.message}</DialogContent>
      <DialogActions>
        <Button
          data-selector="confirmation-dialog-button-no"
          onClick={handleClose}
          color="primary"
        >
          No
        </Button>
        <Button
          data-selector="confirmation-dialog-button-yes"
          autoFocus
          onClick={okOperationDialog}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
