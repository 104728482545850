import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Hidden,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import textLogo from '../../shared/assets/images/text-logo.png'
import { useAppDispatch, useAppSelector } from '../../shared/store'
import { updatePracticeData } from '../../shared/store/auth/actions'
import _ from '../../shared/utils/lodashExtensions'
import Steps from '../SignUpPage/Steps/Steps'
import { useTermServiceStyles } from './TermServicePage.styles'

const TermServicePage: React.FC = () => {
  const [errors, setErrors] = useState<string | null>(null)
  const [termCheck, setTermCheck] = useState(false)
  const [baaCheck, setBaaCheck] = useState(false)
  const [stepActive] = useState(1)

  const history = useHistory()

  const classes = useTermServiceStyles()

  const auth = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()

  const price = auth.userdata.data.maxUsers / 223343
  const unlimited = true //price>80;
  const discount = price === 50

  useEffect(() => {
    if (auth.isAuthenticated || _.isEmpty(auth.userdata)) {
      history.push('/inbox/cases')
    }
  }, [auth.isAuthenticated, auth.userdata, history])

  const proceedPayment = () => {
    if (!termCheck || !baaCheck) {
      setErrors('Please accept the Terms of service & BAA')
    } else {
      setErrors(null)
      const practiceId = auth.userdata.data.practiceId
      const practiceData = {
        practiceId,
        terms: termCheck,
        baa: baaCheck,
      }

      dispatch(updatePracticeData(practiceData))

      history.push({
        pathname: '/payment-info',
      })
    }
  }

  return (
    <>
      <Hidden smDown>
        <Steps stepActive={stepActive} />
      </Hidden>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div style={{ display: 'flex' }}>
            <img src={textLogo} alt="required" style={{ width: '100%' }} />
          </div>
          <Hidden mdUp>
            <Steps stepActive={stepActive} />
          </Hidden>
          <Typography
            style={{ textAlign: 'center', marginTop: '20px' }}
            variant="h4"
          >
            ${price}/month
          </Typography>
          <Typography
            style={{ textAlign: 'center', marginTop: '0px' }}
          >
            {discount && 'For the first 3 months and $150/month for the remainder of the initial year.'}
          </Typography>
          <Typography style={{ textAlign: 'left', margin: '10px 0 0 0' }}>
            Includes:
          </Typography>
          <ul>
            <li>
              <Typography>
                {unlimited ? 'Unlimited' : '2'} panel specialist consults each month
              </Typography>
            </li>
            <li>
              <Typography>Unlimited local consults</Typography>
            </li>
            <li>
              <Typography>Platform training and support</Typography>
            </li>
            <li>
              <Typography>
                Unlimited specialist recruitment campaigns
              </Typography>
            </li>
          </ul>
          <Typography
            component="h1"
            variant="h5"
            style={{ textAlign: 'center', margin: '20px 0 0 0' }}
          >
            {'Terms of Service and BAA'}
          </Typography>
          <p style={{ textAlign: 'center', margin: '0 0 5px 0' }}>
            In order to join TheaHealth, you must read the Terms of Service and
            the Business Associate Agreement (BAA) and accept them by clicking
            the checkboxes below.
          </p>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Checkbox
                    color="primary"
                    checked={termCheck}
                    onClick={() => {
                      setTermCheck(!termCheck)
                    }}
                  />
                }
                label="I agree to the Terms of Service"
              />
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ height: 250, overflowY: 'scroll' }}>
                <p style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '14.0pt' }}>
                          Thea Health Practice Partner Program{' '}
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '14.0pt' }}>
                          Service Agreement
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'center' }}>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        THIS SERVICE AGREEMENT (the "Agreement") constitutes a
                        binding agreement between the party clicking through to
                        accept this Agreement (“Partner”) and Thea Health Inc.,
                        a Delaware corporation whose principal place of business
                        is: 221 Locust Avenue, San Rafael, California 94901
                        ("Thea"). Partner and Thea are sometimes referred to
                        herein individually as a "Party," or together as the
                        "Parties." This Agreement shall be effective on the date
                        on which PARTNER clicks through to accept this Agreement
                        (the “Effective Date”).
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>BACKGROUND</span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p style={{ textAlign: 'center' }}>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        </span>
                      </strong>
                      <span style={{ fontSize: '9.0pt' }}>
                        Thea offers cloud-based eConsult solutions that deliver
                        communication capabilities to enable primary care
                        physicians to access qualified specialists for review,
                        diagnosis and treatment of certain patients' medical
                        conditions.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        </span>
                      </strong>
                      <span style={{ fontSize: '9.0pt' }}>
                        Partner would like to participate in Thea's Practice
                        Partner Program (the "Partner Program") in order to be
                        able to efficiently access specialists for the treatment
                        of patients. Thea has agreed to authorize Partner to
                        participate in Thea's Partner Program and offer Thea
                        services under the terms and conditions ("Terms") of
                        this Agreement.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>AGREEMENT</span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          DEFINITIONS.
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        "<strong>eConsult</strong>" means the delivery of
                        patient information to a specialist physician for
                        analysis, diagnosis and consultation for a patient's
                        medical condition.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp;"End User"
                        </span>
                      </strong>
                      <span style={{ fontSize: '9.0pt' }}>
                        {' '}
                        means an individual affiliated with the Partner either
                        as an employee or licensed physician who will have
                        access and use of the Thea Platform and Thea Service;
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <strong>"Marks"</strong> means names, trademarks, trade
                        names, service marks and logos.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <strong>"Thea Platform"</strong> means the Thea's
                        proprietary technology and managed infrastructure for
                        the delivery, management, and distribution of eConsults
                        and related consultations with other physicians online
                        and through mobile applications.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <strong>"Thea Service"</strong> means the services
                        offered by Thea using the Thea Platform that is accessed
                        by means of the Thea Software.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <strong>"Thea Software"</strong> means application
                        programming interfaces (APIs), software development kits
                        (SDKs), software source code and object code, tools, all
                        related documentation and all updates, enhancements or
                        extensions to the foregoing released and made generally
                        available to its customers by Thea during the Term.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          GRANT OF RIGHTS AND OWNERSHIP.
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Subscription Grants.</u>&nbsp; Subject to Partner's
                        full compliance with the Terms, Thea hereby grants to
                        Partner for the duration of the Term a worldwide,
                        non-exclusive, non-transferable, non-assignable,
                        non-sublicensable (except as expressly set forth herein)
                        subscription to the Thea Service which permits Partner
                        to (i) access, use and display (but not to publicly
                        display) the Thea Service; (ii) install and use the Thea
                        Service in connection with Partner's third-party billing
                        software and systems.&nbsp; Thea shall give Partner
                        access to the Thea Service as of a date to be determined
                        by Thea in its reasonable discretion, and Partner shall
                        receive e-mail notice no less than ten (10) days prior
                        to the date of availability of the Thea Service for
                        Partner.&nbsp; The Thea Service is provided as 'Software
                        as a Service' and not sold hereunder.&nbsp; Any rights
                        not expressly granted herein are reserved by Thea.&nbsp;
                        Partner understands and acknowledges that Thea may make
                        changes to the Thea Platform and/or the Thea Service,
                        and Partner consents to such changes.&nbsp; The
                        foregoing subscription shall initially be provided to
                        Partner and its End Users on an alpha basis and
                        subsequently as a beta service following notice to
                        Partner.
                      </span>{' '}
                      <span style={{ fontSize: '9.0pt' }}>
                        Thea will integrate the Thea Platform into existing
                        electronic healthcare delivery systems to the extent
                        feasible given third-party limitations.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Usage Restrictions.</u>&nbsp; Partner agrees that it
                        shall not copy, reverse engineer, decompile or
                        disassemble any Thea Software, Thea Tools, the Thea
                        Platform or the Thea Service, except to the limited
                        extent such restrictions are expressly prohibited by
                        applicable law, notwithstanding this provision.&nbsp;
                        Partner agrees not to remove, obscure, or alter any Thea
                        copyright notice, Marks, or other proprietary rights
                        notices affixed to or contained within the Thea Software
                        or Thea Tools. Partner shall not use the Thea Software
                        or Thea Tools in any manner that may adversely impact
                        the operation of the Thea Platform or the Thea Service,
                        and any such use by Partner resulting in an adverse
                        impact upon the Thea Platform or the Thea Service shall
                        be considered a material breach of these Terms.{' '}
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Ownership</u>. Partner acknowledges and agrees that
                        as between Thea and Partner, Thea owns all right, title
                        and interest in and to the Thea Platform, the Thea
                        Service, the Thea Software, the Thea Tools, the Thea
                        Marks, including without limitation all Intellectual
                        Property Rights therein and relating thereto.&nbsp;
                        "Intellectual Property Rights" means, collectively, all
                        patent rights, copyrights, trade secrets and moral
                        rights; trade names, trademarks, service marks, and
                        companion goodwill; domain names; rights of publicity
                        and rights of privacy; and other similar rights
                        throughout the world, together with all registrations
                        and/or applications for each of the foregoing. Thea
                        acknowledges and agrees that as between Thea and
                        Partner, Partner shall retain all right title and
                        interest in and to the Partner services, subject to
                        Thea's underlying rights in and to the Thea Platform,
                        the Thea Service, the Thea Software, the Thea Tools, and
                        the Thea Marks. Partner agrees that it has no right or
                        license to use the Thea Marks unless it follows the Thea
                        branding guidelines.&nbsp; Any comments, suggestions or
                        contributions by Partner or its physicians, employees,
                        agents or contractors shall be solely owned by Thea and
                        may be incorporated into the Thea Platform and Thea
                        Service without any compensation, rights or claim by any
                        such person.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Responsibilities of Partner</u>.&nbsp; Access to the
                        Thea Service is limited to those End Users of Partner
                        who have been issued usernames and passwords by Thea or
                        by Partner's administrator and approved by Thea.&nbsp;
                        Partner may designate an administrator user who can set
                        access permissions for other users, however Partner must
                        ensure that details of its administrator user are kept
                        up to date and that Partner notifies Thea if its
                        administrator user is changed or leaves the
                        company.&nbsp; Partner is responsible for any activity
                        by End Users issued under its account.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        2.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Compliance with Laws</u>.&nbsp; Partner shall comply
                        with all applicable federal, state and local laws,
                        statutes, regulations, including without limitation,
                        HIPAA, data protection laws and regulations, and where
                        necessary has provided all notices, and obtained all
                        rights, permissions, and consents for Thea (and where
                        applicable its subcontractors) to process, access, use
                        and display End User data for the purpose of providing
                        the Thea Service under the Terms, as requested by
                        Partner.&nbsp; The foregoing compliance obligation shall
                        include responsibility for compliance with any medical
                        or health care guidelines or regulations in any aspect
                        of the treatment of patients by physicians and other
                        medical providers.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        2.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Verification</u>.&nbsp; Partner's use of the Thea
                        Service is subject to verification by Thea of each
                        physician End User's identity and credentials as a
                        physician and to his or her ongoing qualification as
                        such. Partner shall cooperate with Thea's verification
                        processes by disclosing to Thea information reasonably
                        required by Thea to verify each physician End User's
                        identity and credentials, which may include by way of
                        example the physician's state of licensure and licensure
                        number, Drug Enforcement Agency or similar prescribing
                        number and National Provider Identifiers ("NPIs").&nbsp;
                        Thea may use and disclose physician End User personal
                        information for verification purposes, including making
                        inquiry of third parties concerning the End User's
                        identity and professional and practice credentials.
                        Partner authorize such third parties to disclose to Thea
                        such information as Thea may request for such purposes,
                        and Partner shall hold them and Thea harmless from any
                        claim or liability arising from the request for or
                        disclosure of such information. In addition to Thea's
                        termination and suspension rights under this Agreement,
                        Thea may terminate a physician End User's access to or
                        use of the Thea Service at any time if Thea is unable at
                        any time to determine or verify the physician End User's
                        qualifications or credentials.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        2.7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Representations, Warranties and Covenants</u>.&nbsp;
                        Partner represents, warrants, and covenants to Thea that
                        upon execution and throughout the term of this
                        Agreement:
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        (a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Partner is not bound by any agreement or arrangement
                        which would preclude Partner from entering into, or from
                        fully performing the services required under, this
                        Agreement.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        (b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Each physician End User's license to practice medicine
                        in the has never been denied, suspended, revoked,
                        terminated, voluntarily relinquished under threat of
                        disciplinary action, or restricted in any way in any
                        juristiction.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        (c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Each physician End User's medical staff privileges at
                        any health care facility have never been denied,
                        suspended, revoked, terminated, voluntarily relinquished
                        under threat of disciplinary action, or made subject to
                        terms of probation or any other restriction.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        (d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        No physician End User has in the past conducted, and is
                        not presently conducting, a medical practice in such a
                        manner as to cause the End User to be suspended,
                        excluded, debarred or sanctioned under the Medicare or
                        Medicaid Programs or any government licensing agency,
                        and has never been convicted of an offense related to
                        health care, or listed by a federal agency as debarred,
                        excluded or otherwise ineligible for federal program
                        participation.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        (e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Each physician End User has, and shall maintain
                        throughout the term of this Agreement, an unrestricted
                        license to practice medicine in the state in which the
                        patient resides or is being treated.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          CUSTOMER SERVICE AND SUPPORT.
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Support of End Users</u>.&nbsp; Partner shall at its
                        sole expense provide End User support.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Thea Support and Service Levels.</u>&nbsp; Thea
                        agrees to provide Partner with the support and service
                        levels for the Thea Service that Thea determines in its
                        sole discretion are necessary and advisable for the use
                        of the Thea Services and Thea Platform.&nbsp; Partner
                        acknowledges and agrees that due to the fact this
                        contemplates a testing of the Thea Platform in alpha and
                        beta stages, Partner's sole remedy for Thea's failure to
                        meet service levels determined by Thea shall be
                        termination of this Agreement for a material breach as
                        set forth in Section 6.2.&nbsp; In no event shall
                        Partner seek to recover, and Partner hereby waives in
                        full, any monetary damages for breach or default by Thea
                        pursuant to this Agreement.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          COMPENSATION.
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Expenses.</u> Each Party, unless otherwise arranged
                        in writing, will be responsible for all of its own costs
                        and expenses, including without limitation, any fees
                        imposed by U.S. or State laws, regulations or rules,
                        incurred by such Party in performing its obligations or
                        exercising its rights under this Agreement.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Taxes and Related Local Regulations.</u>&nbsp; Each
                        party shall be responsible for, collect and pay (and
                        indemnify and hold harmless the other party and its
                        affiliates from) any sales, use, excise, import or
                        export, value-added or similar tax or duty not based on
                        the other party's revenue or net income, including any
                        penalties and interest, as well as any costs associated
                        with the collection or withholding thereof, including
                        attorneys' fees, arising from the operation of, or any
                        transaction or transmission contemplated hereby. If
                        required by the applicable tax authority, Thea shall
                        collect taxes on behalf of Partner with an invoice
                        including a subtotal, applicable tax amount, and grand
                        total.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Fees.</u> Partner shall pay to Thea a subscription
                        fee ("Fee") equal to <strong>${price} per month</strong>,
                        payable in advance, for each user’s participation in
                        Thea’s Practice Partner program. Company reserves the
                        right to change the Fees or applicable charges and to
                        institute new charges and Fees at the end of the Initial
                        Service Term or then current renewal term, upon ten (10)
                        days prior notice to Customer (which may be sent by
                        email). If Customer believes that Company has billed
                        Customer incorrectly, Customer must contact Company no
                        later than 60 days after the closing date on the first
                        billing statement in which the error or problem
                        appeared, in order to receive an adjustment or credit.
                        Inquiries should be directed to Company’s customer
                        support department.{' '}
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        4.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Payment by credit card</u>. If you are paying by
                        credit card, you authorize us to charge your credit card
                        or bank account for all fees payable during the
                        Subscription Term. You further authorize us to use a
                        third party to process payments, and consent to the
                        disclosure of your payment information to such third
                        party.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        4.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Free Trial</u>. Thea may make Thea’s Practice Partner
                        Program available on a trial basis for a discounted rate or
                        free of charge until the earlier of (a) the end of the
                        free trial period (if not terminated earlier) or (b) the
                        start date of your paid subscription. Thea may at its
                        sole discretion add terms or limitations to this trial
                        use of our Partner Program. Unless otherwise specified,
                        trials will convert to paid memberships at $200 per user
                        per month after the free trial period unless cancelled
                        ahead of the end of the free trial period.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          CONFIDENTIALITY
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Confidential Information.</u>&nbsp; During the term
                        of this Agreement, each party hereto may disclose (the
                        "Disclosing Party") to the other party (the "Receiving
                        Party") certain non-public information in connection
                        with the performance of this Agreement, including
                        without limitation technical data, trade secrets, plans
                        for products or services, customer or supplier lists,
                        marketing plans, software, source code for software,
                        financial documents or data, inventions, processes,
                        methods, technology, and designs<strong>, </strong>or
                        other information which by its nature or manner of
                        disclosure reasonably ought to be considered
                        confidential, whether disclosed orally or in writing
                        (including electronic formats), and whether or not
                        marked as confidential, proprietary or with a similar
                        legend (collectively, the "Confidential
                        Information").&nbsp;{' '}
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Treatment of Confidential Information</u>.&nbsp; The
                        Receiving Party shall only use Confidential Information
                        of the Disclosing Party in the performance of its duties
                        hereunder, holding such Confidential Information in
                        strictest confidence and only disclosing such
                        Confidential Information to those employees of the
                        Receiving Party to whom disclosure is necessary.&nbsp;
                        The Receiving Party agrees to protect and care for the
                        Confidential Information of the Disclosing Party as it
                        protects and cares for its own Confidential Information
                        of a similar nature, and in any event using no less than
                        a reasonable degree of care.&nbsp; Confidential
                        Information disclosed hereunder shall remain the
                        property of the Disclosing Party.&nbsp; The Receiving
                        party agrees that it shall not make disclosure of the
                        Confidential Information of the Disclosing Party during
                        the Term hereof and for a period of five (5) years
                        thereafter, without the express written consent of the
                        Disclosing Party.&nbsp; Notwithstanding the foregoing,
                        trade secrets of the Disclosing Party shall remain
                        confidential under this Agreement without any time limit
                        or expiration date.&nbsp;{' '}
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Exclusions</u>.&nbsp; Confidential Information does
                        not include information which (i) is rightfully in the
                        Receiving Party's possession before it is received from
                        the Disclosing Party, (ii) is or becomes a matter of
                        public knowledge or generally known in the industry
                        through no fault of the Receiving Party, (iii) is
                        rightfully furnished to the Receiving Party by a third
                        party without restriction on disclosure or use, or (iv)
                        is independently developed by the Receiving Party
                        without use of or reference to the Disclosing Party's
                        Confidential Information.&nbsp;{' '}
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        5.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Disclosure Pursuant to a Judicial Order</u>.&nbsp; A
                        Receiving Party will not be deemed to be in violation of
                        this Section 5 (Confidentiality) to the extent it is
                        required by judicial order or governmental regulation to
                        disclose Confidential Information, provided it has given
                        the Disclosing party prior notice sufficient to permit
                        it to seek a protective order or other confidential
                        treatment of its Confidential Information, unless the
                        Receiving Party is prohibited by law from doing
                        so.&nbsp; Such Confidential Information shall remain
                        confidential for all other purposes.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        5.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>HIPAA Compliance</u>. The Business Associate
                        Agreement shall govern the HIPAA compliance between the
                        Parties.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          TERM AND TERMINATION
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Term</u>.&nbsp; This Agreement will remain in effect
                        for one month from the Effective Date (the "Initial
                        Term").&nbsp; The Initial Term will automatically renew
                        for successive one month renewal terms until terminated
                        by either Party on at least one (1) days written
                        notice prior to the end of the Initial Term or any
                        renewal term.&nbsp; The Initial Term and any renewal
                        terms are, collectively, the "Term."
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Termination</u>.&nbsp; Notwithstanding the foregoing,
                        either Party may terminate the Agreement upon delivery
                        of written notice: (a) if the other Party is in material
                        default with respect to any provision of this Agreement
                        or materially breaches any provision of this Agreement,
                        and such material default or breach is not cured within
                        ten (10) days after the date of written notice thereof,
                        (b) immediately if the other Party becomes insolvent,
                        files for bankruptcy or similar protection under
                        applicable state or federal law, or otherwise ceases
                        operations, (c) immediately if Partner is in violation
                        of the payment terms detailed in Section 4 respectively.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        6.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Effect of Termination</u>.&nbsp; Both Parties' rights
                        and obligations under this Section 6, as well as Section
                        2, 4, 5, 7, 8, 9, 10, 11, 12, 13 shall survive
                        expiration or termination of this Agreement.&nbsp;&nbsp;
                        Upon termination, either party may pursue any remedies
                        provided by applicable law.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          THEA WARRANTY
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        THEA DOES NOT REPRESENT OR WARRANT THAT THE THEA
                        PLATFORM OR THE THEA SERVICE COMPLIES WITH ANY LEGAL
                        REQUIREMENTS, IS FREE OF INACCURACIES, ERRORS, BUGS, OR
                        INTERRUPTIONS, OR IS RELIABLE, ACCURATE, COMPLETE, OR
                        OTHERWISE VALID. THE SERVICE IS PROVIDED ACCORDING TO
                        ANY WRITTEN DOCUMENTATION PROVIDED BY THEA. PARTNER USE
                        OF THE SERVICE IS AT THE DISCRETION AND RISK OF PARTNER
                        AND PARTNER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
                        THAT RESULTS FROM THEIR USE, INCLUDING, BUT NOT LIMITED
                        TO, ANY DAMAGE TO PARTNER OR PARTNER'S OR ITS USER'S
                        COMPUTER SYSTEMS OR LOSS OF DATA. THEA MAKES NO
                        REPRESENTATIONS OR WARRANTIES AS TO THE SUITABILITY OF
                        THIRD-PARTY SERVICES OR FOR THE ACTS OR OMISSIONS OF
                        PROVIDERS OF SUCH THIRD-PARTY SERVICES.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          8.
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          LIMITATION OF LIABILITY
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        THEA SHALL NOT, UNDER ANY CIRCUMSTANCES, BE LIABLE TO
                        PARTNER, OR ANY THIRD PARTY, WHETHER BASED ON BREACH OF
                        CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING
                        NEGLIGENCE, PRODUCT LIABILITY OR OTHERWISE) FOR ANY LOST
                        PROFITS, LOST OR CORRUPTED DATA, COMPUTER FAILURE OR
                        MALFUNCTION, INTERRUPTION OF BUSINESS, OR OTHER
                        INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR
                        EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH
                        USE OF THE THEA PLATFORM, THE THEA SERVICE, THE THEA
                        SOFTWARE, THE THEA TOOLS OR ANY OTHER PECUNIARY LOSS,
                        WHETHER OR NOT THEA HAS BEEN ADVISED OF THE POSSIBILITY
                        OF SUCH DAMAGES.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        IN NO EVENT SHALL EITHER PARTY'S LIABILITY OBLIGATIONS
                        UNDER THIS AGREEMENT EXCEED THE AMOUNT OF FEES DUE FROM
                        PARTNER TO THEA DURING THE TWELVE (12) MONTHS PRIOR TO
                        THE OCCURRENCE GIVING RISE TO SUCH CLAIM; PROVIDED,
                        HOWEVER, THE FOREGOING LIMITATION SHALL NOT APPLY TO ANY
                        CLAIM FOR INDEMNITY IN SECTION 10 OR A BREACH OF
                        SECTIONS 2.3 OR 5.{' '}
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        SOME JURISDICTIONS DO NOT ALLOW FOR THE OPERATION OF
                        SERVICES WITH THE CHARACTERISTICS OF THE THEA SERVICE,
                        AND/OR MAY IMPOSE REQUIREMENTS AND RELATED FEES WHICH AT
                        THE SOLE DISCRETION OF THEA ARE DEEMED BEYOND THE SCOPE
                        ENVISIONED BY THIS AGREEMENT. THE OPERATION OF THE THEA
                        SERVICE IN SUCH JURISDICTIONS IS EXPRESSLY FORBIDDEN.{' '}
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          FORCE MAJEURE
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        Neither Party shall be liable hereunder by reason of any
                        failure or delay in the performance of its obligations
                        hereunder on account of strikes, shortages, riots,
                        insurrection, fires, flood, storm, explosions,
                        earthquakes, telecommunications outages, war, terrorism,
                        governmental action, or any other cause which is beyond
                        the reasonable control of such Party, excluding any
                        payment or other financial obligations of Partner to
                        Thea.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          10.
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          INDEMNITY
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Partner Indemnity</u>.&nbsp; Partner agrees to
                        indemnify, defend, and hold Thea and its affiliates,
                        officers, directors, agents, service providers,
                        partners, and employees harmless from any claim, demand
                        or allegation made by any third party, and all related
                        losses, damages, liabilities, costs and expenses
                        (including attorneys' fees) (collectively, "Losses"),
                        that it is any way related to: (a) Partner use of the
                        Thea Platform, Thea Service, Thea Software or Thea
                        Tools; (b) use of the Thea Service by the End Users; (c)
                        any act or omission by Partner or any of its physicians,
                        employees, consultants or agents; (d) any personal
                        injury, loss or death of Partner's or its physician's
                        patients; and (e) any breach of this Agreement by
                        Partner or its physicians, employees, consultants or
                        agents.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                        <u>Thea Indemnity</u>.&nbsp; Thea agrees to indemnify,
                        defend, and hold Partner and its affiliates, officers,
                        directors, agents, service providers, partners, and
                        employees harmless from any Losses, that it is any way
                        related to: (a) infringement of any third party's
                        intellectual property rights&nbsp; arising from the Thea
                        Platform, Thea Service, Thea Software or Thea Tools; (b)
                        any negligent act or omission by Thea or any of its
                        employees, consultants or agents; and (c) any breach of
                        this Agreement by Thea or its employees, consultants or
                        agents.
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          11.
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          JURISDICTION AND VENUE
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        Partner agrees that the statutes and laws of the State
                        of California, without regard to any principles of
                        conflicts of law, will apply to all matters relating to
                        the Partner use of the Thea Platform or the Thea
                        Service, including any disputes under this Agreement,
                        and Partner further agrees that any litigation shall be
                        subject to the exclusive jurisdiction of the state or
                        federal courts located in San Francisco in the State of
                        California, USA. Both Partner and Thea irrevocably
                        consent to personal jurisdiction in such courts and
                        agree to appear in such courts in connection with any
                        claim arising hereunder.
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          NOTICES{' '}
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        All notices and other communications given or made
                        pursuant hereto shall be in writing and shall be deemed
                        given as of the dated delivered if delivered in person
                        or mailed by registered or certified mail (postage
                        prepaid, return receipt requested) of the Party at the
                        address set forth herein or sent by e-mail transmission
                        (confirmation received) to support@theahealth.com to for{' '}
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '9.0pt' }}>
                          13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          GENERAL INFORMATION
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '11pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '9.0pt' }}>
                        These Terms govern the use of the Thea Platform, Thea
                        Software, Thea Tools and the Thea Service by Partner and
                        constitute the entire agreement between Partner and Thea
                        regarding the subject matter hereof. These Terms
                        supersede any prior or contemporaneous agreements
                        between Partner and Thea (including, but not limited to,
                        any prior versions of these Terms and the different or
                        additional term of any Partner purchase order). The
                        failure of Thea to exercise or enforce any right or
                        provision of these Terms does not constitute a waiver of
                        that right or provision, and a waiver of any default is
                        not a waiver of any other default. If a court of
                        competent jurisdiction finds any provision of these
                        Terms to be invalid, the provision shall be construed in
                        a manner consistent with applicable law to reflect, as
                        nearly as possible, the parties' original intentions as
                        reflected in the provision, and so that the other
                        provisions of the Terms remain in full force and effect.
                        These Terms may only be modified through a written
                        amendment that is executed by both parties.&nbsp; This
                        Agreement may be executed in counterparts and delivered
                        via e-mail, which collectively shall constitute one
                        instrument.&nbsp; All headings in the Terms are for
                        convenience only and have no legal or contractual
                        effect. The Agreement may not be transferred by Partner
                        without Thea's prior written approval, which it may
                        withhold in its sole discretion. Thea expressly reserves
                        and shall have the right to assign the Agreement and to
                        delegate any of its obligations hereunder.
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Checkbox
                    color="primary"
                    checked={baaCheck}
                    onClick={() => {
                      setBaaCheck(!baaCheck)
                    }}
                  />
                }
                label="I agree to the BAA"
              />
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ height: 250, overflowY: 'scroll' }}>
                <p style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '14.0pt' }}>
                          <span
                            style={{ fontFamily: '"Times New Roman",serif' }}
                          >
                            Business Associate Agreement
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '11.0pt' }}>
                          <span
                            style={{ fontFamily: '"Times New Roman",serif' }}
                          >
                            Definitions
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <u>
                        <span style={{ fontSize: '11.0pt' }}>
                          <span
                            style={{ fontFamily: '"Times New Roman",serif' }}
                          >
                            Catch-all definition:
                          </span>
                        </span>
                      </u>
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                    </span>
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          The following terms used in this Agreement shall have
                          the same meaning as those terms in the HIPAA Rules:
                          Breach, Data Aggregation, Designated Record Set,
                          Disclosure, Health Care Operations, Individual,
                          Minimum Necessary, Notice of Privacy Practices,
                          Required By Law, Secretary, Security Incident,
                          Subcontractor, Unsecured Protected Health Information,
                          and Use.&nbsp;{' '}
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <u>
                        <span style={{ fontSize: '11.0pt' }}>
                          <span
                            style={{ fontFamily: '"Times New Roman",serif' }}
                          >
                            Specific definitions:
                          </span>
                        </span>
                      </u>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (a) <u>Business Associate</u>.&nbsp; “Business
                          Associate” shall generally have the same meaning as
                          the term “Business Associate” at 45 CFR 160.103, and
                          in reference to the party to this agreement, shall
                          mean Thea Health, Inc.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (b) <u>Covered Entity</u>.&nbsp; “Covered Entity”
                          shall generally have the same meaning as the term
                          “Covered Entity” at 45 CFR 160.103, and in reference
                          to the party to this agreement, shall mean the party
                          clicking through to accept this Agreement.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (c) <u>HIPAA Rules</u>.&nbsp; “HIPAA Rules” shall mean
                          the Privacy, Security, Breach Notification, and
                          Enforcement Rules at 45 CFR Part 160 and Part 164.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (d)&nbsp; <u>Protected Health Information</u>.&nbsp;
                          “Protected Health Information” shall have the meaning
                          set forth in 45 CFR 160.103, but limited to such
                          information created or received by Business Associate
                          on behalf of the Covered Entity party to this
                          Agreement.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '11.0pt' }}>
                          <span
                            style={{ fontFamily: '"Times New Roman",serif' }}
                          >
                            Obligations and Activities of Business Associate
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          Business Associate agrees to:
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (a) Not Use or Disclose Protected Health Information
                          other than as permitted or required by the Agreement
                          or as Required By Law;
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (b) Use appropriate safeguards, and comply with
                          Subpart C of 45 CFR Part 164 with respect to
                          electronic Protected Health Information, to prevent
                          Use or Disclosure of Protected Health Information
                          other than as provided for by the Agreement;
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (c) Report to Covered Entity any Use or Disclosure of
                          Protected Health Information not provided for by the
                          Agreement of which it becomes aware, including
                          Breaches of Unsecured Protected Health Information as
                          required at 45 CFR 164.410, and any Security Incident
                          of which it becomes aware;<sup> </sup>provided that,
                          Business Associate hereby gives notice to Covered
                          Entity of the ongoing existence and occurrence of
                          attempted but Unsuccessful Security Incidents (as
                          defined below) for which additional notice to Covered
                          Entity by Business Associate shall not be
                          required.&nbsp; "Unsuccessful Security Incidents"
                          shall include but not be limited to, any attempted
                          access of system operations in an information system
                          by a ping program and other broadcast attacks on
                          Business Associate Party's firewall, port scans,
                          unsuccessful log-on attempts, denials of service and
                          any combination of the above, so long as no such
                          incident results in unauthorized access, use or
                          disclosure of Protected Health Information.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (d) In accordance with 45 CFR 164.502(e)(1)(ii) and
                          164.308(b)(2), if applicable, ensure that any
                          Subcontractors that create, receive, maintain, or
                          transmit Protected Health Information on behalf of the
                          Business Associate agree to the same restrictions,
                          conditions, and requirements that apply to the
                          Business Associate with respect to such information;
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (e) Make available Protected Health Information in a
                          Designated Record Set to the “Covered Entity” as
                          necessary to satisfy Covered Entity’s obligations
                          under 45 CFR 164.524;
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (f) Make any amendment(s) to Protected Health
                          Information in a Designated Record Set as directed or
                          agreed to by the Covered Entity pursuant to 45 CFR
                          164.526, or take other measures as necessary to
                          satisfy Covered Entity’s obligations under 45 CFR
                          164.526;
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (g) Maintain and make available the information
                          required to provide an accounting of Disclosures to
                          the “Covered Entity” as necessary to satisfy Covered
                          Entity’s obligations under 45 CFR 164.528;
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (h)&nbsp; To the extent the Business Associate is to
                          carry out one or more of Covered Entity's
                          obligation(s) under Subpart E of 45 CFR Part 164,
                          comply with the requirements of Subpart E that apply
                          to the Covered Entity in the performance of such
                          obligation(s); and
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (i) Make its internal practices, books, and records
                          available to the Secretary for purposes of determining
                          compliance with the HIPAA Rules.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '11.0pt' }}>
                          <span
                            style={{ fontFamily: '"Times New Roman",serif' }}
                          >
                            &nbsp;Permitted Uses and Disclosures by Business
                            Associate
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (a) Business Associate may only Use or Disclose
                          Protected Health Information as reasonably necessary
                          to perform the services set forth in the Service
                          Agreement.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (b) Business Associate may Use or Disclose Protected
                          Health Information as Required By Law.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (c) Business Associate agrees to make uses and
                          Disclosures and requests for Protected Health
                          Information consistent with Covered Entity’s Minimum
                          Necessary policies and procedures.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (d) Business Associate may not Use or Disclose
                          Protected Health Information in a manner that would
                          violate Subpart E of 45 CFR Part 164 if done by
                          Covered Entity, except for the specific uses and
                          Disclosures set forth below.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (e) Business Associate may Use Protected Health
                          Information for the proper management and
                          administration of the Business Associate or to carry
                          out the legal responsibilities of the Business
                          Associate.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (f) Business Associate may provide Data Aggregation
                          services relating to the Health Care Operations of the
                          Covered Entity as permitted by 45 CFR
                          164.504(e)(2)(i)(B).
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (g) Business Associate may Use Protected Health
                          Information to de-identify Protected Health
                          Information and shall have all rights and title to
                          such de-identified information.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '11.0pt' }}>
                          <span
                            style={{ fontFamily: '"Times New Roman",serif' }}
                          >
                            Term and Termination
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (a) <u>Term</u>. The term of this Agreement shall be
                          effective as of the Effective Date and shall terminate
                          when all of the Protected Health Information is
                          destroyed or returned to CE.{' '}
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (b) <u>Termination for Cause</u>. Upon Business
                          Associate’s material breach of this Agreement, Covered
                          Entity shall provide Business Associate with written
                          notice of the breach, and Business Associate shall
                          cure the breach within thirty (30) business days of
                          receiving the written notice.&nbsp; If Business
                          Associate does not cure the breach within the
                          designated time period, Covered Entity may terminate
                          this Agreement and the underlying service agreement
                          between the parties that necessitated this
                          Agreement.&nbsp; Pursuant to 42 U.S.C. § 17934(b), if
                          Business Associate knows of a pattern of activity or
                          practice of Covered Entity that constitutes a material
                          breach or violation of the Covered Entity’s
                          obligations under the HIPAA Rules, Business Associate
                          must take reasonable steps to cure the breach or end
                          the violation.&nbsp; If the steps are unsuccessful,
                          Business Associate may be required to terminate this
                          Agreement and the underlying service agreement between
                          the parties that necessitated this Agreement, if
                          feasible. &nbsp;&nbsp;
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (c){' '}
                          <u>
                            Obligations of Business Associate Upon Termination
                          </u>
                          . (i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          Except as provided in paragraph (ii) of this section,
                          upon termination of this Agreement for any reason,
                          Business Associate shall, if Business Associate
                          determines it is feasible, return or destroy all
                          Protected Health Information or any copies thereof
                          received from Covered Entity that Business Associate
                          still maintain in any form.&nbsp;&nbsp;{' '}
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (ii)&nbsp; If Business Associate determines that
                          returning or destroying the Protected Health
                          Information is not feasible, Business Associate shall
                          provide to Covered Entity notification of the
                          conditions that make return or destruction
                          unfeasible.&nbsp; Business Associate shall continue to
                          extend the protections of this Agreement to such
                          Protected Health Information and limit further uses
                          and disclosures to those purposes that make the return
                          or destruction of the Protected Health Information
                          unfeasible, for so long as Business Associate
                          maintains such Protected Health Information.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <strong>
                        <span style={{ fontSize: '11.0pt' }}>
                          <span
                            style={{ fontFamily: '"Times New Roman",serif' }}
                          >
                            Miscellaneous{' '}
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (a) <u>Regulatory References</u>. A reference in this
                          Agreement to a section in the HIPAA Rules means the
                          section as in effect or as amended.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (b) <u>Amendment</u>. The Parties agree to take such
                          action as is necessary to amend this Agreement from
                          time to time as is necessary for compliance with the
                          requirements of the HIPAA Rules and any other
                          applicable law.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
                <p style={{ marginLeft: '24px' }}>&nbsp;</p>
                <p style={{ marginLeft: '24px' }}>
                  <span style={{ fontSize: '12pt' }}>
                    <span style={{ fontFamily: 'Calibri,sans-serif' }}>
                      <span style={{ fontSize: '11.0pt' }}>
                        <span style={{ fontFamily: '"Times New Roman",serif' }}>
                          (c) <u>Interpretation</u>. Any ambiguity in this
                          Agreement shall be interpreted to permit compliance
                          with the HIPAA Rules.
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
          <Typography color="error" className={classes.errorText}>
            {errors ?? errors}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={proceedPayment}
          >
            Proceed to payment information
          </Button>
        </div>
      </Container>
    </>
  )
}

export default TermServicePage
