import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'

const { innerWidth: width } = window

const ViewFile: React.FC<{ file: File; fileUrl: string }> = ({
  file,
  fileUrl,
}): React.ReactElement => {
  let fileToRender

  const [numPages, setNumPages] = useState(0)

  const fileTypeRes = file.type.substring(0, file.type.indexOf('/'))
  const fileExtRes = file.type.split('/').pop()

  let pdfWidth = 650
  if (width <= 400) {
    pdfWidth = 375
  }

  if (fileTypeRes === 'image') {
    fileToRender = (
      <>
        <img src={fileUrl} alt={file.type} />
      </>
    )
  } else if (fileTypeRes === 'application' && fileExtRes === 'pdf') {
    // This loads the entire PDF which could be slow for very large files
    fileToRender = (
      <div style={{ overflow: 'scroll' }}>
        <Document
          file={fileUrl}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={pdfWidth}
            />
          ))}
        </Document>
      </div>
    )
  } else if (fileTypeRes === 'application' && fileExtRes !== 'pdf') {
    fileToRender = (
      <h1
        style={{
          width: '70%',
          alignItems: '',
          color: 'grey',
          marginTop: '20%',
        }}
      >
        {fileExtRes} file{' '}
      </h1>
    )
  } else if (fileTypeRes === 'video') {
    fileToRender = (
      <video src={fileUrl} controls style={{ width: '100%', height: '100%' }} />
    )
  } else if (fileTypeRes === 'text') {
    fileToRender = (
      <h1
        style={{
          width: '70%',
          alignItems: '',
          color: 'grey',
          marginTop: '30vh',
        }}
      >
        {fileExtRes} file
      </h1>
    )
  } else {
    fileToRender = (
      <h1
        style={{
          width: '70%',
          alignItems: '',
          color: 'grey',
          marginTop: '30vh',
        }}
      >
        {fileExtRes} file
      </h1>
    )
  }
  return fileToRender
}

export default ViewFile
