import { Button, Grid, TextField, Typography } from '@material-ui/core'
import classnames from 'classnames'
import React, { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../shared/store'
import { newUserInviteEmail } from '../../shared/store/auth/actions'
import { useReferStyles } from './Refer.styles'

const defaultEmailText =
  'Hey friend, \n\nI wanted to invite you to try TheaHealth. \n\nIt will allow me to securely send documents and images about patients who I would like to consult you on. Let me know if you choose to try it and I will send you a patient case! \n\nThank you and Happy eConsulting!'

const ReferAFriend: React.FC = () => {
  const dispatch = useAppDispatch()

  const auth = useAppSelector((state) => state.auth)
  const stateErrors = useAppSelector((state) => state.errors)

  const classes = useReferStyles()
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')

  const [errors, setErrors] = React.useState<any>({
    name: '',
    inviteEmail: '',
    text: '',
  })

  const [emailText, setEmailText] = React.useState(defaultEmailText)

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailText(event.target.value)
  }

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    let errors: any = {}
    if (name === '') {
      errors = {
        name: 'Name field is required.',
      }
    } else if (email === '') {
      errors = {
        inviteEmail: 'Email field is required.',
      }
    } else if (emailText === '') {
      errors = {
        text: 'Email message is required.',
      }
    } else {
      dispatch(
        newUserInviteEmail({
          userID: auth.userdata.id,
          inviteName: name,
          inviteEmail: email,
          emailText,
        })
      )
    }
    setErrors(errors)
  }

  useEffect(() => {
    setErrors(stateErrors)
    if (!auth.loading && !(stateErrors as any).inviteEmail) {
      setName('')
      setEmail('')
    }
  }, [stateErrors, auth.loading])

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="name"
            name="name"
            type="name"
            label="Provider Full Name"
            onChange={handleNameChange}
            className={classnames('', {
              invalid: errors.name,
            })}
            value={name}
          />
          {errors && errors.name && errors.name !== 'Error' && (
            <Typography color="error" className={classes.errorText}>
              {errors.name}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="email"
            name="email"
            type="email"
            label="Email Address"
            onChange={handleEmailChange}
            className={classnames('', {
              invalid: errors.inviteEmail,
            })}
            value={email}
          />
          <Typography color="error" className={classes.errorText}>
            {errors.inviteEmail}
          </Typography>
        </Grid>
      </Grid>

      <TextField
        multiline
        fullWidth
        minRows={5}
        required
        rowsMax="10"
        margin="normal"
        variant="outlined"
        id="text"
        name="text"
        type="text"
        value={emailText}
        label="Email message"
        className={classnames('', {
          invalid: errors.text,
        })}
        onChange={handleTextChange}
      />
      <Typography color="error" className={classes.errorText}>
        {errors.text}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        className={classes.sendBtn}
      >
        <Typography>Send</Typography>
      </Button>
    </div>
  )
}

export default ReferAFriend
