import { makeStyles, Theme } from '@material-ui/core'

export const useAllCasesPageStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('md')]: {
      padding: 20,
    },
  },
  dataGrid: {
    '&.MuiDataGrid-root': {
      height: 'calc(100vh - 118px)',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-overlay': {
      backgroundColor: 'transparent',
    },
  },
  visibilityHidden: {
    visibility: 'hidden',
  },
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    boxShadow: 'none',
  },
  table: {
    tableLayout: 'fixed',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  headerCellBold: {
    color: '#000',
    fontSize: 16,
    fontWeight: 600,
  },
  tableCellBold: {
    color: '#000',
    fontSize: 16,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'calc((100vw / 4 - 120px))',
    maxWidth: '200px',
  },
  tableCellLight: {
    color: '#000',
    fontSize: 16,
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'calc((100vw / 3 - 120px))',
    maxWidth: '200px',
  },
  caseLink: {
    color: '#0000FF',
    textDecoration: 'underline',
  },
  textWrap: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}))
