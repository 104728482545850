import React from 'react'
import { Document, Page } from 'react-pdf'

const ViewFileThumbnail: React.FC<{
  fileData: { name: string; type: string }
  fileUrl: string
}> = ({ fileData, fileUrl }) => {
  let fileToRender

  const fileType = fileData?.type?.substring(0, fileData.type.indexOf('/'))
  const fileExt = fileData?.type?.split('/').pop()

  if (fileType === 'image') {
    fileToRender = (
      <img
        src={fileUrl}
        alt="Upload"
        style={{
          height: '14vh',
          overflow: 'hidden',
          display: 'block',
          width: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
        }}
      />
    )
  } else if (fileType === 'application' && fileExt === 'pdf') {
    fileToRender = (
      <div style={{ height: '100%' }}>
        <Document
          file={fileUrl}
          onLoadError={(e) => {
            console.error(e)
          }}
        >
          <Page pageNumber={1} width={200} />
        </Document>
      </div>
    )
  } else if (fileType === 'application' && fileExt !== 'pdf') {
    fileToRender = (
      <h6
        style={{
          marginLeft: '5%',
          marginRight: '5%',
          marginTop: '30%',
          alignItems: 'center',
          color: 'grey',
          display: 'block',
        }}
      >
        {' '}
        {fileExt} file{' '}
      </h6>
    )
  } else if (fileType === 'video') {
    fileToRender = (
      <video src={fileUrl} style={{ width: '100%', height: '14vh' }} />
    ) //Edit this if required
  } else {
    fileToRender = (
      <h5
        style={{
          marginLeft: '5%',
          marginRight: '5%',
          marginTop: '30%',
          alignItems: 'center',
          color: 'grey',
          display: 'block',
        }}
      >
        {' '}
        {fileExt} file{' '}
      </h5>
    )
  }
  return fileToRender
}

export default ViewFileThumbnail
