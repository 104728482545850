import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'

import classnames from 'classnames'
import React from 'react'
import validator from 'validator'

import { useAppDispatch, useAppSelector } from '../../../shared/store'
import { userReferEmail } from '../../../shared/store/auth/actions'
import { enqueueSnackbar } from '../../../shared/store/snackbar/actions'
import { snackbarMessages } from '../../../shared/utils/constant'
import { encodeString } from '../../../shared/utils/functions'
import { useReferSpecialistStyles } from './ReferSpecialist.styles'

interface IError {
  specialistName: undefined | string
  specialistEmail: undefined | string
}

const ReferPcp: React.FC = () => {
  const dispatch = useAppDispatch()
  const classes = useReferSpecialistStyles()

  const auth = useAppSelector((state) => state.auth)

  const headTitle = auth.userdata.userType === 'PCP' ? 'Invite Specialists' : 'Refer A Specialist'
  const subTitle = auth.userdata.userType === 'PCP' ? 'Invite your local specialists to join Thea Health!' : 'Know anyone who might be interested in Thea Health? Use the form below to get them on the platform!'

  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')

  const [errors, setErrors] = React.useState<IError>({
    specialistName: undefined,
    specialistEmail: undefined,
  })

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const copyInviteLink = () => {
    const params = {
      practiceId: auth.userdata.practiceId,
      isAdmin: 'false',
    }

    const newParams = encodeString(JSON.stringify(params))
    const link = window.location.origin + '/register/identification/hash_' + newParams

    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(link)
    } else {
      document.execCommand('copy', true, link)
    }

    dispatch(
      enqueueSnackbar({
        message: snackbarMessages.INVITE_LINK_COPIES,
        options: {
          variant: 'success',
        },
      })
    )
  }

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    let errorObj: any = {}
    if (name === '') {
      errorObj = {
        specialistName: 'Name field is required.',
      }
    } else if (email === '') {
      errorObj = {
        specialistEmail: 'Email field is required.',
      }
    } else if (!validator.isEmail(email)) {
      errorObj = {
        specialistEmail: 'Email is invalid.',
      }
    } else {
      dispatch(
        userReferEmail({
          userId: auth.userdata.id,
          inviteName: name,
          inviteEmail: email,
        })
      )
      setName('')
      setEmail('')
    }
    setErrors(errorObj)
  }

  return (
    <Card>
      <CardHeader title={headTitle} />
      <Divider />
      <div className={classes.divHeading}>
        <Typography className={classes.subHeading}>
          {subTitle}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="name"
              label="Full Name"
              value={name}
              onChange={handleNameChange}
              className={classnames('', {
                invalid: errors.specialistName,
              })}
            />
            <Typography color="error" className={classes.errorText}>
              {errors.specialistName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="specialistEmail"
              type="email"
              value={email}
              label="Email Address"
              onChange={handleEmailChange}
              className={classnames('', {
                invalid: errors.specialistEmail,
              })}
            />
            <Typography color="error" className={classes.errorText}>
              {errors.specialistEmail}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <CardActions className={classes.cardBottom}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Send Email
        </Button>

        <Button
          variant="contained"
          onClick={copyInviteLink}
        >
          Copy Invite Link
        </Button>
      </CardActions>

    </Card>
  )
}

export default ReferPcp
