import {
  Container,
  CssBaseline,
  Grid,
  Hidden,
  TextField,
  Typography,
} from '@material-ui/core'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import classnames from 'classnames'
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import textLogo from '../../shared/assets/images/text-logo.png'
import { useAppDispatch, useAppSelector } from '../../shared/store'
import {
  getPublicStripeKey,
  getSetupIntent,
  modifyPracticeData,
} from '../../shared/store/auth/actions'
import { enqueueSnackbar } from '../../shared/store/snackbar/actions'
import { snackbarMessages } from '../../shared/utils/constant'
import CardSetupForm from '../CardDetails/CardSetupForm'
import Steps from '../SignUpPage/Steps/Steps'
import { usePaymentInfoPageStyles } from './PaymentInfoPage.styles'

const PaymentInfoPage: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useAppDispatch()
  const classes = usePaymentInfoPageStyles()

  const auth = useAppSelector((state) => state.auth)
  const stateErrors = useAppSelector((state) => state.errors)

  const [name, setName] = React.useState('')
  const [secret, setSecret] = React.useState('')
  const [stripePromise, setStripePromise] = React.useState(null)

  const [errors, setErrors] = React.useState({
    firstName: '',
  })

  const price = auth.userdata.data.maxUsers / 223343;
  const unlimited = true //price > 80;
  const discount = price === 50;

  useEffect(() => {
    const fetchStripeKey = async () => {
      const key: any = await dispatch(getPublicStripeKey())
      const stripePromise: any = await loadStripe(key)
      setStripePromise(stripePromise)

      if (auth.userdata) {
        const setupIntent: any = await dispatch(
          getSetupIntent(auth.userdata.data)
        )
        setSecret(setupIntent.client_secret)
      }
    }
    fetchStripeKey()

    if (auth.isAuthenticated || !auth.userdata) {
      // If logged in and user navigates to Register page, should redirect them
      history.push('/')
    }
  }, [auth.isAuthenticated, auth.userdata, dispatch, history])

  useEffect(() => {
    setErrors(stateErrors as any)
  }, [stateErrors])

  useEffect(() => {
    document.addEventListener('keydown', handleHitEnter, true)
    return () => {
      document.removeEventListener('keydown', handleHitEnter, true)
    }
  }, [])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handler = () => {

    const practiceData = {
      practiceId: auth.practiceData.id,
      stripeId: auth.userdata.data.stripeId,
    }
    dispatch(modifyPracticeData(practiceData))

    dispatch(
      enqueueSnackbar({
        message: snackbarMessages.ACCOUNT_CREATED_SUCCESS,
        options: {
          variant: 'success',
        },
      })
    )
  }

  const handleHitEnter = (e: any) => {
    const ENTER_KEY_CODE = 13
    if (
      e.target.name === 'my-input' &&
      (e.key === 'Enter' || e.keyCode === ENTER_KEY_CODE)
    ) {
      e.stopPropagation()
    }
  }

  const propsToPass = {
    handler,
    name,
    secret,
    history,
  }

  return (
    <div>
      <Hidden smDown>
        <Steps stepActive={2} />
      </Hidden>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div style={{ display: 'flex' }}>
            <img src={textLogo} alt="required" style={{ width: '100%' }} />
          </div>
          <Typography
            style={{ textAlign: 'center', marginTop: '20px' }}
            variant="h4"
          >
            ${price}/month
          </Typography>
          <Typography
            style={{ textAlign: 'center', marginTop: '0px' }}
          >
            {discount && 'For the first 3 months and $150/month for the remainder of the initial year.'}
          </Typography>
          <Typography style={{ textAlign: 'left', margin: '10px 0 0 0' }}>
            Includes:
          </Typography>
          <ul>
            <li>
              <Typography>
                {unlimited ? 'Unlimited' : '2'} panel specialist consults each month
              </Typography>
            </li>
            <li>
              <Typography>Unlimited local consults</Typography>
            </li>
            <li>
              <Typography>Platform training and support</Typography>
            </li>
            <li>
              <Typography>
                Unlimited specialist recruitment campaigns
              </Typography>
            </li>
          </ul>
          <Typography
            variant="h5"
            style={{ textAlign: 'center', margin: '30px 0 0 0' }}
          >
            Enter Payment Information:
          </Typography>
          <Hidden mdUp>
            <Steps stepActive={2} />
          </Hidden>

          <form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="name"
                  name="name"
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label="Name on card"
                  autoFocus
                  onChange={onChange}
                  className={classnames('', {
                    invalid: errors.firstName,
                  })}
                />
                <Typography color="error" className={classes.errorText}>
                  {errors.firstName}
                </Typography>
              </Grid>
            </Grid>
          </form>

          {stripePromise && (
            <Elements stripe={stripePromise}>
              <CardSetupForm {...propsToPass} />
            </Elements>
          )}
        </div>
      </Container>
    </div>
  )
}

export default PaymentInfoPage
