import React from 'react'

import { useAdminLayoutStyles } from './Layout.styles'

interface IAdminLayoutProps {
  children: React.ReactNode
}
const AdminLayout: React.FC<IAdminLayoutProps> = ({ children }) => {
  const classes = useAdminLayoutStyles()

  return (
    <div data-selector="admin-layout" className={classes.root}>
      <main className={classes.content}>{children}</main>
    </div>
  )
}

export default AdminLayout
