export const SEND_CASE = 'SEND_CASE'
export const CURRENT_CASE = 'CURRENT_CASE'
export const SET_CURRENT_CASE_PROCEDURE_CODES =
  'SET_CURRENT_CASE_PROCEDURE_CODES'
export const SET_SEARCHED_DIAGNOSES = 'SET_SEARCHED_DIAGNOSES'
export const FETCH_ALL_CASES = 'FETCH_ALL_CASES'
export const ADD_NEW_CASE = 'ADD_NEW_CASE'
export const UPDATE_CASE = 'UPDATE_CASE'
export const DELETE_CASE = 'DELETE_CASE'
export const GET_NUM_NEW_MESSAGE = 'GET_NUM_NEW_MESSAGE'
export const CASE_LOADER = 'CASE_LOADER'
export const SET_USER_PATIENT_LIST = 'SET_USER_PATIENT_LIST'
export const SET_USER_PATIENT_IS_LOADING = 'SET_USER_PATIENT_IS_LOADING'
export const SPECIALTY_LIST = 'SPECIALTY_LIST'
export const FETCH_ATHENA_DATA = 'FETCH_ATHENA_DATA'
export const SET_ATHENA_LATEST_ENCOUNTER_SUMMARY =
  'SET_ATHENA_LATEST_ENCOUNTER_SUMMARY'
export const CLEAR_ATHENA_DATA_AND_CURRENT_CASE_DATA =
  'CLEAR_ATHENA_DATA_AND_CURRENT_CASE_DATA'
export const GET_ERRORS = 'GET_ERRORS'
export const ACTION_PERFORMED = 'ACTION_PERFORMED'
export const SET_PATIENT_ATHENA_DIAGNOSES = 'SET_PATIENT_ATHENA_DIAGNOSES'
export const GET_ALL_SPECIALTIES_USERS = 'GET_ALL_SPECIALTIES_USERS'
export const SET_CERBO_LATEST_ENCOUNTER_SUMMARY =
  'SET_CERBO_LATEST_ENCOUNTER_SUMMARY'
export const SET_ELATION_LATEST_ENCOUNTER_SUMMARY =
  'SET_ELATION_LATEST_ENCOUNTER_SUMMARY'
