import { makeStyles, Theme } from '@material-ui/core'

export const useReferPcpStyles = makeStyles((theme: Theme) => ({
  divHeading: {
    padding: '20px 20px 0',
  },
  subHeading: {
    marginBottom: 10,
  },
  errorText: {
    fontSize: 12,
    fontWeight: 500,
  },
  cardBottom: {
    marginLeft: '0.5rem',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
}))
