import axios, { AxiosResponse } from 'axios'
import jwt_decode from 'jwt-decode'

import { sendAmplitudeData } from '../../utils/amplitude'
import { amplitudeEvents, snackbarMessages } from '../../utils/constant'
import { getServerUrl } from '../../utils/functions'
import setAuthToken from '../../utils/setAuthToken'
import { enqueueSnackbar } from '../snackbar/actions'
import { AppDispatch } from '../store'
import {
  CLIENT_DATA,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_REQUEST,
  GET_ERRORS,
  NEW_ACCOUNT,
  NEW_ACCOUNT_REQUEST,
  PRACTICE_DATA,
  PRACTICE_DATA_REQUEST,
  PRACTICE_MEMBER_DATA,
  SET_CURRENT_USER,
  SET_HINT_DATA,
  SET_SPECIALTY_FULL_LIST,
  STRIPE_DATA,
  STRIPE_DATA_REQUEST,
  TOTAL_PRACTICE_MEMBER,
  USER_DATA,
  USER_LOADING,
  USER_LOGOUT,
} from './types'

// Register User
export const registerUser =
  (
    userData: {
      practiceId?: number
      maxUser?: number
      isAdmin: any
      firstName?: string
      lastName?: string
      role?: string
      specialty?: string
      practice?: string
      email?: string
      password?: string
      password2?: string
      phoneNumber?: string
    },
    history: any
  ) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(getServerUrl() + '/api/v1/auth/register', userData)
      .then((res) => {
        const { status } = res.data
        //const admin = true;
        if (status === '1') {
          if (userData.isAdmin === true) {
            dispatch({
              type: USER_DATA,
              payload: res.data,
            })
            history.push('/term-service')
          } else {
            dispatch(
              enqueueSnackbar({
                message: snackbarMessages.ACCOUNT_CREATED_SUCCESS,
                options: {
                  variant: 'success',
                },
              })
            )
            history.push('/login')
          }
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.errors,
          })
        }
      })
      .catch((_err) =>
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      )
  }

// Login - get user token
export const loginUser =
  (userData: { email: string }) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(getServerUrl() + '/api/v1/auth/login', userData)
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          // Save to localStorage
          // Set token to localStorage
          const { token } = res.data
          localStorage.setItem('jwtToken', token)
          const { data, practiceData } = res.data.data
          dispatch({
            type: USER_DATA,
            payload: data,
          })
          dispatch({
            type: PRACTICE_DATA,
            payload: practiceData,
          })

          // Set token to Auth header
          setAuthToken(token)
          // Decode token to get user data
          const decoded = jwt_decode(token)
          // Set current user
          dispatch(setCurrentUser(decoded))
          sendAmplitudeData(data.email, amplitudeEvents.LOGIN_SUCCESS, data)
        } else {
          sendAmplitudeData(
            userData.email,
            amplitudeEvents.LOGIN_FAILED,
            res.data.errors
          )
          dispatch({
            type: GET_ERRORS,
            payload: res.data.errors,
          })
        }
      })
      .catch((errors) => {
        sendAmplitudeData(userData.email, amplitudeEvents.LOGIN_FAILED, errors)
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
  }

// Get User data by id from database
export const getSpecialtyFullList =
  () =>
  (dispatch: AppDispatch): void => {
    axios
      .get(getServerUrl() + '/api/v1/speciality/specialtyFullList')
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: SET_SPECIALTY_FULL_LIST,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      )
  }
// Get User data by id from database
export const fetchUserData =
  (userID: string) =>
  (dispatch: AppDispatch): void => {
    const request = {
      userID,
    }
    dispatch({
      type: CLIENT_DATA,
      payload: {},
    })
    axios
      .post(getServerUrl() + '/api/v1/auth/fetchUserDataById', request)
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: CLIENT_DATA,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      )
  }

// Get stripe info
// we could break this into two calls and handle async on front end but I think this is easier for now
export const fetchStripeData =
  (practiceId: string) =>
  (dispatch: AppDispatch): void => {
    //dispatch(fetchPracticeData(practiceId));
    const request = {
      practiceId,
    }
    dispatch({
      type: STRIPE_DATA_REQUEST,
      payload: true,
    })
    axios
      .post(getServerUrl() + '/api/v1/auth/fetchStripeDataById', request)
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: STRIPE_DATA,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: STRIPE_DATA_REQUEST,
            payload: false,
          })
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: STRIPE_DATA_REQUEST,
          payload: false,
        })
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      })
  }

export const fetchPracticeData =
  (practiceId: number) =>
  (dispatch: AppDispatch): void => {
    const request = {
      practiceId,
    }
    dispatch({
      type: PRACTICE_DATA_REQUEST,
      payload: true,
    })
    axios
      .post(getServerUrl() + '/api/v1/auth/fetchPracticeDataById', request)
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: PRACTICE_DATA,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
          dispatch({
            type: PRACTICE_DATA_REQUEST,
            payload: false,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: PRACTICE_DATA_REQUEST,
          payload: false,
        })
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      })
  }

// Modify a specific user data
export const modifyUserData =
  (userData: {
    practiceId?: number
    maxUser?: number
    isAdmin: any
    firstName?: string
    lastName?: string
    role?: string
    specialty?: string
    practice?: string
    email?: string
    password?: string
    password2?: string
    phoneNumber?: string
  }) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(getServerUrl() + '/api/v1/auth/modifyUserDataById', userData)
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: USER_DATA,
            payload: res.data.data,
          })
          dispatch({
            type: GET_ERRORS,
            payload: {},
          })
          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.USER_DATA_SAVED_SUCCESS,
              options: {
                variant: 'success',
              },
            })
          )
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.errors,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      })
  }

// Modify a specific practice's data
export const modifyPracticeData =
  (practiceData: { practiceId: any; stripeId: any }) =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: PRACTICE_DATA_REQUEST,
      payload: true,
    })
    axios
      .post(
        getServerUrl() + '/api/v1/auth/modifyPracticeDataById',
        practiceData
      )
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: PRACTICE_DATA,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: PRACTICE_DATA_REQUEST,
            payload: false,
          })
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: PRACTICE_DATA_REQUEST,
          payload: false,
        })
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      })
  }

// export const confirmPassword = userData => dispatch => {
export const confirmPassword =
  (userData: {
    practiceId?: number
    maxUser?: number
    isAdmin: any
    firstName?: string
    lastName?: string
    role?: string
    specialty?: string
    practice?: string
    email?: string
    password?: string
    password2?: string
    phoneNumber?: string
  }) =>
  async (dispatch: AppDispatch): Promise<void> => {
    // WARNING!!!!!!!!!!!: changed the data here
    try {
      const res = await axios.post(
        getServerUrl() + '/api/v1/auth/confirmPassword',
        userData
      )
      const { status } = res.data
      dispatch(checkAuthorization(res))
      if (status === '1') {
        return status
      } else {
        // return false
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: 'err.response.data',
      })
    }
  }
// Set logged in user
export const setCurrentUser =
  (decoded: unknown) =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: SET_CURRENT_USER,
      payload: decoded,
    })
  }
// User loading
export const setUserLoading =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: USER_LOADING,
    })
  }
// Log user out
export const logoutUser =
  () =>
  (dispatch: AppDispatch): void => {
    // Remove token from local storage
    dispatch({
      type: USER_LOGOUT,
    })
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('state')

    // Remove auth header for future requests
    setAuthToken(false)
    // Set current user to empty object {} which will set isAuthenticated to false
    // dispatch(setCurrentUser({}));
  }

//Forgot Password
export const forgotPassword =
  (userData: { email: string }) =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
      payload: true,
    })
    axios
      .post(getServerUrl() + '/api/v1/auth/forgotPassword', userData)
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: FORGOT_PASSWORD,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.errors,
          })
          dispatch({
            type: FORGOT_PASSWORD_REQUEST,
            payload: false,
          })
        }
        sendAmplitudeData(
          userData.email,
          amplitudeEvents.FORGOT_PASSWORD,
          res.data
        )
      })
      .catch((err) => {
        dispatch({
          type: FORGOT_PASSWORD_REQUEST,
          payload: false,
        })
        dispatch({
          type: GET_ERRORS,
          payload: err,
        })
      })
  }

//Email New User
export const newAccountEmail =
  (newUserData: { userID: string; inviteName: string; inviteEmail: string }) =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: NEW_ACCOUNT_REQUEST,
      payload: true,
    })
    axios
      .post(getServerUrl() + '/api/v1/auth/newAccountEmail', newUserData)
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: NEW_ACCOUNT,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.errors,
          })
          dispatch({
            type: NEW_ACCOUNT_REQUEST,
            payload: false,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: NEW_ACCOUNT_REQUEST,
          payload: false,
        })
        dispatch({
          type: GET_ERRORS,
          payload: err,
        })
      })
  }

//Invite New User
export const newUserInviteEmail =
  (newUserData: {
    userID: string
    inviteName: string
    inviteEmail: string
    emailText: string
  }) =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: NEW_ACCOUNT_REQUEST,
      payload: true,
    })
    axios
      .post(getServerUrl() + '/api/v1/auth/newUserInviteEmail', newUserData)
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: NEW_ACCOUNT,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.errors,
          })
          dispatch({
            type: NEW_ACCOUNT_REQUEST,
            payload: false,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: NEW_ACCOUNT_REQUEST,
          payload: false,
        })
        dispatch({
          type: GET_ERRORS,
          payload: err,
        })
      })
  }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSetupIntent =
  (data: any) =>
  (dispatch: AppDispatch): unknown => {
    return axios
      .post(getServerUrl() + `/api/v1/auth/createSetupIntent`, data)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      })
  }

export const getPublicStripeKey =
  () =>
  (dispatch: AppDispatch): unknown => {
    return axios
      .get(getServerUrl() + `/api/v1/auth/public-key`)
      .then((res) => {
        return res.data.publicKey
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      })
  }

export const checkAuthorization =
  (data: AxiosResponse<any>) =>
  (dispatch: AppDispatch): any => {
    const { status } = data.data
    if (status === 402) {
      dispatch(logoutUser())
      dispatch(
        enqueueSnackbar({
          message: snackbarMessages.SESION_EXPIRED,
          options: {
            variant: 'error',
          },
        })
      )
    }
  }

interface IFetchPracticeMemberData {
  practiceId: string
}

export const fetchPracticeMemberData =
  (request: IFetchPracticeMemberData) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(
        getServerUrl() + '/api/v1/auth/fetchPracticeMemberDataById',
        request
      )
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: PRACTICE_MEMBER_DATA,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.errors,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      })
  }

interface IDeleteUserFromPractice {
  userId: string
  practiceId: string
}

export const deleteUserFromPractice =
  (user: IDeleteUserFromPractice) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(getServerUrl() + '/api/v1/auth/deleteUserFromPractice', user)
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.MEMBER_DELETED_SUCCESS,
              options: {
                variant: 'success',
              },
            })
          )
          dispatch(
            fetchPracticeMemberData({
              practiceId: user.practiceId,
            })
          )
          dispatch(
            getTotalPracticeUsers({
              practiceId: user.practiceId,
            })
          )
          return res.data
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.errors,
          })
        }
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      )
  }

interface IGetTotalPracticeUsers {
  practiceId: string
}

export const getTotalPracticeUsers =
  (data: IGetTotalPracticeUsers) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(getServerUrl() + `/api/v1/auth/getTotalPracticeUsers`, data)
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: TOTAL_PRACTICE_MEMBER,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.errors,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      })
  }

interface IAddMemberToPractice {
  firstName: string
  lastName: string
  email: string
  practiceId: string
  maxUsers: number
}

export const addMemberToPractice =
  (userData: IAddMemberToPractice) =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: GET_ERRORS,
      payload: {},
    })
    axios
      .post(getServerUrl() + '/api/v1/auth/addMemberToPractice', userData)
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.MEMBER_ADDED_SUCCESS,
              options: {
                variant: 'success',
              },
            })
          )
          dispatch(
            fetchPracticeMemberData({
              practiceId: userData.practiceId,
            })
          )
          dispatch(
            getTotalPracticeUsers({
              practiceId: userData.practiceId,
            })
          )
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.errors,
          })
        }
      })
      .catch((err) =>
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      )
  }

interface IUpdatePracticeData {
  practiceId: string
  terms: boolean
  baa: boolean
  successMessage?: boolean
}

export const updatePracticeData =
  (practiceData: IUpdatePracticeData) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(
        getServerUrl() + '/api/v1/auth/updatePracticeDataById',
        practiceData
      )
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: PRACTICE_DATA,
            payload: res.data.data,
          })
          if (practiceData.successMessage) {
            dispatch(
              enqueueSnackbar({
                message: snackbarMessages.USER_DATA_SAVED_SUCCESS,
                options: {
                  variant: 'success',
                },
              })
            )
          }
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      })
  }

interface IUserReferEmail {
  userId: string
  inviteName: string
  inviteEmail: string
}

// Refer Invite Email
export const userReferEmail =
  (inviteData: IUserReferEmail) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(getServerUrl() + '/api/v1/auth/userReferEmail', inviteData)
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.INVITATION_SENT,
              options: {
                variant: 'success',
              },
            })
          )
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.errors,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        })
      })
  }

interface IIntegrateToHint {
  hintAuthCode: string
  practiceId: string
}
// Integrate to Hint via InstantOn
export const integrateWithHint =
  (data: IIntegrateToHint, history: any) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(getServerUrl() + '/api/v1/hint/integrate', data)
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: SET_HINT_DATA,
            payload: {
              isHintIntegrated: true,
              isShownHintIntegratedModal: true,
            },
          })
        } else {
          throw new Error(res.data.message)
        }
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: err.message,
            options: {
              variant: 'error',
            },
          })
        )
        dispatch({
          type: GET_ERRORS,
          payload: err,
        })
        history.push('/login')
      })
  }
