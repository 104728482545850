import {
  Card,
  Collapse,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  Zoom,
} from '@material-ui/core'
import ArchiveIcon from '@material-ui/icons/Archive'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import Delete from '@material-ui/icons/Delete'
import { Alert } from '@material-ui/lab'
import { DataGrid, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import deepEqual from 'fast-deep-equal/es6/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import ConfirmationDialog from '../../shared/components/ConfirmationDialog'
import DataGridTableHeader from '../../shared/components/DataGridTableHeader'
import useDataGridColumns, {
  HideableDataGridColumns,
} from '../../shared/hooks/useDataGridColumns'
import { useAppDispatch, useAppSelector } from '../../shared/store'
import {
  closeCases,
  fetchAllCases,
  modifyCaseOnTableRowClick,
  reOpenCases,
} from '../../shared/store/case/actions'
import {
  fetchAllDrafts,
  removeDraft,
  setCurrentDraft,
} from '../../shared/store/draft/actions'
import { decodeString } from '../../shared/utils/functions'
import _ from '../../shared/utils/lodashExtensions'
import { removeFileFromS3 } from '../../shared/utils/s3'
import { useAllCasesPageStyles } from './AllCasesPage.styles'

const AllCasesPage: React.FC = () => {
  const [searchResultsForCases, setSearchResultsForCases] = useState([])
  const [searchResultsForDrafts, setSearchResultsForDrafts] = useState([])
  const [isConfirmDeleteDraftOpened, setIsConfirmDeleteDraftOpened] =
    useState(false)
  const [filesDraft, setFilesDraft] = useState([])
  const [infoAlert, setInfoAlert] = useState(true)
  const [draftId, setDraftId] = useState('')
  const [isConfirmBulkCasesReOpenOpened, setIsConfirmBulkCasesReOpenOpened] =
    useState(false)

  const classes = useAllCasesPageStyles()

  const userdata = useAppSelector((state) => state.auth.userdata)
  const readAllCases = useAppSelector(
    (state) => state.case.readAllCases,
    deepEqual
  )
  const readAllDrafts = useAppSelector(
    (state) => state.draft.readAllDrafts,
    deepEqual
  )
  const [selected, setSelected] = useState<string[]>([])

  const caseLoader = useAppSelector((state) => state.case.caseLoader)
  const draftLoader = useAppSelector((state) => state.draft.draftLoader)
  const loading = caseLoader || draftLoader

  const dispatch = useAppDispatch()

  const history = useHistory()

  useEffect(() => {
    const currentUserData = {
      currentUserId: userdata.id,
      currentUserRole: userdata.userType,
      currentUserPracticeId: userdata.practiceId,
      isDeleted: '',
      isCompleted: false,
    }
    dispatch(fetchAllCases(currentUserData as any))
  }, [dispatch, userdata.id, userdata?.practiceId, userdata?.userType])

  useEffect(() => {
    setSearchResultsForCases(readAllCases as any)
  }, [readAllCases])

  useEffect(() => {
    const currentUserData = {
      currentUserId: userdata.id,
      currentUserRole: userdata.userType,
      currentUserPracticeId: userdata.practiceId,
      isDeleted: '',
      isCompleted: false,
    }
    if (userdata?.userType === 'PCP') {
      dispatch(fetchAllDrafts(currentUserData as any))
    }
  }, [dispatch, userdata.id, userdata.practiceId, userdata.userType])

  useEffect(() => {
    setSearchResultsForDrafts(readAllDrafts as any)
  }, [readAllDrafts])

  const handleTableRowClick = (id: string, isDraft: boolean, caseData: any) => {
    if (isDraft) {
      dispatch(setCurrentDraft({ caseId: id }))
      history.push({
        pathname: `/edit-new-case-${id}`,
      })
    } else {
      const newCaseData: any = {
        caseId: id,
        lastUpdatedById: userdata.id,
        currentUserRole: userdata.userType,
      }
      if (userdata.userType !== 'Admin') {
        // String comparison with Numer is not working with triple equals
        if (userdata.id == caseData.createdById) {
          newCaseData['isRead'] = true
          if (!caseData.isRead) {
            dispatch(modifyCaseOnTableRowClick(newCaseData))
          }
        } else {
          const readByOthers = caseData.isReadByOther
            ? caseData.isReadByOther
            : []
          const ifIndex = _.indexOf(readByOthers, userdata.id)
          if (ifIndex < 0) {
            readByOthers.push(userdata.id)
            newCaseData['isReadByOther'] = readByOthers
            dispatch(modifyCaseOnTableRowClick(newCaseData))
          }
        }
      }
      history.push({
        pathname: `/insidemessage/room_${id}`,
        state: { from: 'cases' },
      })
    }
  }

  const onHandleSearch = (searchInput: string) => {
    if (searchInput !== '') {
      let searchResultsForCases: any = [...readAllCases]
      let searchResultsForDrafts: any = [...readAllDrafts]

      if (searchInput !== 'All Specialties') {
        searchResultsForCases = _.filter(readAllCases, function (item: any) {
          return (
            item.caseData.specialty
              .toLowerCase()
              .indexOf(searchInput.toLowerCase()) > -1 ||
            item.caseData.patientName
              .toLowerCase()
              .indexOf(searchInput.toLowerCase()) > -1
          )
        })
        searchResultsForDrafts = _.filter(readAllDrafts, function (item: any) {
          return (
            item.draftData.specialty
              .toLowerCase()
              .indexOf(searchInput.toLowerCase()) > -1 ||
            item.draftData.patientName
              .toLowerCase()
              .indexOf(searchInput.toLowerCase()) > -1
          )
        })
      }

      setSearchResultsForCases(searchResultsForCases)
      setSearchResultsForDrafts(searchResultsForDrafts)
    } else {
      setSearchResultsForCases(readAllCases as any)
      setSearchResultsForDrafts(readAllDrafts as any)
    }
  }

  const handleRemoveDraft = () => {
    setIsConfirmDeleteDraftOpened(false)
    if (filesDraft && filesDraft.length) {
      filesDraft.map(async (fileData: any, index: any) => {
        if (fileData && fileData.name) {
          await removeFileFromS3(fileData, index)
        }
      })
    }
    dispatch(removeDraft({ caseId: draftId }))
  }

  const handleCloseAllConfirmDialogs = () => {
    setIsConfirmDeleteDraftOpened(false)
    setIsConfirmBulkCasesCloseOpened(false)
    setIsConfirmBulkCasesReOpenOpened(false)
  }

  const openDraftDialog = useCallback((draftData: any) => {
    setIsConfirmDeleteDraftOpened(true)
    setDraftId(draftData.caseId)
    setFilesDraft(
      draftData.files && draftData.files.length ? draftData.files : []
    )
  }, [])

  const handleAssignConsult = useCallback(
    (id: string, specialty: string) => {
      if (id) {
        history.push({
          pathname: `/assign-consult/case_${id}/specialty_${specialty}`,
        })
      }
    },
    [history]
  )

  const handleCloseSelectedCases = () => {
    setIsConfirmBulkCasesCloseOpened(false)
    // const current_time = new Date()
    // const current_timestamp = current_time.getTime()

    const dataToClose: {
      caseId: string
      specialistId: string
    }[] = rows
      .filter(
        (item) =>
          selected.includes(item.id) && item.specialty !== '0AlwaysFirstValue'
      )
      .map((item) => ({
        caseId: item.fullData.caseData.caseId,
        specialistId: item.fullData.caseData.latestAssignedSpecialistId,
      }))

    const caseData = {
      caseIds: dataToClose,
      lastUpdatedById: userdata.id,
      isRead: true,
      isDeleted: true,
    }
    dispatch(closeCases(caseData))
  }

  const handleReOpenSelectedCases = () => {
    setIsConfirmBulkCasesReOpenOpened(false)

    const dataToReOpen: {
      caseId: string
      specialistId: string
    }[] = rows
      .filter(
        (item) =>
          selected.includes(item.id) && item.specialty !== '0AlwaysFirstValue'
      )
      .map((item) => ({
        caseId: item.fullData.caseData.caseId,
        specialistId: item.fullData.caseData.latestAssignedSpecialistId,
      }))

    const caseData = {
      caseIds: dataToReOpen,
      lastUpdatedById: userdata.id,
      isRead: true,
      isDeleted: false,
    }
    dispatch(reOpenCases(caseData as any))
  }

  const renderActions = useCallback(
    (params: GridRenderCellParams) => {
      return (
        <>
          {params.row.fullData.draftData && (
            <Tooltip TransitionComponent={Zoom} title="Delete Draft">
              <Delete
                onClick={(_event) =>
                  openDraftDialog(params.row.fullData.draftData)
                }
                style={{ cursor: 'pointer', margin: 8 }}
              />
            </Tooltip>
          )}
          {userdata.userType === 'Admin' &&
          (!params.row.fullData.caseData.latestAssignedSpecialistId ||
            params.row.fullData.caseData.latestAssignedSpecialistId === '0') ? (
            <Tooltip TransitionComponent={Zoom} title="Assign Specialist">
              <AssignmentIndOutlinedIcon
                color="action"
                data-selector="assign-specialist"
                onClick={() =>
                  handleAssignConsult(
                    params.row.fullData.caseData.caseId,
                    params.row.fullData.caseData.specialty
                  )
                }
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          ) : userdata.userType === 'Admin' &&
            (params.row.fullData.caseData.specialistId ||
              params.row.fullData.caseData.specialistId !== '0') ? (
            <Tooltip TransitionComponent={Zoom} title="Deselect Specialist">
              <AssignmentIndIcon
                color="primary"
                data-selector="unassign-specialist"
                onClick={() =>
                  handleAssignConsult(
                    params.row.fullData.caseData.caseId,
                    params.row.fullData.caseData.specialty
                  )
                }
                style={{ cursor: 'pointer', color: '#13c1cf' }}
              />
            </Tooltip>
          ) : null}
        </>
      )
    },
    [handleAssignConsult, openDraftDialog, userdata.userType]
  )

  const isWideScreen = useMediaQuery('(min-width: 960px)')
  const { columns } = useDataGridColumns(
    isWideScreen
      ? [HideableDataGridColumns.claimStatus]
      : [
          HideableDataGridColumns.question,
          HideableDataGridColumns.updatedAt,
          HideableDataGridColumns.claimStatus,
        ],
    renderActions,
    userdata
  )

  const caseDataRows = useMemo(
    () =>
      searchResultsForCases
        ?.map((item: any) => {
          let updatedAt = item.caseData.updatedAt
            ? item.caseData.updatedAt
            : item.caseData.updatedAtOld
          if (!item.caseData.updatedAt) {
            updatedAt = new Date(
              Number(item.caseData.updatedAtOld)
            ).toISOString()
          }
          return {
            id: item.caseData.id,
            fullData: item, // used only to track full object for selected item
            specialty: item.caseData.specialty
              ?.map((item) => item.title)
              .join(', '),
            patientName: item.caseData.patientName,
            question: decodeString(item.caseData.questions),
            updatedAt,
            specialistId: item.caseData.latestAssignedSpecialistId,
          }
        })
        .sort(
          (x, y) =>
            new Date(y.updatedAt).getTime() - new Date(x.updatedAt).getTime()
        ) || [],
    [searchResultsForCases]
  )

  if (userdata.userType !== 'Admin') {
    caseDataRows.sort(
      (x, y) =>
        Number(x.fullData.caseData.isRead) - Number(y.fullData.caseData.isRead)
    )
  }

  const rows = useMemo(
    () => [
      ...(searchResultsForDrafts
        ?.map((item: any) => {
          let updatedAt = item.draftData.updatedAt
            ? item.draftData.updatedAt
            : item.draftData.updatedAtOld
          if (!item.draftData.updatedAt) {
            updatedAt = new Date(
              Number(item.draftData.updatedAtOld)
            ).toISOString()
          }
          return {
            id: item.draftData.id,
            fullData: item, // used only to track full object for selected item
            specialty: '0AlwaysFirstValue', // it is named as '0AlwaysFirstValue' to ensure Draft is always in first rows
            patientName: item.draftData.patientName,
            question: decodeString(item.draftData.questions),
            updatedAt,
            specialistId: item.draftData.latestAssignedSpecialistId,
          }
        })
        .sort(
          (x, y) =>
            new Date(y.updatedAt).getTime() - new Date(x.updatedAt).getTime()
        ) || []),
      ...caseDataRows,
    ],
    [searchResultsForCases, searchResultsForDrafts]
  )

  const [isConfirmBulkCasesCloseOpened, setIsConfirmBulkCasesCloseOpened] =
    useState(false)

  return (
    <div className={classes.container}>
      {(!userdata.phoneNumber ||
        userdata.phoneNumber === '' ||
        userdata.phoneNumber === null) && (
        <Grid item xs={12}>
          <Collapse in={infoAlert}>
            <Alert
              severity="info"
              onClose={() => {
                setInfoAlert(false)
              }}
            >
              <Typography style={{ fontSize: 'inherit' }}>
                Add your phone number to recieve text notifications —{' '}
                <Link
                  to="/account"
                  style={{ color: 'inherit', fontWeight: 'bold' }}
                >
                  click here
                </Link>{' '}
                to update!
              </Typography>
            </Alert>
          </Collapse>
        </Grid>
      )}

      <Grid container justifyContent="flex-start" spacing={0}>
        <Grid item xs={12}>
          <Card
            style={{
              backgroundColor: '#ffffff',
              padding: 20,
              height: '100%',
            }}
          >
            <DataGridTableHeader
              title="All Cases"
              onHandleSearch={onHandleSearch}
            />

            <div className={classes.root} style={{ width: '100%' }}>
              <Paper
                className={classes.paper}
                style={{ height: '100%', width: '100%' }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  style={{
                    height: '50px',
                    visibility:
                      rows.length && userdata.userType === 'PCP'
                        ? 'visible'
                        : 'hidden',
                  }}
                >
                  {Boolean(
                    !draftLoader &&
                      !caseLoader &&
                      rows.length > 0 &&
                      userdata.userType === 'PCP' &&
                      selected.length
                  ) && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <label
                        style={{
                          marginRight: 15,
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: '15px',
                          cursor: 'pointer',
                        }}
                        onClick={() => setIsConfirmBulkCasesCloseOpened(true)}
                      >
                        <span style={{ float: 'left' }}>
                          <ArchiveIcon style={{ margin: '8px 8px 8px 0' }} />
                        </span>
                        <span
                          data-selector="close-cases-toolbar"
                          style={{
                            float: 'left',
                            marginTop: 10,
                            fontSize: '1rem',
                          }}
                        >
                          Close Cases
                        </span>
                      </label>
                    </Grid>
                  )}
                </Grid>
                <DataGrid
                  className={classes.dataGrid}
                  rows={!loading ? rows : []}
                  columns={columns}
                  loading={loading}
                  autoPageSize
                  sortingOrder={['asc', 'desc']}
                  onRowClick={(data) => {
                    handleTableRowClick(
                      data.row.fullData.draftData
                        ? data.row.fullData.draftData.caseId
                        : data.row.fullData.caseData.caseId,
                      data.row.fullData.draftData ? true : false,
                      data.row.fullData.caseData && data.row.fullData.caseData
                    )
                  }}
                  onCellClick={(params, event) => {
                    if (params.colDef.headerName === 'Actions') {
                      event.stopPropagation()
                    }
                  }}
                  isRowSelectable={(params: GridRowParams) =>
                    params.row.specialty !== '0AlwaysFirstValue'
                  }
                  onSelectionModelChange={(newSelection, details) => {
                    setSelected(newSelection as string[])
                  }}
                  checkboxSelection
                  disableSelectionOnClick
                  disableColumnSelector
                />
              </Paper>
            </div>
          </Card>
        </Grid>
      </Grid>
      <ConfirmationDialog
        message="Are you sure you want to delete this draft?"
        title="Delete Draft"
        okOperationDialog={handleRemoveDraft}
        handleClose={handleCloseAllConfirmDialogs}
        dialogState={isConfirmDeleteDraftOpened}
      />

      <ConfirmationDialog
        message="Are you sure you want to close selected cases?"
        title="Close Cases"
        okOperationDialog={handleCloseSelectedCases}
        handleClose={handleCloseAllConfirmDialogs}
        dialogState={isConfirmBulkCasesCloseOpened}
      />

      <ConfirmationDialog
        message="Are you sure you want to reopen selected cases?"
        title="Reopen Cases"
        okOperationDialog={handleReOpenSelectedCases}
        handleClose={handleCloseAllConfirmDialogs}
        dialogState={isConfirmBulkCasesReOpenOpened}
      />
    </div>
  )
}

export default AllCasesPage
