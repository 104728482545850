import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'

import { AppDispatch } from '../'
import { CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from './types'

interface IEnqueueSnackbar {
  key?: SnackbarKey
  message: SnackbarMessage
  options: OptionsObject
  dismissed?: boolean
}

export const enqueueSnackbar = (notification: IEnqueueSnackbar) => (
  dispatch: AppDispatch
): void => {
  dispatch({
    type: ENQUEUE_SNACKBAR,
    payload: {
      ...notification,
      key: notification.options.key || new Date().getTime() + Math.random(),
    },
  })
}

export const closeSnackbar = (key: SnackbarKey) => (
  dispatch: AppDispatch
): void => {
  dispatch({
    type: CLOSE_SNACKBAR,
    payload: {
      dismissAll: !key, // dismiss all if no key has been defined
      key,
    },
  })
}

export const removeSnackbarFromRedux = (key: SnackbarKey) => (
  dispatch: AppDispatch
): void => {
  dispatch({
    type: REMOVE_SNACKBAR,
    payload: {
      key,
    },
  })
}
