import Typography from '@material-ui/core/Typography'
import React from 'react'

interface ICircularComponent {
  size: number
  lineHeight: number
  fontSize: number
  backgroundColor: string
  text: string
}

const CircularComponent: React.FC<ICircularComponent> = ({
  size,
  backgroundColor,
  lineHeight,
  fontSize,
  text,
}) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        backgroundColor,
        borderRadius: '50%',
        display: 'inline-block',
      }}
      className="text-center"
    >
      <Typography
        style={{
          lineHeight,
          fontSize,
          fontWeight: 'bold',
          color: '#ffffff',
          fontFamily: 'Open Sans - Extra Bold',
        }}
      >
        {text}
      </Typography>
    </div>
  )
}

export default CircularComponent
