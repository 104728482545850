import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import classnames from 'classnames'
import React, { useEffect } from 'react'

import ConfirmationDialog from '../../../shared/components/ConfirmationDialog'
import { useAppDispatch, useAppSelector } from '../../../shared/store'
import {
  addMemberToPractice,
  getTotalPracticeUsers
} from '../../../shared/store/auth/actions'
import _ from '../../../shared/utils/lodashExtensions'
import { useAddPracticeMemberStyles } from './AddPracticeMember.styles'

const AddPracticeMember: React.FC<{ handleShowTable: () => void }> = ({
  handleShowTable,
}) => {
  const dispatch = useAppDispatch()
  const classes = useAddPracticeMemberStyles()

  const auth = useAppSelector((state) => state.auth)
  const stateErrors = useAppSelector((state) => state.errors)

  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [totalUsers, setTotalUsers] = React.useState(0)
  const [maxLimitReachedMsg, setMaxLimitReachedMsg] = React.useState('')
  const [addButtonClick, setAddButtonClick] = React.useState(false)
  const [addMemberDialog, setAddMemberDialog] = React.useState(false)
  const [confirmAddMember, setConfirmAddMember] = React.useState(false)

  const [errors, setErrors] = React.useState({
    name: '',
    email: '',
  })

  useEffect(() => {
    dispatch(
      getTotalPracticeUsers({
        practiceId: auth.userdata.practiceId,
      })
    )
  }, [auth.userdata.practiceId, dispatch])

  useEffect(() => {
    setName('')
    setEmail('')
    setMaxLimitReachedMsg('')
    setTotalUsers(+auth.totalPracticeMember)

    if (Number(auth.totalPracticeMember) >= Number(auth.practiceData.maxUsers)) {
      setMaxLimitReachedMsg(
        'Total allowed users for your Practice has exceeded the set: ' +
          auth.practiceData.maxUsers
      )
    }
    if (addButtonClick && _.isEmpty(stateErrors)) {
      handleShowTable()
    }
    setAddButtonClick(false)
  }, [
    addButtonClick,
    auth.practiceData.maxUsers,
    auth.totalPracticeMember,
    handleShowTable,
    stateErrors,
  ])

  useEffect(() => {
    const getErrors: any = stateErrors
    setErrors(getErrors)
  }, [stateErrors])

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleClose = () => {
    setAddMemberDialog(false)
  }

  const handleConfirmAddMember = () => {
    setAddMemberDialog(false)
    setConfirmAddMember(true)
    handleAddMember()
  }

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    let errorObj = { name: '', email: '' }
    const maxUsers = auth.practiceData.maxUsers

    if (name === '') {
      errorObj = {
        name: 'Name field is required.',
        email: '',
      }
      setErrors(errorObj)
    } else if (email === '') {
      errorObj = {
        name: '',
        email: 'Email field is required.',
      }
      setErrors(errorObj)
    } else if (!confirmAddMember && totalUsers >= maxUsers) {
      setErrors(errorObj)
      setAddMemberDialog(true)
    } else {
      handleAddMember()
    }
  }

  const handleAddMember = () => {
    const memberName = name.split(' ')
    const firstName = memberName[0]
    const lastName = memberName[1] ? memberName[1] : memberName[0]

    const newUser = {
      firstName,
      lastName,
      email,
      practiceId: auth.userdata.practiceId,
      maxUsers: auth.practiceData.maxUsers,
    }
    dispatch(addMemberToPractice(newUser))
    setConfirmAddMember(false)
    setAddButtonClick(true)
  }

  const showListTable = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleShowTable()

    dispatch({
      type: 'GET_ERRORS',
      payload: {},
    })
  }

  return (
    <Card className={classes.root}>
      <CardHeader title="Add member to your practice" />
      <Divider />
      <div style={{ padding: '20px 20px 0' }}>
        <Typography style={{ paddingBottom: '5px' }}>
          Add a new member to your practice by sending an email below. You can
          only add the set number of members to your practice.
        </Typography>
        <Typography
          style={{
            paddingBottom: '5px',
            fontSize: 13,
            fontWeight: 800,
            color: 'red',
          }}
        >
          {maxLimitReachedMsg}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="name"
              label="Provider Full Name"
              value={name}
              onChange={handleNameChange}
              className={classnames('', {
                invalid: errors.name,
              })}
            />
            <Typography color="error" className={classes.errorText}>
              {errors.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="providerEmail"
              name="email"
              type="email"
              value={email}
              label="Email Address"
              onChange={handleEmailChange}
              className={classnames('', {
                invalid: errors.email,
              })}
            />
            <Typography color="error" className={classes.errorText}>
              {errors.email}
            </Typography>
          </Grid>
        </Grid>

        <ConfirmationDialog
          message="You have already added max members in practice, if you add more it will cost you. Are you sure you want to add member?"
          title="Add Member"
          okOperationDialog={handleConfirmAddMember}
          handleClose={handleClose}
          dialogState={addMemberDialog}
        />
      </div>

      <CardActions className={classes.cardBottom}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          //disabled={totalUsers>=auth.practiceData.maxUsers}
        >
          Add Member
        </Button>
        <Button variant="contained" onClick={showListTable}>
          Cancel
        </Button>
      </CardActions>
    </Card>
  )
}

export default AddPracticeMember
