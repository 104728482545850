import MomentUtils from '@date-io/moment'
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  CircularProgressProps,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  MobileStepper,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import CollectionsOutlinedIcon from '@material-ui/icons/CollectionsOutlined'
import HighlightOff from '@material-ui/icons/HighlightOff'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import classnames from 'classnames'
import heic2any from 'heic2any'
import produce, { Draft } from 'immer'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf'
import { RouteComponentProps } from 'react-router'

import ViewFile from '../../shared/components/ViewFile/ViewFile'
import useComponentWillUnmountWithStateAccessInCleanup from '../../shared/hooks/useComponentWillUnmountWithStateAccessInCleanup'
import { useAppDispatch, useAppSelector } from '../../shared/store'
import {
  getAthenaLatestEncounterSummary,
  getLatestEncounterSummaryElation,
  getLatestEncouterSummaryCerbo,
  getUserPatients,
  getUserSpecialties,
  sendCase,
  triggerAthenaPatientChangeEvent,
} from '../../shared/store/case/actions'
import {
  IModifyDraft,
  ISaveDraft,
  modifyDraft,
  saveDraft,
  setDraftAsCompleted,
} from '../../shared/store/draft/actions'
import { ICase } from '../../shared/types'
import { sendAmplitudeData } from '../../shared/utils/amplitude'
import { amplitudeEvents } from '../../shared/utils/constant'
import {
  decodeString,
  encodeString,
  generateCaseID,
  isEmpty,
} from '../../shared/utils/functions'
import {
  getSignedUrl,
  removeFileFromS3,
  uploadFile,
} from '../../shared/utils/s3'
import DragDropPlugin from '../NewCase/DragDropPlugin'
import DropzonePlugin from '../NewCase/DropzonePlugin'
import { useNewCaseStyles } from './NewCasePage.styles'

interface ISelectedPatient {
  id: string
  fullName: string
  dob: null | string
}
interface ISelectedSpecialty {
  specialtyId: number
  title: string
  value: string
}

interface IFileData {
  name: string
  type: string
}
interface IFileState {
  fileData: IFileData
  fileUrl: string
}
interface IError {
  specialty: undefined | string
  patientName: undefined | string
  dob: undefined | string
  patientConsentGiven: undefined | string
}
type INewCaseProps = RouteComponentProps<{ id: string }>

const NewCasePage: React.FC<INewCaseProps> = ({ match, history }) => {
  const userdata = useAppSelector((state) => state.auth.userdata)
  const athenaPracticeId = useAppSelector(
    (state) => state.auth.practiceData?.athenaPracticeId
  )
  const specialtyList = useAppSelector((state) => state.case.specialtyList)
  const userPatientList = useAppSelector((state) => state.case.userPatient.list)
  const userPatientIsLoading = useAppSelector(
    (state) => state.case.userPatient.isLoading
  )
  const caseLoader = useAppSelector((state) => state.case.caseLoader)
  const athenaLatestEncouterSummary = useAppSelector(
    (state) => state.case.athenaLatestEncouterSummary
  )
  const cerboLatestEncouterSummary = useAppSelector(
    (state) => state.case.cerboLatestEncouterSummary
  )
  const elationLatestEncouterSummary = useAppSelector(
    (state) => state.case.elationLatestEncouterSummary
  )

  const currentDraft = useAppSelector((state) => state.draft.currentDraft)
  const dispatch = useAppDispatch()

  const [isChecked, setIsChecked] = useState(false)
  const [questionText, setQuestionText] = useState('')
  const [files, setFiles] = useState<IFileState[]>([])
  const [fileUploadIsLoading, setFileUploadIsLoading] = useState(false)
  const [errors, setErrors] = useState<IError>({
    specialty: undefined,
    patientName: undefined,
    dob: undefined,
    patientConsentGiven: undefined,
  })

  const [patientNameAutocompleteIsOpen, setPatientNameAutocompleteIsOpen] =
    useState(false)

  const [selectedPatient, setSelectedPatient] = useState<ISelectedPatient>({
    id: '',
    fullName: '',
    dob: null,
  })

  const [athenaPatientId, setAthenaPatientId] = useState('')
  const [cerboPatientId, setCerboPatientId] = useState('')
  const [elationPatientId, setElationPatientId] = useState('')

  const [selectedSpecialtyList, setSelectedSpecialtyList] = useState<
    ISelectedSpecialty[]
  >([])

  const [activeStep, setActiveStep] = useState(0)
  const [displayModal, setDisplayModal] = useState(false)
  const [progress, setProgress] = useState(0)

  const classes = useNewCaseStyles()

  useEffect(() => {
    if (userdata.userType !== 'PCP' && userdata.userType !== 'PCPSUPERUSER') {
      history.push('/inbox/cases')
    }
  }, [history, userdata.userType])

  useEffect(() => {
    if (athenaPracticeId) {
      dispatch(
        triggerAthenaPatientChangeEvent({
          athenaPracticeId,
          practiceId: userdata.practiceId,
        })
      )
    }
  }, [dispatch, athenaPracticeId, userdata.practiceId])

  useComponentWillUnmountWithStateAccessInCleanup(() => {
    if (
      !caseLoader &&
      selectedPatient.dob !== '' &&
      (selectedSpecialtyList.length ||
        selectedPatient.fullName !== '' ||
        questionText !== '' ||
        files.length)
    ) {
      const currentTimestamp = new Date().getTime()
      const newDraft: IModifyDraft | ISaveDraft = {
        caseId: currentDraft.draftData
          ? currentDraft.draftData.caseId
          : generateCaseID(selectedPatient.fullName, currentTimestamp),
        providerId: userdata.id,
        specialistId: '', // this is always empty string? do we need this?
        patientId: selectedPatient.id,
        specialtyIds: selectedSpecialtyList.map((opt) => opt.specialtyId),
        patientName: selectedPatient.fullName,
        dob: selectedPatient.dob
          ? moment(selectedPatient.dob).format('YYYY-MM-DD')
          : '',
        questions: questionText === '' ? '' : encodeString(questionText),
        files: files.map((item) => item.fileData),
        isCompleted: false,
        patientConsentGiven: isChecked,
      }
      if (currentDraft.draftData) {
        dispatch(modifyDraft(newDraft))
      } else {
        dispatch(saveDraft(newDraft))
      }
    }
  })

  useEffect(() => {
    dispatch(
      getUserSpecialties({
        practiceId: userdata.practiceId,
        fetchUsers: true,
      })
    )
    sendAmplitudeData(
      userdata.email,
      amplitudeEvents.CASE_CREATE_PAGE,
      userdata
    )
    window.addEventListener('dragenter', handleDragEnter)
  }, [
    dispatch,
    history,
    match.params.id,
    userdata,
    userdata.practiceId,
    userdata.userType,
  ])

  // ONLY ON OPENNING DRAFT
  useEffect(() => {
    if (!isEmpty(currentDraft) && currentDraft.draftData) {
      if (currentDraft.draftData.files) {
        for (const draftFile of currentDraft.draftData.files) {
          getSignedUrl('get', draftFile.name).then((fileResponse) => {
            const url = fileResponse.data.url
            const fileFound = currentDraft.draftData?.files?.find((file) =>
              url.includes(file.name)
            )
            if (fileFound) {
              setFiles(
                produce((draft) => {
                  draft.push({ fileData: fileFound, fileUrl: url })
                })
              )
            }
          })
        }
      }
      setQuestionText(decodeString(currentDraft.draftData.questions))
      setSelectedPatient({
        id: currentDraft.draftData.patientId,
        fullName: currentDraft.draftData.patientName,
        dob: currentDraft.draftData.dob,
      })
    }
  }, [currentDraft])

  useEffect(() => {
    if (!isEmpty(currentDraft) && currentDraft.draftData) {
      if (currentDraft.draftData.specialty) {
        const selectedSpecialtyList = specialtyList
          .filter((specialty) =>
            currentDraft.draftData?.specialtyIds.includes(specialty.specialtyId)
          )
          .map((userSpecialty) => ({
            specialtyId: userSpecialty.specialtyIds,
            value: userSpecialty.value,
            title: userSpecialty.value,
          }))
        setSelectedSpecialtyList(selectedSpecialtyList)
      }
    }
  }, [currentDraft, specialtyList])

  useEffect(() => {
    if (athenaLatestEncouterSummary.pdfUrl) {
      setFiles(
        produce((draft: Draft<any>) => {
          draft.push({
            fileData: athenaLatestEncouterSummary.pdfFileObj,
            fileUrl: athenaLatestEncouterSummary.pdfUrl,
          })
        })
      )
    }
    if (cerboLatestEncouterSummary.pdfUrl) {
      setFiles(
        produce((draft: Draft<any>) => {
          draft.push({
            fileData: cerboLatestEncouterSummary.pdfFileObj,
            fileUrl: cerboLatestEncouterSummary.pdfUrl,
          })
        })
      )
    }
    if (elationLatestEncouterSummary.pdfUrl) {
      setFiles(
        produce((draft: Draft<any>) => {
          draft.push({
            fileData: elationLatestEncouterSummary.pdfFileObj,
            fileUrl: elationLatestEncouterSummary.pdfUrl,
          })
        })
      )
    }
  }, [
    athenaLatestEncouterSummary.pdfFileObj,
    athenaLatestEncouterSummary.pdfUrl,
    cerboLatestEncouterSummary.pdfFileObj,
    cerboLatestEncouterSummary.pdfUrl,
    elationLatestEncouterSummary.pdfFileObj,
    elationLatestEncouterSummary.pdfUrl,
  ])

  const handleDragEnter = () => {
    const div = document.getElementById('webapp-drag-drop-div')
    if (div) {
      div.style.visibility = 'visible'
    }
  }

  const handleChangeSpecialtySelectMultiple = (_e: any, options: any) => {
    const newList = options.map((opt: any) => ({
      specialtyId: opt.specialtyId,
      title: opt.title,
      value: opt.value,
    }))
    setSelectedSpecialtyList(newList)
  }

  const handleChangePatientNameSelect = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    _e: React.ChangeEvent<{}>,
    opt: any
  ) => {
    if (opt && opt.id !== '') {
      setSelectedPatient({
        id: opt.id,
        fullName: opt.fullName,
        dob: opt.dob,
      })
    }
    setAthenaPatientId(opt?.athenaPatientId ? opt.athenaPatientId : '')
    setCerboPatientId(opt?.cerboPatientId ? opt.cerboPatientId : '')
    setElationPatientId(opt?.elationPatientId ? opt.elationPatientId : '')
  }

  const handleChangePatientNameTextfield = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target
    if (value !== '' || value !== null) {
      setSelectedPatient({
        id: selectedPatient.id,
        fullName: value,
        dob: selectedPatient.dob,
      })
      dispatch(
        getUserPatients({
          currentUserId: userdata.id,
          currentPracticeId: userdata.practiceId,
          currentUserRole: userdata.userType,
          keyword: value,
        })
      )
    }
  }

  const setDOB = (_event: string | null | undefined, date: string) => {
    setSelectedPatient({ ...selectedPatient, dob: date })
  }

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionText(e.target.value)
  }

  const handleFileUpload = async (newFiles: File[]) => {
    setFileUploadIsLoading(true)
    for (let newFile of newFiles) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 1
        )
      }, 75)
      const fileExtension = newFile.name.split('.').pop()
      if (fileExtension === 'heic' || fileExtension === 'heif') {
        const blobImage = await heic2any({
          blob: newFile,
          toType: 'image/png',
          quality: 1,
        })
        const fileName = newFile.name.replace(/heic|heif|HEIC|HEIF/g, 'png')
        const newImage = new File([blobImage as any], fileName, {
          lastModified: new Date().getTime(),
          type: 'image/png',
        })
        newFile = newImage
      }
      const [file, url] = await uploadFile(newFile)
      setFiles(
        produce((draft) => {
          draft.push({ fileData: file, fileUrl: url })
        })
      )
      setProgress(0)
      clearInterval(timer)
    }
    setFileUploadIsLoading(false)
  }

  const handleSubmit = () => {
    if (
      selectedSpecialtyList.length &&
      selectedPatient.fullName !== '' &&
      selectedPatient.dob !== '' &&
      isChecked
    ) {
      const currentTime = new Date()
      const currentTimestamp = currentTime.getTime()
      const caseId = generateCaseID(selectedPatient.fullName, currentTimestamp)

      const dateofbirth = moment(selectedPatient.dob).format('YYYY-MM-DD')

      const newCase: ICase = {
        caseId,
        providerId: userdata.id,
        practiceId: userdata.practiceId,
        patientId: selectedPatient.id,
        specialtyIds: selectedSpecialtyList.map((spec) => spec.specialtyId),
        patientName: selectedPatient.fullName,
        dob: dateofbirth,
        questions: encodeString(questionText),
        files: files.length ? files.map((item) => item.fileData) : null,
        isRead: true,
        isDeleted: false,
        patientConsentGiven: isChecked,
        email: userdata ? userdata.email : '',
      }

      const eventData = { ...userdata, ...newCase }
      sendAmplitudeData(userdata.email, amplitudeEvents.CASE_CREATED, eventData)

      dispatch(sendCase(newCase, history))

      if (currentDraft.draftData) {
        dispatch(
          setDraftAsCompleted({
            caseId: currentDraft.draftData?.caseId || '',
            isCompleted: true,
          })
        )
      }
    } else {
      if (!selectedSpecialtyList.length) {
        setErrors({ ...errors, specialty: 'Specialty field is required.' })
      } else if (selectedPatient.fullName === '') {
        setErrors({
          ...errors,
          patientName: 'Patient name field is required.',
        })
      } else if (selectedPatient.dob === null || selectedPatient.dob === '') {
        setErrors({ ...errors, dob: 'Patient DOB field is required.' })
      } else if (isChecked === false) {
        setErrors({
          ...errors,
          patientConsentGiven: 'Patient consent is required.',
        })
      }
    }
  }

  const goBack = () => {
    history.push('/inbox/cases')
  }

  const removeFile = async (item: IFileState) => {
    const resp = await removeFileFromS3(item)
    if (resp) {
      setFiles(
        files.filter((file) => file.fileData.name !== item.fileData.name)
      )
    }
  }

  const CircularProgressWithLabel = (
    props: CircularProgressProps & { value: number }
  ) => {
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          marginLeft: 10,
          marginTop: 10,
        }}
      >
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    )
  }

  const fileDisplay = (file: IFileData, fileUrl: string, fileIndex: number) => {
    const fileType = file.type.substring(0, file.type.indexOf('/'))
    const fileExt = file.type.split('/').pop()
    let fileDisplay

    if (fileType === 'image') {
      fileDisplay = (
        <img
          src={fileUrl}
          alt="Upload"
          style={{
            height: '14vh',
            overflow: 'hidden',
            display: 'block',
            width: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => handleCardClick(fileIndex)}
        />
      )
    } else if (fileType === 'application' && fileExt === 'pdf') {
      fileDisplay = (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => handleCardClick(fileIndex)}
        >
          <Document file={fileUrl}>
            <Page pageNumber={1} width={200} />
          </Document>
        </div>
      )
    } else if (fileType === 'application' && fileExt !== 'pdf') {
      fileDisplay = (
        <h6
          style={{
            marginLeft: '5%',
            marginRight: '5%',
            marginTop: '30%',
            alignItems: 'center',
            color: 'grey',
            display: 'block',
            cursor: 'pointer',
          }}
          onClick={() => handleCardClick(fileIndex)}
        >
          {' '}
          {fileExt} file{' '}
        </h6>
      )
    } else if (fileType === 'video') {
      fileDisplay = (
        <video
          src={fileUrl}
          style={{ width: '100%', height: '14vh', cursor: 'pointer' }}
          onClick={() => handleCardClick(fileIndex)}
        />
      ) //Edit this if required
    } else {
      fileDisplay = (
        <h5
          style={{
            marginLeft: '5%',
            marginRight: '5%',
            marginTop: '30%',
            alignItems: 'center',
            color: 'grey',
            display: 'block',
            cursor: 'pointer',
          }}
          onClick={() => handleCardClick(fileIndex)}
        >
          {' '}
          {fileExt} file{' '}
        </h5>
      )
    }
    return fileDisplay
  }

  const handleCardClick = (fileIndex: number) => {
    setDisplayModal(true)
    setActiveStep(fileIndex)
  }

  const handleClose = () => {
    setDisplayModal(false)
  }

  const handleNext = () => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handleBack = () => {
    setActiveStep((prevState) => prevState - 1)
  }

  const caseDataFiles: any = files ? files : []
  const maxSteps = files.length

  const keyboardEvents = (e: React.KeyboardEvent) => {
    const backDisabled = activeStep === 0
    const nextDisabled = activeStep === maxSteps - 1

    if (e.keyCode === 37 && !backDisabled) {
      handleBack()
    } else if (e.keyCode === 39 && !nextDisabled) {
      handleNext()
    }
  }

  const getLatestEncounter = () => {
    if (athenaPatientId) {
      dispatch(
        getAthenaLatestEncounterSummary({
          athenaPracticeId,
          athenaDepartmentId: 1,
          athenaPatientId,
        })
      )
    }
    if (cerboPatientId) {
      dispatch(
        getLatestEncouterSummaryCerbo({
          cerboPatientId,
          practiceId: userdata.practiceId,
        })
      )
    }
    if (elationPatientId) {
      dispatch(
        getLatestEncounterSummaryElation({
          elationPatientId,
          practiceId: userdata.practiceId,
        })
      )
    }
  }

  const dropzoneGridSize =
    athenaPatientId || cerboPatientId || elationPatientId ? 6 : 12

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <div className={classes.arrowBackWrapper}></div>
        <Grid
          item
          xs={12}
          md={10}
          sm={12}
          lg={8}
          xl={8}
          style={{
            height: '100%',
            margin: '200',
            position: 'relative',
            marginBottom: 20,
          }}
        >
          <Paper elevation={3}>
            {/* TODO: Reduce marginLeft for mobile size */}
            <Typography className={classes.headText}>Create A Case</Typography>
            <Divider />
            <div
              style={{
                paddingRight: '3em',
                paddingBottom: 0,
                height: '30%',
                minHeight: 130,
              }}
            >
              {/* ---------------- PATIENT NAME ---------------- */}
              <div style={{ display: 'flex', margin: '0.5em' }}>
                <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
                  <Autocomplete
                    freeSolo
                    open={patientNameAutocompleteIsOpen}
                    onOpen={() => {
                      setPatientNameAutocompleteIsOpen(true)
                    }}
                    onClose={() => {
                      setPatientNameAutocompleteIsOpen(false)
                    }}
                    onChange={handleChangePatientNameSelect}
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.fullName
                    }
                    getOptionSelected={(option, value) => {
                      return option.title === value.title
                    }}
                    options={userPatientList ?? []}
                    loading={userPatientIsLoading}
                    renderOption={(option) => (
                      <Typography noWrap>
                        {option.fullName}{' '}
                        {option.dob
                          ? '(' + moment(option.dob).format('MM/DD/YYYY') + ')'
                          : ''}
                      </Typography>
                    )}
                    value={selectedPatient.fullName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="patientName"
                        label="Type Patient Name"
                        name="patientName"
                        value={selectedPatient.fullName}
                        className={classnames(classes.specialItem, {
                          invalid: errors.patientName,
                        })}
                        onChange={handleChangePatientNameTextfield}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {userPatientIsLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </div>

              {/* ---------------- PATIENT DOB ---------------- */}
              <div style={{ display: 'flex', margin: '0.5em' }}>
                <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      inputVariant="outlined"
                      required
                      fullWidth
                      disableFuture
                      className={classnames(classes.specialItem, {
                        itemnvalid: errors.specialty,
                      })}
                      name="dob"
                      placeholder="09/09/1990"
                      format="MM/DD/YYYY"
                      label="Type Patient DOB"
                      InputAdornmentProps={{ position: 'start' }}
                      value={selectedPatient.dob}
                      onChange={(date, event) => setDOB(event, String(date))}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </div>
              <Typography color="error" className={classes.errorText}>
                {errors.dob}
              </Typography>

              {/* ---------------- SPECIALTY ---------------- */}
              <div style={{ display: 'flex', margin: '0.5em' }}>
                {/* TODO: Increase marginLeft for normal size */}
                <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={specialtyList}
                    getOptionLabel={(option: ISelectedSpecialty) =>
                      option.title
                    }
                    onChange={handleChangeSpecialtySelectMultiple}
                    value={selectedSpecialtyList}
                    getOptionSelected={(option, value) =>
                      option.title === value.title
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="specialty"
                        name="specialty"
                        label="Select Specialty"
                        className={classnames(classes.specialItem, {
                          invalid: errors.specialty,
                        })}
                      />
                    )}
                  />
                </Grid>
              </div>
              <Typography color="error" className={classes.errorText}>
                {errors.specialty}
              </Typography>
            </div>

            {/* ---------------- DESCRIPTION TEXTBOX ---------------- */}
            <Card className={classes.mainNewCaseWrapper} elevation={0}>
              <div style={{ paddingLeft: 10, height: '30%', minHeight: 50 }}>
                <TextField
                  multiline
                  fullWidth
                  required
                  maxRows="7"
                  minRows="4"
                  value={questionText}
                  name="question"
                  placeholder={
                    'Type a specific question for the specialist here'
                  }
                  onChange={handleQuestionChange}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </Card>

            {/* DRAGDROP ON FULL PAGE */}
            <Card style={{ boxShadow: 'none' }}>
              <DragDropPlugin handleFileUpload={handleFileUpload} />
            </Card>

            {/* Add Encounter & Dropzone File */}
            <Card style={{ padding: '5px 30px 20px 30px' }} elevation={0}>
              <Grid container spacing={2}>
                {(athenaPatientId || cerboPatientId || elationPatientId) && (
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div
                      className={classes.sectionBorder}
                      style={{ cursor: 'pointer' }}
                      onClick={getLatestEncounter}
                      data-selector="get-encounter"
                    >
                      <CollectionsOutlinedIcon
                        style={{ width: 80, height: 80 }}
                      />
                      <Typography className={classes.subText}>
                        Add latest encounter
                      </Typography>
                    </div>
                  </Grid>
                )}

                {/* DROPZONE FILE */}
                <Grid
                  item
                  xs={12}
                  sm={dropzoneGridSize}
                  md={dropzoneGridSize}
                  lg={dropzoneGridSize}
                  xl={dropzoneGridSize}
                >
                  <div className={classes.sectionBorder}>
                    <DropzonePlugin
                      removeFile={removeFile}
                      handleFileUpload={handleFileUpload}
                      files={files.map((item) => item.fileData) ?? []}
                    />
                  </div>
                </Grid>
              </Grid>
            </Card>

            {/* DROPZONE FILE */}
            <Card elevation={0}>
              {/* <div style={{ paddingBottom: 50, height: '0%', minHeight: 180 }}>
                <DropzonePlugin
                  removeFile={removeFile}
                  handleFileUpload={handleFileUpload}
                  files={files.map((item) => item.fileData) ?? []}
                />
              </div> */}

              <Divider />
              {(fileUploadIsLoading ||
                athenaLatestEncouterSummary.isLoading ||
                cerboLatestEncouterSummary.isLoading ||
                elationLatestEncouterSummary.isLoading) && (
                <CircularProgressWithLabel value={progress} />
              )}
              {files.length > 0 && (
                <aside
                  style={{
                    maxHeight: 250,
                    overflow: 'auto',
                    backgroundColor: 'white',
                    margin: '10px',
                  }}
                >
                  <GridList cols={5.5}>
                    {files.length ? (
                      files.map((item, i) => (
                        <GridListTile key={item.fileUrl}>
                          {fileDisplay(item.fileData, item.fileUrl, i)}
                          <GridListTileBar
                            title={item.fileData.name.slice(13)}
                            titlePosition="top"
                            actionIcon={
                              <IconButton onClick={() => removeFile(item)}>
                                <HighlightOff
                                  style={{ marginLeft: 5, color: 'white' }}
                                />
                              </IconButton>
                            }
                          />
                        </GridListTile>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </GridList>
                </aside>
              )}
            </Card>
            {/* CHECKBOX */}
            <div
              style={{
                paddingLeft: 30,
                paddingTop: 20,
                display: 'flex',
                flexDirection: 'column',
                height: '8%',
                justifyContent: 'center',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    onChange={() => setIsChecked(true)}
                  />
                }
                label="Patient has verbally consented to this consultation including any financial obligations that arise from it.*"
              />
              <Typography
                style={{ marginLeft: 0 }}
                color="error"
                className={classes.errorText}
              >
                {errors.patientConsentGiven}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: 80,
                minHeight: 90,
              }}
            >
              <Grid item xs={6} md={6} sm={12} lg={6} xl={6}>
                <div style={{ display: 'flex' }}>
                  <Fab
                    color="inherit"
                    aria-label="add"
                    className={classes.backFAB}
                    onClick={goBack}
                  >
                    <ArrowBackIcon className={classes.arrowBackIcon} />
                  </Fab>
                  <Button disabled>
                    <Typography className={classes.backToInboxText}>
                      Back to Inbox
                    </Typography>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} md={6} sm={12} lg={6} xl={6}>
                <Button
                  variant="contained"
                  className={`${classes.sendButtonRightAlign} ${classes.addButton}`}
                  disabled={
                    fileUploadIsLoading || athenaLatestEncouterSummary.isLoading
                  }
                  color="primary"
                  onClick={handleSubmit}
                >
                  <Typography className={classes.addButton}>Send</Typography>
                </Button>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>

      {caseDataFiles.length && (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={displayModal}
          onClose={handleClose}
          BackdropProps={{ style: { top: 64, left: '0%' } }} //The grey Modal background when a file is opened
          onKeyDown={keyboardEvents}
        >
          <div className={classes.modalContainer}>
            <Card
              className={classes.modalCard}
              style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
            >
              <ViewFile
                file={caseDataFiles[activeStep].fileData}
                fileUrl={caseDataFiles[activeStep].fileUrl}
              />
            </Card>

            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="text"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                  style={{ color: '#fff' }}
                >
                  Next
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  style={{ color: '#fff' }}
                >
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
            />
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                paddingTop: 5,
              }}
            >
              <Fab
                variant="round"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon />
              </Fab>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default NewCasePage
