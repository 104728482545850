import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useAppSelector } from '../../store'
import InboxLayout from '../layouts/InboxLayout'

const InboxRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  if (!component) {
    throw Error('Component is Undefined')
  }
  const auth = useAppSelector((state) => state.auth)
  const Component = component

  if (!auth.isAuthenticated && window.location.search) {
    const url = new URL(window.location.href)
    const caseId = url.searchParams.get('caseId')
    if (caseId) {
      localStorage.setItem('caseId', caseId)
    }
  }

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        auth.isAuthenticated === true ? (
          <InboxLayout {...matchProps}>
            <Component {...matchProps} />
          </InboxLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

export default InboxRoute
