import { makeStyles, Theme } from '@material-ui/core'

export const useMemberInviteTableStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
    [theme.breakpoints.down(550)]: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
  container: {
    [theme.breakpoints.down(550)]: {
      width: 500,
    },
    [theme.breakpoints.down(500)]: {
      width: 450,
    },
    [theme.breakpoints.down(450)]: {
      width: 400,
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  footerPagination: {
    padding: 10,
  },
  addMemberBtn: {
    position: 'absolute',
    margin: '-55px 15px',
  },
  inviteLinkBtn: {
    position: 'absolute',
    margin: '-55px 150px',
    [theme.breakpoints.down(450)]: {
      position: 'relative',
      margin: '0 15px 15px',
    },
  },
}))
