import { AnyAction } from 'redux'

import { GET_ERRORS } from './types'

export interface IError {
  message: string
  name: string
  stack: string
  config: any[]
}

const initialState: IError | unknown = {}

const errorReducer = (
  state = initialState,
  action: AnyAction
): IError | unknown => {
  switch (action.type) {
    case GET_ERRORS:
      return action.payload
    default:
      return state
  }
}
export default errorReducer
