import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useAppSelector } from '../../store'
import DashboardLayout from '../layouts/DashboardLayout'

const DashboardRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  if (!component) {
    throw Error('Component is Undefined')
  }
  const auth = useAppSelector((state) => state.auth)
  const Component = component

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        auth.isAuthenticated === true ? (
          <DashboardLayout>
            <Component {...matchProps} />
          </DashboardLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

export default DashboardRoute
