export const specialtyList = [
  { value: 'All Specialties', title: 'All Specialties' },
  { value: 'Dermatology', title: 'Dermatology' },
  { value: 'Endocrinology', title: 'Endocrinology' },
  { value: 'Neurology', title: 'Neurology' },
  { value: 'Ophthalmology', title: 'Ophthalmology' },
  { value: 'Orthopedics', title: 'Orthopedics' },
  { value: 'Cardiology', title: 'Cardiology' },
  { value: 'Nephrology', title: 'Nephrology' },
  { value: 'Psychiatry', title: 'Psychiatry' },
  { value: 'Pulmonology', title: 'Pulmonology' },
]

export const pennsylvaniaList = [
  { value: 'Cardiology', title: 'Cardiology' },
  { value: 'Dermatology', title: 'Dermatology' },
  { value: 'Endocrinology', title: 'Endocrinology' },
  { value: 'Neurology', title: 'Neurology' },
  { value: 'Ophthalmology', title: 'Ophthalmology' },
  { value: 'Orthopedics', title: 'Orthopedics' },
]

export const washingtonList = [
  { value: 'Cardiology', title: 'Cardiology' },
  { value: 'Dermatology', title: 'Dermatology' },
  { value: 'Endocrinology', title: 'Endocrinology' },
  { value: 'Neurology', title: 'Neurology' },
  { value: 'Ophthalmology', title: 'Ophthalmology' },
  { value: 'Orthopedics', title: 'Orthopedics' },
]

export const californiaList = [
  { value: 'Cardiology', label: 'Cardiology - Dr. Brian Keeffe' },
  { value: 'Dermatology', label: 'Dermatology' },
  { value: 'Endocrinology', label: 'Endocrinology' },
  {
    value: 'Gastroenterology',
    label: 'Gastroenterology - Dr. Timothy Sowerby',
  },
  {
    value: 'General Surgery',
    label: 'General Surgery - Dr. Alexis Alexandridis',
  },
  { value: 'Neurology', label: 'Neurology' },
  { value: 'Ophthalmology', label: 'Ophthalmology - Dr. Kathryn Najafi-Tagol' },
  { value: 'Orthopedics', label: 'Orthopedics' },
  { value: 'Otolaryngology', label: 'Otolaryngology - Dr. Dana Petersen' },
]

export const practiceList = [
  { value: 'HatboroMedical', label: 'HatboroMedical' },
  { value: 'Sellersville', label: 'Sellersville' },
  { value: 'Handelsman', label: 'Handelsman' },
  { value: 'HoucksRoadFamilyPractice ', label: 'HoucksRoadFamilyPractice' },
  { value: 'Bixby', label: 'Bixby' },
  { value: 'Beda', label: 'Beda' },
  { value: 'eyeOps', label: 'eyeOps' },
  { value: 'OrthoTelecon', label: 'OrthoTelecon' },
]

export const snackbarMessages = Object.freeze({
  CASE_STATE_MODIFIED: 'Case state has been modified!',
  INTERNAL_ERROR:
    'An unexpected internal error ocurred. Please contact support.',
  SESION_EXPIRED: 'Session expired. Login again.',
  USER_DATA_SAVED_SUCCESS: 'User data saved successfully',
  ACCOUNT_CREATED_SUCCESS: 'Account created successfully.',
  MEMBER_DELETED_SUCCESS: 'Member has been deleted successfully',
  MEMBER_ADDED_SUCCESS: 'Member has been added successfully',
  INVITE_EMAIL_SUCCESS:
    "Email has been sent. Please make sure your friend checks their spam folder in case they don't see the email",
  ATHENA_CLAIM_CREATED_SUCCESS: 'Athena Health claim creation successful.',
  ATHENA_CLAIM_CREATED_FAIL: 'Athena Health claim creation failed.',
  INVITE_LINK_COPIES: 'Invite link is copied to clipboard',
  DEFAULT_SPECIALIST_MODIFIED: 'Default specialist has been updated.',
  INVITATION_SENT: 'Refer invitation has been sent.',
})

export const getDefaultHtml2PdfOptions = (fileName: string): unknown => {
  return {
    margin: 1,
    filename: fileName,
    image: { type: 'jpeg', quality: 0.95 },
    html2canvas: {
      dpi: 300,
      letterRendering: true,
      useCORS: true,
      scrollX: 0,
      scrollY: 0,
    },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    pagebreak: {
      before: ['.pg-break-before'],
      after: ['.pg-break-after'],
      avoid: '.pdf-avoid-split',
      mode: ['avoid-all', 'css', 'legacy'],
    },
  }
}

export const amplitudeEvents = Object.freeze({
  LOGIN_SUCCESS: 'Login Success',
  LOGIN_FAILED: 'Login Failed',
  LOGOUT: 'Logout',
  FORGOT_PASSWORD: 'Forgot Password',
  INBOX_PAGE: 'Inbox Page Opened',
  CLOSE_CASE_PAGE: 'Close Case Page Opened',
  ACCOUNT_PAGE: 'Account Page Opened',
  DRAFT_PAGE: 'Draft Page Opened',
  DASHBOARD_PAGE: 'Dashboard Page Opened',
  CASE_CREATE_PAGE: 'Create Case Page Opened',
  CASE_DETAILS_PAGE: 'Case Details Page Opened',
  CASE_CREATED: 'Case Created',
  CASE_CLOSED: 'Case Closed',
  CASE_FILE_UPLOAD: 'File Uploaded in case',
  CASE_FILE_REMOVE: 'File Removed in case',
  CASE_MESSAGE_SENT: 'Case message sent',
  SUPPORT_POPUP: 'Support Popup Opened',
  SUPPORT_CASE_CREATED: 'Support Case Created',
})
