import { makeStyles, Theme } from '@material-ui/core'

export const useReferStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    paddingTop: 5,
  },
  heading: {
    fontSize: theme.typography.pxToRem(30),
  },
  mainNewCaseWrapper: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    borderRadius: 0,
    backgroundColor: '#fff',
    paddingTop: theme.spacing(2.5),
    paddingRight: theme.spacing(3.5),
    paddingLeft: theme.spacing(2.5),
    paddingBottom: theme.spacing(4),
  },
  errorText: {
    fontSize: 12,
    fontWeight: 500,
  },
  sendBtn: {
    marginLeft: 0,
    width: '15%',
    marginTop: '6px',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'auto',
    float: 'left',
  },
}))
