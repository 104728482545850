export const SET_SPECIALTY_FULL_LIST = 'SET_SPECIALTY_FULL_LIST'
export const USER_LOADING = 'USER_LOADING'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const USER_DATA = 'USER_DATA'
export const PRACTICE_DATA_REQUEST = 'PRACTICE_DATA_REQUEST'
export const PRACTICE_DATA = 'PRACTICE_DATA'
export const CLIENT_DATA = 'CLIENT_DATA'
export const STRIPE_DATA = 'STRIPE_DATA'
export const STRIPE_DATA_REQUEST = 'STRIPE_DATA_REQUEST'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const USER_LOGOUT = 'USER_LOGOUT'
export const NEW_ACCOUNT_REQUEST = 'NEW_ACCOUNT_REQUEST'
export const NEW_ACCOUNT = 'NEW_ACCOUNT'
export const GET_ERRORS = 'GET_ERRORS'
export const ACTION_PERFORMED = 'ACTION_PERFORMED'
export const PRACTICE_MEMBER_DATA = 'PRACTICE_MEMBER_DATA'
export const TOTAL_PRACTICE_MEMBER = 'TOTAL_PRACTICE_MEMBER'
export const SET_HINT_DATA = 'SET_HINT_DATA'
