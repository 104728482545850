import {
  Button,
  Container,
  CssBaseline,
  Grid,
  Hidden,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import classnames from 'classnames'
import MuiPhoneNumber from 'material-ui-phone-number'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import textLogo from '../../shared/assets/images/text-logo.png'
import { useAppDispatch, useAppSelector } from '../../shared/store'
import {
  fetchPracticeData,
  getSpecialtyFullList,
  registerUser,
} from '../../shared/store/auth/actions'
import { decodeString } from '../../shared/utils/functions'
import { useSignUpStyles } from './SignUpPage.styles'
import Steps from './Steps/Steps'

const stepActive = 0

const SignUpPage: React.FC<
  RouteComponentProps<{
    isAdmin: string | undefined
    maxUser: string | undefined
    practiceId: string | undefined
    hash: string | undefined
  }>
> = ({ match, history }) => {
  const [formData, setFormData] = React.useState({
    firstName: '',
    lastName: '',
    practice: '',
    role: '',
    specialtyId: null as null | string,
    email: '',
    password: '',
    password2: '',
    isAdmin: false,
    phoneNumber: '',
    errors: {},
  })
  const [displayText, setDisplayText] = useState(false)

  const [errors, setErrors] = React.useState({
    firstName: '',
    lastName: '',
    practice: '',
    role: '',
    specialtyId: '',
    email: '',
    password: '',
    password2: '',
    isAdmin: '',
    phoneNumber: '',
  })

  const auth = useAppSelector((state) => state.auth)
  const specialtyFullList = useAppSelector(
    (state) => state.auth.specialtyFullList
  )
  const stateErrors = useAppSelector((state) => state.errors)

  const dispatch = useAppDispatch()

  const classes = useSignUpStyles()

  let isAdmin = match.params.isAdmin === 'true'
  let maxUser = parseInt(match.params.maxUser ? match.params.maxUser : '1')
  let practiceId = parseInt(
    match.params.practiceId ? match.params.practiceId : '0',
    10
  )

  if (match.params.hash && match.params.hash !== '') {
    const hash = decodeString(match.params.hash)
    const params = JSON.parse(hash)

    isAdmin = params.isAdmin === 'true'
    maxUser = parseInt(params.maxUser ? params.maxUser : '1')
    practiceId = parseInt(params.practiceId ? params.practiceId : '0', 10)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    })
  }

  const onChangeUserRole = (e: React.ChangeEvent<HTMLInputElement>) => {
    let specialtyId = ''
    if (e.target.value === 'PCP' || e.target.value === 'Biller') {
      specialtyId = ''
    }
    setFormData({
      ...formData,
      specialtyId,
      role: e.target.value,
    })
  }

  const onChangeSpecialtyId = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormData({
      ...formData,
      specialtyId: e.target.value,
    })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const newUser = {
      practiceId,
      maxUser,
      isAdmin,
      firstName: formData.firstName,
      lastName: formData.lastName,
      role: formData.role,
      specialtyId: formData.specialtyId,
      practice: formData.practice,
      email: formData.email,
      password: formData.password,
      password2: formData.password2,
      phoneNumber: formData.phoneNumber,
    }

    dispatch(registerUser(newUser, history))
  }

  useEffect(() => {
    document.addEventListener('keydown', handleHitEnter, true)
    return () => {
      document.removeEventListener('keydown', handleHitEnter, true)
    }
  }, [])

  useEffect(() => {
    if (practiceId) {
      dispatch(fetchPracticeData(practiceId))

      if (
        auth &&
        auth.practiceData &&
        auth.practiceData.id &&
        auth.practiceData.practiceName
      ) {
        setFormData({
          ...formData,
          practice: auth.practiceData.practiceName,
        })
        setDisplayText(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceId])

  useEffect(() => {
    dispatch(getSpecialtyFullList())
  }, [dispatch])

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push('/inbox/cases')
    }
  }, [auth.isAuthenticated, history])

  useEffect(() => {
    const getErrors: any = stateErrors
    setErrors(getErrors)
  }, [stateErrors])

  const handleHitEnter = (e: any) => {
    const ENTER_KEY_CODE = 13
    if (
      e.target.name === 'my-input' &&
      (e.key === 'Enter' || e.keyCode === ENTER_KEY_CODE)
    ) {
      e.stopPropagation()
    }
  }

  const handlePhoneChange = (phone: string) => {
    if (phone) {
      phone = phone.replace(/ /g, '')
      setFormData({
        ...formData,
        phoneNumber: phone,
      })
    }
  }

  return (
    <div>
      {isAdmin === true && (
        <Hidden smDown>
          <Steps stepActive={stepActive} />
        </Hidden>
      )}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div style={{ display: 'flex' }}>
            <img src={textLogo} alt="required" style={{ width: '100%' }} />
          </div>
          <div>
            <Typography
              component="h1"
              variant="h5"
              style={{ textAlign: 'center', margin: '20px 0' }}
            >
              {formData.practice} {'Sign Up'}
            </Typography>
            {isAdmin === true && (
              <Hidden mdUp>
                <Steps stepActive={stepActive} />
              </Hidden>
            )}
            <form className={classes.form} noValidate onSubmit={onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    onChange={onChange}
                    className={classnames('', {
                      invalid: errors.firstName,
                    })}
                  />
                  <Typography color="error" className={classes.errorText}>
                    {errors.firstName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    onChange={onChange}
                    className={classnames('', {
                      invalid: errors.lastName,
                    })}
                  />
                  <Typography color="error" className={classes.errorText}>
                    {errors.lastName}
                  </Typography>
                </Grid>
                {!displayText && isAdmin && (
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="practice"
                      label="Practice Name"
                      name="practice"
                      autoComplete="practice"
                      value={formData.practice}
                      onChange={onChange}
                      className={classnames('', {
                        invalid: errors.practice,
                      })}
                    />
                    <Typography color="error" className={classes.errorText}>
                      {errors.practice}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    select
                    variant="outlined"
                    // required
                    fullWidth
                    id="role"
                    label="Select Occupation"
                    name="role"
                    data-selector="select-occupation"
                    autoComplete="role"
                    value={formData.role}
                    onChange={onChangeUserRole}
                    className={classnames('', {
                      invalid: errors.role,
                    })}
                  >
                    <MenuItem value={'PCP'}>PCP</MenuItem>
                    <MenuItem value={'Specialty'}>Specialist</MenuItem>
                    <MenuItem value={'Biller'}>Practice Administrator</MenuItem>
                  </TextField>
                  <Typography color="error" className={classes.errorText}>
                    {errors.role}
                  </Typography>
                </Grid>
                {formData.role === 'Specialty' && (
                  <Grid item xs={12}>
                    <TextField
                      select
                      variant="outlined"
                      required
                      fullWidth
                      id="specialtyId"
                      label="Select your specialty"
                      name="specialtyId"
                      data-selector="select-specialty"
                      autoComplete="specialtyId"
                      value={formData.specialtyId}
                      onChange={onChangeSpecialtyId}
                      className={classnames('', {
                        invalid: errors.specialtyId,
                      })}
                    >
                      {specialtyFullList?.map((specialty) => (
                        <MenuItem key={specialty.value} value={specialty.id}>
                          {specialty.title}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography color="error" className={classes.errorText}>
                      {errors.specialtyId}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={onChange}
                    className={classnames('', {
                      invalid: errors.email,
                    })}
                  />
                  <Typography color="error" className={classes.errorText}>
                    {errors.email}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={onChange}
                    className={classnames('', {
                      invalid: errors.password,
                    })}
                  />
                  <Typography color="error" className={classes.errorText}>
                    {errors.password}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password2"
                    label="Confirm Password"
                    type="password"
                    id="password2"
                    autoComplete="current-password"
                    onChange={onChange}
                    className={classnames('', {
                      invalid: errors.password2,
                    })}
                  />
                  <Typography color="error" className={classes.errorText}>
                    {errors.password2}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <MuiPhoneNumber
                    name="phoneNumber"
                    label="Phone Number"
                    defaultCountry={'us'}
                    variant="outlined"
                    onChange={handlePhoneChange}
                    style={{ width: '100%' }}
                  />
                  <Typography color="error" className={classes.errorText}>
                    {errors.phoneNumber}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography //Not removing them for now
                    color="error"
                    className={classes.errorText}
                  >
                    {errors.isAdmin}
                  </Typography>
                </Grid>
                {isAdmin === true && (
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.submit}
                  >
                    Proceed to terms of services and BAA
                  </Button>
                )}
              </Grid>

              {isAdmin !== true && (
                <Button //Has to be hidden for Admin or by Default
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  data-selector="sign-up-btn"
                >
                  Sign Up
                </Button>
              )}
              <Grid container justifyContent="flex-end">
                <Grid item style={{ marginTop: 30 }}>
                  <Link style={{ padding: 0 }} href="/login" variant="body2">
                    <Typography>Already have an account? Sign in</Typography>
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default SignUpPage
