import { AnyAction } from 'redux'

import { READ_MESSAGES } from './types'

interface IReadMessages {
  id: string
  senderId: string
  receiverId: string
  created: string
  type: string
  isRead: boolean
  content: string
  inboxIsDeleted: boolean
  createdById: string
  lastUpdatedById: string
  caseDetailsId: string
  firstName: string
  lastName: string
}

export interface IChatReducer {
  readMessages: IReadMessages[]
}

const initialState: IChatReducer = {
  readMessages: [],
}
const chatReducer = (state = initialState, action: AnyAction): IChatReducer => {
  switch (action.type) {
    case READ_MESSAGES:
      return {
        ...state,
        readMessages: action.payload,
      }
    default:
      return state
  }
}

export default chatReducer
