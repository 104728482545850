import { enableAllPlugins } from 'immer'
import throttle from 'lodash.throttle'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
  applyMiddleware,
  CombinedState,
  combineReducers,
  createStore,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import { loadState, saveState } from '../utils/localStorage'
import authReducer, { IAuthReducer } from './auth/reducers'
import caseReducer, { ICaseReducer } from './case/reducers'
import chatReducer, { IChatReducer } from './chat/reducers'
import draftReducer, { IDraftReducer } from './draft/reducers'
import errorReducer from './error/reducers'
import snackbarReducer, { INotificationState } from './snackbar/reducers'

// https://immerjs.github.io/immer/installation/#pick-your-immer-version
enableAllPlugins()

const rootReducer = combineReducers({
  auth: authReducer,
  chat: chatReducer,
  case: caseReducer,
  draft: draftReducer,
  snackbar: snackbarReducer,
  errors: errorReducer,
})

export const reducers = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  state: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  action: any
): CombinedState<{
  auth: IAuthReducer
  chat: IChatReducer
  case: ICaseReducer
  draft: IDraftReducer
  snackbar: INotificationState
  errors: unknown
}> =>
  action.type === 'USER_LOGOUT'
    ? rootReducer(undefined, action)
    : rootReducer(state, action)

const middleware = [thunk]
const persistedState = loadState()
const store = createStore(
  reducers,
  persistedState,
  composeWithDevTools(
    applyMiddleware(...middleware)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
)

store.subscribe(
  throttle(() => {
    saveState({
      auth: store.getState().auth,
    })
  }, 1000)
)

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
