import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useAppSelector } from '../../store'
import NewCaseLayout from '../layouts/NewCaseLayout'

const InsideMessageRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  if (!component) {
    throw Error('Component is Undefined')
  }
  const auth = useAppSelector((state) => state.auth)
  const Component = component

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        auth.isAuthenticated === true ? (
          <NewCaseLayout>
            <Component {...matchProps} />
          </NewCaseLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

export default InsideMessageRoute
