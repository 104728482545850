import {
  Button,
  Card,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  Zoom,
} from '@material-ui/core'
import ArchiveIcon from '@material-ui/icons/Archive'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import Delete from '@material-ui/icons/Delete'
import { Alert } from '@material-ui/lab'
import { DataGrid, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import deepEqual from 'fast-deep-equal/es6/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'

import ConfirmationDialog from '../../shared/components/ConfirmationDialog'
import DataGridTableHeader from '../../shared/components/DataGridTableHeader'
import useDataGridColumns, {
  HideableDataGridColumns,
} from '../../shared/hooks/useDataGridColumns'
import { useAppDispatch, useAppSelector } from '../../shared/store'
import { SET_HINT_DATA } from '../../shared/store/auth/types'
import {
  closeCases,
  fetchAllCases,
  modifyCaseOnTableRowClick,
  setCurrentCase,
} from '../../shared/store/case/actions'
import {
  fetchAllDrafts,
  removeDraft,
  setCurrentDraft,
} from '../../shared/store/draft/actions'
import { enqueueSnackbar } from '../../shared/store/snackbar/actions'
import { ICase } from '../../shared/types'
import { sendAmplitudeData } from '../../shared/utils/amplitude'
import { amplitudeEvents, snackbarMessages } from '../../shared/utils/constant'
import { decodeString, encodeString } from '../../shared/utils/functions'
import { removeFileFromS3 } from '../../shared/utils/s3'
import { useOpenCasesPageStyles } from './OpenCasesPage.styles'

export type ISort = 'asc' | 'desc'
export interface ISearchResultsForCases {
  caseData: ICase
}
export interface ISearchResultsForDraft {
  draftData: ICase
}

interface IFileData {
  name: string
  type: string
}

const OpenCasesPage: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const [searchResultsForCases, setSearchResultsForCases] = useState<
    ISearchResultsForCases[]
  >([])
  const [searchResultsForDrafts, setSearchResultsForDrafts] = useState<
    ISearchResultsForDraft[]
  >([])
  const [confirmDraftDelete, setConfirmDraftDelete] = useState(false)
  const [confirmCloseCase, setConfirmCloseCase] = useState(false)
  const [infoAlert, setInfoAlert] = useState(true)
  const [referAlert, setReferAlert] = useState(true)
  const [filesDraft, setFilesDraft] = useState<IFileData[]>([])
  const [draftId, setDraftId] = useState('')
  const [selected, setSelected] = useState<string[]>([])
  const [caseIdExists, setCaseIdExists] = useState(false)

  const classes = useOpenCasesPageStyles()

  const authStore = useAppSelector((state) => state.auth)
  const currentCase = useAppSelector(
    (state) => state.case.currentCase,
    deepEqual
  )
  const readAllCases = useAppSelector(
    (state) => state.case.readAllCases,
    deepEqual
  )
  const readAllDrafts = useAppSelector(
    (state) => state.draft.readAllDrafts,
    deepEqual
  )
  const userdata = useAppSelector((state) => state.auth.userdata)
  const isShownHintIntegratedModal = useAppSelector(
    (state) => state.auth.hint.isShownHintIntegratedModal
  )
  const caseLoader = useAppSelector((state) => state.case.caseLoader)
  const draftLoader = useAppSelector((state) => state.draft.draftLoader)
  const loading = caseLoader || draftLoader

  const isWideScreen = useMediaQuery('(min-width: 960px)')
  const dispatch = useAppDispatch()

  useEffect(() => {
    const currentUserData = {
      currentUserId: userdata.id,
      currentUserRole: userdata.userType,
      currentUserPracticeId: userdata.practiceId,
      isDeleted: false,
      isCompleted: false,
    }
    dispatch(fetchAllCases(currentUserData as any))
  }, [dispatch, userdata?.id, userdata?.practiceId, userdata?.userType])

  useEffect(() => {
    setSearchResultsForCases(readAllCases)
  }, [readAllCases])

  useEffect(() => {
    const currentUserData = {
      currentUserId: userdata.id,
      currentUserRole: userdata.userType,
      currentUserPracticeId: userdata.practiceId,
      isDeleted: false,
      isCompleted: false,
    }
    if (userdata?.userType === 'PCP') {
      dispatch(fetchAllDrafts(currentUserData as any))
    }
  }, [dispatch, userdata.id, userdata.practiceId, userdata.userType])

  useEffect(() => {
    setSearchResultsForDrafts(readAllDrafts)
  }, [readAllDrafts])

  useEffect(() => {
    if (caseIdExists && currentCase && currentCase.caseData) {
      setCaseIdExists(false)
      handleTableRowClick(
        currentCase.caseData.caseId,
        false,
        currentCase.caseData
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseIdExists, currentCase])

  useEffect(() => {
    sendAmplitudeData(
      authStore.userdata.email,
      amplitudeEvents.INBOX_PAGE,
      authStore.userdata
    )

    if (location.search) {
      const url = new URL(window.location.href)
      const caseId = url.searchParams.get('caseId')
      if (caseId && !caseIdExists) {
        setCaseIdExists(true)
        localStorage.removeItem('caseId')
        const currentCaseCondition = {
          caseId,
          currentUserRole: authStore.userdata.userType,
        }
        dispatch(setCurrentCase(currentCaseCondition))
      }
    }
  }, [])

  const handleTableRowClick = useCallback(
    (id: string, isDraft: boolean, caseData: ICase) => {
      const eventData = { ...authStore.userdata, ...{ caseId: id } }
      sendAmplitudeData(
        authStore.userdata.email,
        amplitudeEvents.CASE_DETAILS_PAGE,
        eventData
      )

      if (isDraft) {
        dispatch(setCurrentDraft({ caseId: id }))
        history.push(`/edit-new-case-${id}`)
      } else {
        const newCaseData: any = {
          caseId: id,
          lastUpdatedById: authStore.userdata.id,
        }
        if (authStore.userdata.userType !== 'Admin') {
          // String comparison with Numer is not working with triple equals
          if (authStore.userdata.id == caseData.createdById) {
            newCaseData.isRead = true
            if (!caseData.isRead) {
              dispatch(modifyCaseOnTableRowClick(newCaseData))
            }
          } else {
            const readByOthers = caseData.isReadByOther
              ? caseData.isReadByOther
              : []
            if (!readByOthers.includes(authStore.userdata.id)) {
              readByOthers.push(authStore.userdata.id)
              newCaseData.isReadByOther = readByOthers
              dispatch(modifyCaseOnTableRowClick(newCaseData))
            }
          }
        }
        history.push({
          pathname: `/insidemessage/room_${id}`,
          state: { from: 'cases' },
        })
      }
    },
    [authStore.userdata, dispatch, history]
  )

  const onHandleSearch = (searchInput: string) => {
    if (searchInput !== '') {
      let resultsForCases: ISearchResultsForCases[] = [...readAllCases]
      let resultsForDrafts: ISearchResultsForDraft[] = [...readAllDrafts]

      if (searchInput !== 'All Specialties') {
        resultsForCases = readAllCases?.filter(
          (item: ISearchResultsForCases) =>
            (
              item.caseData?.specialty?.map((item) => item.title).join(', ') ||
              ''
            )
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            item.caseData?.patientName
              .toLowerCase()
              .includes(searchInput.toLowerCase())
        )

        resultsForDrafts = readAllDrafts?.filter(
          (item: ISearchResultsForDraft) =>
            (
              item.draftData?.specialty.map((item) => item.title).join(', ') ||
              ''
            )
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            item.draftData?.patientName
              .toLowerCase()
              .includes(searchInput.toLowerCase())
        )
      }

      setSearchResultsForCases(resultsForCases)
      setSearchResultsForDrafts(resultsForDrafts)
    } else {
      setSearchResultsForCases(readAllCases)
      setSearchResultsForDrafts(readAllDrafts)
    }
  }

  const handleDeleteDraft = async () => {
    setConfirmDraftDelete(false)
    if (filesDraft && filesDraft.length) {
      await Promise.all(
        filesDraft.map(async (fileData: IFileData, index) => {
          if (fileData && fileData.name) {
            await removeFileFromS3(fileData, index)
          }
        })
      )
    }

    dispatch(removeDraft({ caseId: draftId }))
  }

  const handleCloseConfirmDialog = () => {
    setConfirmDraftDelete(false)
    setConfirmCloseCase(false)
  }

  const openDraftDialog = useCallback((draftData: ICase) => {
    setConfirmDraftDelete(true)
    setDraftId(draftData.caseId)
    setFilesDraft(
      draftData.files && draftData.files.length ? draftData.files : []
    )
  }, [])

  const handleAssignConsult = useCallback(
    (id: string, specialty: string) => {
      history.push(`/assign-consult/case_${id}/specialty_${specialty}`)
    },
    [history]
  )

  const handleCloseSelectedCases = () => {
    setConfirmCloseCase(false)

    const dataToClose: {
      caseId: string
      specialistId: string
    }[] = caseDataRows
      .filter((item) => selected.includes(item.id))
      .map((item) => ({
        caseId: item.fullData.caseData.caseId,
        specialistId: item.fullData.caseData.latestAssignedSpecialistId,
      }))

    const caseData = {
      caseIds: dataToClose,
      lastUpdatedById: authStore.userdata.id,
      isRead: true,
      isDeleted: true,
      practiceId: authStore.userdata.practiceId,
    }

    dispatch(closeCases(caseData))
  }

  const copyInviteLink = () => {
    const params = {
      practiceId: authStore.userdata.practiceId,
      isAdmin: 'false',
    }

    const newParams = encodeString(JSON.stringify(params))
    const link =
      window.location.origin + '/register/identification/hash_' + newParams

    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(link)
    } else {
      document.execCommand('copy', true, link)
    }

    dispatch(
      enqueueSnackbar({
        message: snackbarMessages.INVITE_LINK_COPIES,
        options: {
          variant: 'success',
        },
      })
    )
  }

  const renderActions = useCallback(
    (params: GridRenderCellParams) => {
      return (
        <>
          {params.row.fullData.draftData && (
            <Tooltip TransitionComponent={Zoom} title="Delete Draft">
              <Delete
                onClick={(_event) =>
                  openDraftDialog(params.row.fullData.draftData)
                }
                style={{ cursor: 'pointer', margin: 8 }}
              />
            </Tooltip>
          )}
          {userdata.userType === 'Admin' &&
          (!params.row.fullData.caseData.latestAssignedSpecialistId ||
            params.row.fullData.caseData.latestAssignedSpecialistId === '0') ? (
            <Tooltip TransitionComponent={Zoom} title="Assign Specialist">
              <AssignmentIndOutlinedIcon
                color="action"
                data-selector="assign-specialist"
                onClick={() =>
                  handleAssignConsult(
                    params.row.fullData.caseData.caseId,
                    params.row.fullData.caseData.specialty
                  )
                }
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          ) : userdata.userType === 'Admin' &&
            (params.row.fullData.caseData.latestAssignedSpecialistId ||
              params.row.fullData.caseData.latestAssignedSpecialistId !==
                '0') ? (
            <Tooltip TransitionComponent={Zoom} title="Deselect Specialist">
              <AssignmentIndIcon
                color="primary"
                data-selector="unassign-specialist"
                onClick={() =>
                  handleAssignConsult(
                    params.row.fullData.caseData.caseId,
                    params.row.fullData.caseData.specialty
                  )
                }
                style={{ cursor: 'pointer', color: '#13c1cf' }}
              />
            </Tooltip>
          ) : null}
        </>
      )
    },
    [handleAssignConsult, openDraftDialog, userdata.userType]
  )

  const { columns } = useDataGridColumns(
    isWideScreen
      ? [HideableDataGridColumns.claimStatus]
      : [
          HideableDataGridColumns.question,
          HideableDataGridColumns.updatedAt,
          HideableDataGridColumns.claimStatus,
        ],
    renderActions,
    userdata
  )

  const caseDataRows = useMemo(
    () =>
      searchResultsForCases
        ?.map((item: any) => {
          let updatedAt = item.caseData.updatedAt
            ? item.caseData.updatedAt
            : item.caseData.updatedAtOld
          if (!item.caseData.updatedAt) {
            updatedAt = new Date(
              Number(item.caseData.updatedAtOld)
            ).toISOString()
          }
          return {
            id: item.caseData.id,
            fullData: item, // used only to track full object for selected item
            specialty: item.caseData.specialty
              ?.map((item) => item.title)
              .join(', '),
            patientName: item.caseData.patientName,
            question: decodeString(item.caseData.questions),
            updatedAt,
            specialistId: item.caseData.latestAssignedSpecialistId,
          }
        })
        .sort(
          (x, y) =>
            new Date(y.updatedAt).getTime() - new Date(x.updatedAt).getTime()
        ) || [],
    [searchResultsForCases]
  )

  if (userdata.userType !== 'Admin') {
    caseDataRows.sort(
      (x, y) =>
        Number(x.fullData.caseData.isRead) - Number(y.fullData.caseData.isRead)
    )
  }

  const draftDataRows = useMemo(
    () =>
      searchResultsForDrafts
        ?.map((item: any) => {
          let updatedAt = item.draftData.updatedAt
            ? item.draftData.updatedAt
            : item.draftData.updatedAtOld
          if (!item.draftData.updatedAt) {
            updatedAt = new Date(
              Number(item.draftData.updatedAtOld)
            ).toISOString()
          }
          return {
            id: item.draftData.id,
            fullData: item, // used only to track full object for selected item
            specialty: '0AlwaysFirstValue', // it is named as '0AlwaysFirstValue' to ensure draftS are always in first rows
            patientName: item.draftData.patientName,
            question: decodeString(item.draftData.questions),
            updatedAt,
            specialistId: item.draftData.latestAssignedSpecialistId,
          }
        })
        .sort(
          (x, y) =>
            new Date(y.updatedAt).getTime() - new Date(x.updatedAt).getTime()
        ) || [],
    [searchResultsForDrafts]
  )

  const rows = useMemo(
    () => [...draftDataRows, ...caseDataRows],
    [caseDataRows, draftDataRows]
  )

  const onCloseHintIntegratedModal = () => {
    dispatch({
      type: SET_HINT_DATA,
      payload: {
        isShownHintIntegratedModal: false,
      },
    })
  }
  return (
    <div className={classes.container}>
      {(!authStore.userdata.phoneNumber ||
        authStore.userdata.phoneNumber === '' ||
        authStore.userdata.phoneNumber === null) && (
        <Grid item xs={12}>
          <Collapse in={infoAlert}>
            <Alert
              severity="info"
              onClose={() => {
                setInfoAlert(false)
              }}
            >
              <Typography style={{ fontSize: 'inherit' }}>
                Add your phone number to recieve text notifications —{' '}
                <Link
                  to="/account"
                  style={{ color: 'inherit', fontWeight: 'bold' }}
                >
                  click here
                </Link>{' '}
                to update!
              </Typography>
            </Alert>
          </Collapse>
        </Grid>
      )}

      <Grid item xs={12}>
        <Collapse in={referAlert}>
          <Alert
            severity="info"
            onClose={() => {
              setReferAlert(false)
            }}
          >
            <Typography style={{ fontSize: 'inherit' }}>
              Want to invite local specialists to join Thea Health?{' '}
              <Link
                to="#"
                style={{ color: 'inherit', fontWeight: 'bold' }}
                onClick={copyInviteLink}
              >
                Click here
              </Link>{' '}
              to copy the invite link!
            </Typography>
          </Alert>
        </Collapse>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        spacing={0}
        className={classes.cardContainer}
      >
        <Grid item xs={12}>
          <Card
            style={{
              backgroundColor: '#ffffff',
              padding: 20,
              overflow: 'auto',
              height: '100%',
            }}
          >
            <DataGridTableHeader
              title="Open Cases"
              onHandleSearch={onHandleSearch}
            />

            <div className={classes.root} style={{ width: '100%' }}>
              <Paper
                className={classes.paper}
                style={{ height: '100%', width: '100%' }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  style={{
                    height: '50px',
                    visibility:
                      rows.length && userdata.userType === 'PCP'
                        ? 'visible'
                        : 'hidden',
                  }}
                >
                  {Boolean(
                    !draftLoader &&
                      !caseLoader &&
                      rows.length > 0 &&
                      userdata.userType === 'PCP' &&
                      selected.length
                  ) && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <label
                        style={{
                          marginRight: 15,
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: '15px',
                          cursor: 'pointer',
                        }}
                        onClick={() => setConfirmCloseCase(true)}
                      >
                        <span style={{ float: 'left' }}>
                          <ArchiveIcon style={{ margin: '8px 8px 8px 0' }} />
                        </span>
                        <span
                          data-selector="close-cases-toolbar"
                          style={{
                            float: 'left',
                            marginTop: 10,
                            fontSize: '1rem',
                          }}
                        >
                          Close Cases
                        </span>
                      </label>
                    </Grid>
                  )}
                </Grid>
                <DataGrid
                  className={classes.dataGrid}
                  rows={!loading ? rows : []}
                  columns={columns}
                  loading={loading}
                  autoPageSize
                  sortingOrder={['asc', 'desc']}
                  onRowClick={(data) => {
                    handleTableRowClick(
                      data.row.fullData.draftData
                        ? data.row.fullData.draftData.caseId
                        : data.row.fullData.caseData.caseId,
                      data.row.fullData.draftData ? true : false,
                      data.row.fullData.caseData && data.row.fullData.caseData
                    )
                  }}
                  onCellClick={(params, event) => {
                    if (params.colDef.headerName === 'Actions') {
                      event.stopPropagation()
                    }
                  }}
                  isRowSelectable={(params: GridRowParams) =>
                    params.row.specialty !== '0AlwaysFirstValue'
                  }
                  onSelectionModelChange={(newSelection, details) => {
                    setSelected(newSelection as string[])
                  }}
                  checkboxSelection
                  disableSelectionOnClick
                  disableColumnSelector
                />
              </Paper>
            </div>
          </Card>
        </Grid>
      </Grid>
      <ConfirmationDialog
        message="Are you sure you want to delete this draft?"
        title="Delete Draft"
        okOperationDialog={handleDeleteDraft}
        handleClose={handleCloseConfirmDialog}
        dialogState={confirmDraftDelete}
      />

      <ConfirmationDialog
        message="Are you sure you want to close selected cases?"
        title="Close Cases"
        okOperationDialog={handleCloseSelectedCases}
        handleClose={handleCloseConfirmDialog}
        dialogState={confirmCloseCase}
      />
      {/*
          https://github.com/mui/material-ui/issues/13394,
          Dialog shows error in console, should be solved in v5.
      */}
      <Dialog
        onClose={onCloseHintIntegratedModal}
        open={isShownHintIntegratedModal}
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          <div>Hint Integration Complete!</div>
          <div>We will now automatically sync your patients with Hint.</div>
        </DialogTitle>
        <DialogActions>
          <Button onClick={onCloseHintIntegratedModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default OpenCasesPage
