import {
  Hidden,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
} from '@material-ui/core'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import DescriptionIcon from '@material-ui/icons/Description'
import PersonIcon from '@material-ui/icons/Person'
import React from 'react'

import { useStepsStyles } from './Steps.styles'

interface ISteps {
  stepActive: number
}

const Steps: React.FC<ISteps> = ({ stepActive }) => {
  const classes = useStepsStyles()
  const steps = ['Sign Up', 'Terms of Service and BAA', 'Payment']

  const stepIcon = (i: number) => {
    const icons = [
      <PersonIcon key={i} />,
      <DescriptionIcon key={i} />,
      <CreditCardIcon key={i} />,
    ]
    return (
      <div
        className={`${classes.stepIcon} ${
          i === stepActive ? classes.stepActive : ''
        }`}
      >
        {icons[i]}
      </div>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <Hidden smDown>
        <Stepper
          orientation="vertical"
          className={classes.stepperMain}
          connector={
            <StepConnector
              classes={{
                line: classes.connectorLine,
                vertical: classes.connectorVertical,
              }}
            />
          }
        >
          {steps.map((label, i) => (
            <Step key={label}>
              <StepLabel
                classes={{
                  active: classes.stepLabelActive,
                }}
                icon={stepIcon(i)}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Hidden>
      <Hidden mdUp>
        <Stepper
          connector={
            <StepConnector
              classes={{
                horizontal: classes.connectorHorizontal,
              }}
            />
          }
          alternativeLabel
          style={{ padding: 0 }}
        >
          {steps.map((label, i) => (
            <Step key={label} style={{ padding: 0 }}>
              <StepLabel
                classes={{
                  active: classes.stepLabelActive,
                }}
                icon={stepIcon(i)}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Hidden>
    </div>
  )
}

export default Steps
