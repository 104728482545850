import { Hidden } from '@material-ui/core'
import React from 'react'
import { useLocation } from 'react-router-dom'

import CreateButton from '../../../components/CreateButton/CreateButton'
import Header from '../Header/Header'
import HelpButton from '../Help/HelpButton'
import InboxSideBar from '../Sidebar/InboxSideBar'
import { useInboxLayoutStyles } from './Layout.styles'

interface IInboxLayoutProps {
  children: React.ReactNode
}
const InboxLayout: React.FC<IInboxLayoutProps> = ({ children }) => {
  const classes = useInboxLayoutStyles()
  const location = useLocation()

  const routeName = location.pathname

  return (
    <div data-selector="inbox-layout" className={classes.root}>
      <Header />
      <CreateButton />
      <Hidden smDown>
        <InboxSideBar routeName={routeName} />
      </Hidden>
      <main className={classes.content}>{children}</main>
      <HelpButton />
    </div>
  )
}
export default InboxLayout
