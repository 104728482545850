import produce from 'immer'
import { isNil } from 'lodash'
import { AnyAction } from 'redux'

import { isEmpty } from '../../utils/functions'
import {
  ACTION_PERFORMED,
  CLIENT_DATA,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_REQUEST,
  NEW_ACCOUNT,
  NEW_ACCOUNT_REQUEST,
  PRACTICE_DATA,
  PRACTICE_DATA_REQUEST,
  PRACTICE_MEMBER_DATA,
  SET_CURRENT_USER,
  SET_HINT_DATA,
  SET_SPECIALTY_FULL_LIST,
  STRIPE_DATA,
  STRIPE_DATA_REQUEST,
  TOTAL_PRACTICE_MEMBER,
  USER_DATA,
  USER_LOADING,
} from './types'

// TODO: instead of any type, add correct TS types
export interface IAuthReducer {
  isAuthenticated: boolean
  user: any
  userdata: any
  clientdata: any
  loading: boolean
  forgotPasswordState: boolean
  specialtyData: any[]
  practiceData: any
  practiceMemberData:
    | {
        id: number
        practiceId: number
        firstName: string
        lastName: string
        email: string
        userType: string
        specialty: string
        active: boolean
        isAdmin: boolean
      }
    | any
  stripeData: any
  isActionPerformed: boolean
  totalPracticeMember: string
  specialtyFullList: {
    id: string
    value: string
    title: string
    status: number
    defaultSpecialistId: number
  }[]
  hint: {
    isHintIntegrated: boolean
    isShownHintIntegratedModal: boolean
  }
}

const initialState: IAuthReducer = {
  isAuthenticated: false,
  user: {},
  userdata: {},
  clientdata: {},
  loading: false,
  forgotPasswordState: false,
  specialtyData: [],
  practiceData: {},
  practiceMemberData: {},
  stripeData: {},
  isActionPerformed: false,
  totalPracticeMember: '0',
  hint: {
    isHintIntegrated: false,
    isShownHintIntegratedModal: false,
  },
  specialtyFullList: [],
}
const authReducer = (state = initialState, action: AnyAction): IAuthReducer => {
  switch (action.type) {
    case SET_SPECIALTY_FULL_LIST:
      return {
        ...state,
        specialtyFullList: action.payload.filter(
          (spec) => spec.value !== 'All Specialties'
        ),
      }
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        clientdata: {},
        user: action.payload,
      }
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case USER_DATA:
      return {
        ...state,
        userdata: action.payload,
      }
    case PRACTICE_DATA:
      return produce(state, (draftState) => {
        draftState.hint.isHintIntegrated = action.payload.isHintIntegrated
        draftState.practiceData = action.payload
        draftState.loading = false
      })
    case PRACTICE_DATA_REQUEST:
      return {
        ...state,
        loading: action.payload,
      }
    case STRIPE_DATA:
      return {
        ...state,
        stripeData: action.payload,
        loading: false,
      }
    case STRIPE_DATA_REQUEST:
      return {
        ...state,
        loading: action.payload,
      }
    case CLIENT_DATA:
      return {
        ...state,
        clientdata: action.payload,
      }
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordState: action.payload,
        loading: false,
      }
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordState: false,
        loading: action.payload,
      }
    case NEW_ACCOUNT:
      return {
        ...state,
        // newAccountState: action.payload,
        loading: false,
      }
    case NEW_ACCOUNT_REQUEST:
      return {
        ...state,
        // newAccountState: false,
        loading: action.payload,
      }
    case ACTION_PERFORMED:
      return {
        ...state,
        isActionPerformed: action.payload,
      }
    case PRACTICE_MEMBER_DATA:
      return {
        ...state,
        practiceMemberData: action.payload,
      }
    case TOTAL_PRACTICE_MEMBER:
      return {
        ...state,
        totalPracticeMember: action.payload,
      }
    case SET_HINT_DATA:
      return produce(state, (draftState) => {
        const { isShownHintIntegratedModal, isHintIntegrated } = action.payload
        if (!isNil(isShownHintIntegratedModal)) {
          draftState.hint.isShownHintIntegratedModal =
            isShownHintIntegratedModal
        }
        if (!isNil(isHintIntegrated)) {
          draftState.hint.isHintIntegrated = isHintIntegrated
        }
      })
    default:
      return state
  }
}
export default authReducer
