import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  Paper,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import SendIcon from '@material-ui/icons/Send'
import clsx from 'clsx'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../../../shared/store'
import {
  readMessages,
  sendChatMessage,
} from '../../../shared/store/chat/actions'
import { sendAmplitudeData } from '../../../shared/utils/amplitude'
import { amplitudeEvents } from '../../../shared/utils/constant'
import {
  decodeString,
  encodeString,
  isEmpty,
} from '../../../shared/utils/functions'
import { socketInstanceClient } from '../../../shared/utils/socket'
import { useChatStyles } from './Chat.styles'

export interface IOnMessageReceivedEntry {
  id: string
  senderId: string
  firstName: string
  lastName: string
  content: string
  created: string
}

const Chat: React.FC<{ channelId: string }> = ({ channelId }) => {
  const dispatch = useAppDispatch()

  const userdata = useAppSelector((state) => state.auth.userdata)
  const readedMessages = useAppSelector((state) => state.chat.readMessages)
  const currentCase = useAppSelector((state) => state.case.currentCase)
  const caseLoader = useAppSelector((state) => state.case.caseLoader)

  const [messageList, setMessageList] = useState<IOnMessageReceivedEntry[]>([])
  const [messageText, setMessageText] = useState('')
  const [openAlert, setOpenAlert] = useState<boolean>(false)

  const classes = useChatStyles()

  const chatPanelRef = useRef<HTMLDivElement | undefined>(undefined)

  useEffect(() => {
    dispatch(
      readMessages({
        channelId,
        type: 'message',
      })
    )
    socketInstanceClient.join(channelId)
  }, [channelId, dispatch])

  useEffect(() => {
    setMessageList(readedMessages)
  }, [readedMessages])

  useEffect(() => {
    socketInstanceClient.registerHandler((entry: IOnMessageReceivedEntry) => {
      setMessageList((prevState) => [...prevState, entry])
    })
    return () => {
      socketInstanceClient.unregisterHandler()
    }
  }, [channelId, dispatch, readedMessages])

  useEffect(() => {
    if (chatPanelRef.current) {
      chatPanelRef.current.scrollTo(0, chatPanelRef.current.scrollHeight)
    }
  }, [messageList.length])

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageText(event.target.value)
  }

  const onMessageSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (messageText === '') {
      return
    }

    const eventData = { ...userdata, ...{ caseId: channelId } }
    sendAmplitudeData(
      userdata.email,
      amplitudeEvents.CASE_MESSAGE_SENT,
      eventData
    )

    dispatch(
      sendChatMessage({
        channelId,
        type: 'message',
        content: encodeString(messageText),
      })
    )
    setMessageText('')
  }

  const handleClose = () => {
    setOpenAlert(false)
  }

  const userAvatar = currentCase.senderData?.avatar
    ? currentCase.senderData.avatar.name
    : null

  return (
    <div className={classes.root} data-selector="chat-panel">
      <Grid
        container
        justifyContent="flex-start"
        style={{
          padding: 10,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {userAvatar ? (
          <Avatar src={currentCase.senderData.avatar.name} />
        ) : (
          <PersonIcon style={{ marginTop: 0 }} />
        )}
        {!isEmpty(currentCase) && (
          <Typography className={classes.chatSpecialist}>
            {currentCase.caseData?.specialty
              ?.map((item) => item.title)
              .join(', ')}{' '}
            Specialist
          </Typography>
        )}
      </Grid>
      <Divider />
      <Grid container justifyContent="center" style={{ height: '89%' }}>
        {!isEmpty(currentCase) && (
          <Typography
            className={classes.chatPatient}
            style={{ margin: 10, textAlign: 'center' }}
          >
            {currentCase.caseData?.patientName}{' '}
            {currentCase.caseData?.specialty
              ?.map((item) => item.title)
              .join(', ')}{' '}
            Consultation
          </Typography>
        )}
        <Paper className={classes.chattingBox} ref={chatPanelRef}>
          {messageList.map((item) => {
            if (String(item.senderId) === userdata.id) {
              return (
                <div
                  key={item.id}
                  style={{
                    display: 'inline-block',
                    marginTop: 15,
                    marginBottom: 10,
                    width: '100%',
                  }}
                >
                  {item.content ? (
                    <div className={classes.sendMessageItemWrapper}>
                      <label className={classes.senderNameLabel}>Me</label>
                      <Typography
                        variant="inherit"
                        className={classes.senderMessageContainer}
                      >
                        {decodeString(item.content)}
                      </Typography>
                      <Typography className={classes.senderDate}>
                        {moment(item.created).format('lll')}
                      </Typography>
                    </div>
                  ) : null}
                </div>
              )
            } else {
              return (
                <div
                  key={item.id}
                  style={{
                    display: 'inline-block',
                    marginTop: 15,
                    marginBottom: 10,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  {item.content ? (
                    <div className={classes.receiveMessageItemWrapper}>
                      {!isEmpty(currentCase) && (
                        <label className={classes.receiveNameLabel}>
                          {item.firstName + ' ' + item.lastName}
                        </label>
                      )}
                      <Typography
                        variant="inherit"
                        className={classes.receiveMessageContainer}
                      >
                        {decodeString(item.content)}
                      </Typography>
                      <Typography className={classes.receiverDate}>
                        {moment(item.created).format('lll')}
                      </Typography>
                    </div>
                  ) : null}
                </div>
              )
            }
          })}
        </Paper>
        <form
          onSubmit={onMessageSubmit}
          className={clsx(classes.bottom, 'py-16 px-8')}
        >
          <Grid container justifyContent="center" spacing={0}>
            <Grid item xs={10} sm={11} md={11}>
              <Paper
                className={clsx(
                  classes.inputWrapper,
                  'flex items-center relative'
                )}
              >
                <TextField
                  autoFocus={false}
                  autoComplete="off"
                  id="message-input"
                  data-selector="message-input"
                  className={clsx('flex-1')}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: 'flex flex-grow flex-shrink-0 ml-16 mr-48 my-8',
                      input: '',
                    },
                    placeholder: 'Type your message',
                  }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  onChange={onInputChange}
                  style={{ width: '100%' }}
                  value={messageText}
                  multiline
                  minRows={4}
                  maxRows={10}
                />
              </Paper>
            </Grid>
            <Grid item xs={2} sm={1} md={1} style={{ position: 'relative' }}>
              <IconButton
                disabled={caseLoader}
                className="absolute right-0 top-0"
                type="submit"
                data-selector="send-icon"
                style={{ position: 'absolute', bottom: 0 }}
              >
                <SendIcon className="text-24" color="action" />
              </IconButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Dialog
        open={openAlert}
        TransitionComponent={Slide}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            No specialist assigned to this case yet. Please try later!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            className={classes.buttonCenter}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Chat
