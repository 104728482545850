import { SnackbarKey, useSnackbar } from 'notistack'
import React from 'react'

import { useAppDispatch, useAppSelector } from '../../store'
import { removeSnackbarFromRedux } from '../../store/snackbar/actions'
import { INotification } from '../../store/snackbar/reducers'

let displayedSnackbarKeys: SnackbarKey[] = []

const SnackbarNotifier: React.FC = () => {
  const dispatch = useAppDispatch()
  const notifications = useAppSelector((state) => state.snackbar.notifications)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayedSnackbar = (id: SnackbarKey) => {
    displayedSnackbarKeys = [...displayedSnackbarKeys, id]
  }

  const removeDisplayedSnackbar = (id: SnackbarKey) => {
    displayedSnackbarKeys = [
      ...displayedSnackbarKeys.filter((key: SnackbarKey) => id !== key),
    ]
  }

  React.useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }: INotification) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key)
          return
        }

        // do nothing if snackbar is already displayed
        if (displayedSnackbarKeys.includes(key)) return

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey)
            }
          },
          onExited: (_event, myKey: SnackbarKey) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbarFromRedux(myKey))
            removeDisplayedSnackbar(myKey)
          },
        })

        // keep track of snackbars that we've displayed
        storeDisplayedSnackbar(key)
      }
    )
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return null
}

export default SnackbarNotifier
