import { makeStyles, Theme } from '@material-ui/core'

export const useTermServiceStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  errorText: {
    fontSize: 12,
    fontWeight: 500,
  },
}))
