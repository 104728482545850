import {
  Card,
  Grid,
  Paper,
  Tooltip,
  useMediaQuery,
  Zoom,
} from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { usePrevious } from 'react-use'

import ConfirmationDialog from '../../shared/components/ConfirmationDialog'
import DataGridTableHeader from '../../shared/components/DataGridTableHeader'
import useDataGridColumns, {
  HideableDataGridColumns,
} from '../../shared/hooks/useDataGridColumns'
import { useAppDispatch, useAppSelector } from '../../shared/store'
import {
  fetchAllDrafts,
  removeDraft,
  removeDraftByIds,
  setCurrentDraft,
} from '../../shared/store/draft/actions'
import { sendAmplitudeData } from '../../shared/utils/amplitude'
import { amplitudeEvents } from '../../shared/utils/constant'
import { decodeString } from '../../shared/utils/functions'
import { removeFileFromS3 } from '../../shared/utils/s3'
import { ISearchResultsForDraft } from '../OpenCasesPage/OpenCasesPage'
import { useDraftCasesPageStyles } from './DraftCasesPage.styles'

const DraftCasesPage: React.FC<RouteComponentProps> = ({ history }) => {
  const [searchResultsForDrafts, setSearchResultsForDrafts] = useState<
    ISearchResultsForDraft[]
  >([])
  const [isConfirmDraftDeleteOpened, setIsConfirmDraftDeleteOpened] =
    useState(false)
  const [isConfirmBulkDraftDeleteOpened, setIsConfirmBulkDraftDeleteOpened] =
    useState(false)

  const [clickedDraft, setClickedDraft] = useState<any>()
  const [selectedDrafts, setSelectedDrafts] = useState<string[]>([])

  const userdata = useAppSelector((state) => state.auth.userdata)
  const readAllDrafts = useAppSelector(
    (state) => state.draft.readAllDrafts,
    shallowEqual
  )
  const caseLoader = useAppSelector((state) => state.case.caseLoader)
  const draftLoader = useAppSelector((state) => state.draft.draftLoader)
  const loading = caseLoader || draftLoader

  const classes = useDraftCasesPageStyles()
  const dispatch = useAppDispatch()
  const prevReadAllDrafts = usePrevious(readAllDrafts)
  const isWideScreen = useMediaQuery('(min-width: 960px)')

  useEffect(() => {
    if (JSON.stringify(prevReadAllDrafts) !== JSON.stringify(readAllDrafts)) {
      setSearchResultsForDrafts(readAllDrafts)

      sendAmplitudeData(userdata.email, amplitudeEvents.DRAFT_PAGE, userdata)

      const currentUserData = {
        currentUserId: userdata.id,
        currentUserRole: userdata.userType,
        currentUserPracticeId: userdata.practiceId,
        isDeleted: false,
        isCompleted: false,
      }
      dispatch(fetchAllDrafts(currentUserData))
    }
  }, [
    dispatch,
    prevReadAllDrafts,
    readAllDrafts,
    userdata.id,
    userdata.userType,
    userdata.practiceId,
    userdata,
  ])

  const handleTableRowClicked = (id: any) => {
    dispatch(setCurrentDraft({ caseId: id }))
    history.push({
      pathname: `/edit-new-case-${id}`,
    })
  }

  const onHandleSearch = (searchInput: string) => {
    if (searchInput !== '') {
      let resultsForDrafts: ISearchResultsForDraft[] = [...readAllDrafts]

      if (searchInput !== 'All Specialties') {
        resultsForDrafts = readAllDrafts.filter(
          (item: ISearchResultsForDraft) =>
            (item.draftData?.specialty || '')
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            item.draftData?.patientName
              .toLowerCase()
              .includes(searchInput.toLowerCase())
        )
      }
      setSearchResultsForDrafts(resultsForDrafts)
    } else {
      setSearchResultsForDrafts(readAllDrafts)
    }
  }

  const handleRemoveDraft = async () => {
    setIsConfirmDraftDeleteOpened(false)
    if (clickedDraft.files && clickedDraft.files.length) {
      clickedDraft.files.map(async (fileData: any, index: number) => {
        if (fileData && fileData.name) {
          await removeFileFromS3(fileData, index)
        }
      })
    }
    dispatch(removeDraft({ caseId: clickedDraft.caseId }))
  }

  const renderActions = useCallback((params: GridRenderCellParams) => {
    return (
      <>
        {params.row.fullData.draftData && (
          <Tooltip TransitionComponent={Zoom} title="Delete Draft">
            <Delete
              onClick={() => {
                setClickedDraft(params.row.fullData.draftData)
                setIsConfirmDraftDeleteOpened(true)
              }}
              style={{ cursor: 'pointer', margin: 8 }}
            />
          </Tooltip>
        )}
      </>
    )
  }, [])

  const { columns } = useDataGridColumns(
    isWideScreen
      ? [HideableDataGridColumns.claimStatus]
      : [
          HideableDataGridColumns.question,
          HideableDataGridColumns.updatedAt,
          HideableDataGridColumns.claimStatus,
        ],
    renderActions,
    userdata
  )

  const rows = useMemo(
    () =>
      searchResultsForDrafts
        ?.map((item: any) => {
          let updatedAt = item.draftData.updatedAt
            ? item.draftData.updatedAt
            : item.draftData.updatedAtOld
          if (!item.draftData.updatedAt) {
            updatedAt = new Date(
              Number(item.draftData.updatedAtOld)
            ).toISOString()
          }
          return {
            id: item.draftData.id,
            fullData: item, // used only to track full object for selected item
            specialty: '0AlwaysFirstValue', // it is named as '0AlwaysFirstValue' to ensure Draft is always in first rows
            patientName: item.draftData.patientName,
            question: decodeString(item.draftData.questions),
            updatedAt,
          }
        })
        .sort(
          (x, y) =>
            new Date(y.updatedAt).getTime() - new Date(x.updatedAt).getTime()
        ) || [],
    [searchResultsForDrafts]
  )

  const handleBulkDelete = () => {
    setIsConfirmBulkDraftDeleteOpened(false)
    const selectedRows = rows.filter((item) => selectedDrafts.includes(item.id))
    selectedRows.map(async (item: any, index: number) => {
      if (item.fullData.draftData.files.length) {
        item.fullData.draftData.files.map(async (fileData: any, index: any) => {
          if (fileData && fileData.name) {
            await removeFileFromS3(fileData, index)
          }
        })
      }
    })
    dispatch(
      removeDraftByIds({
        caseIds: selectedRows.map((item) => item.fullData.draftData.caseId),
      })
    )
  }

  return (
    <div className={classes.container}>
      <Grid container justifyContent="flex-start" spacing={0}>
        <Grid item xs={12}>
          <Card
            style={{
              backgroundColor: '#ffffff',
              padding: 20,
              overflow: 'auto',
              height: '100%',
            }}
          >
            <DataGridTableHeader
              title="Drafts"
              onHandleSearch={onHandleSearch}
            />

            <div className={classes.root} style={{ width: '100%' }}>
              <Paper
                className={classes.paper}
                style={{ height: '100%', width: '100%' }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  style={{
                    height: '50px',
                    visibility:
                      rows.length && userdata.userType === 'PCP'
                        ? 'visible'
                        : 'hidden',
                  }}
                >
                  {Boolean(
                    !draftLoader &&
                      !caseLoader &&
                      rows.length > 0 &&
                      userdata.userType === 'PCP' &&
                      selectedDrafts.length
                  ) && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <label
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: '15px',
                          cursor: 'pointer',
                        }}
                        onClick={() => setIsConfirmBulkDraftDeleteOpened(true)}
                      >
                        <span style={{ float: 'left' }}>
                          <Delete
                            style={{
                              margin: '8px 8px 8px 0',
                            }}
                          />
                        </span>
                        <span
                          style={{
                            float: 'left',
                            marginTop: 10,
                            fontSize: '1rem',
                          }}
                        >
                          Delete Cases
                        </span>
                      </label>
                    </Grid>
                  )}
                </Grid>
                <DataGrid
                  className={classes.dataGrid}
                  rows={!loading ? rows : []}
                  columns={columns}
                  loading={loading}
                  autoPageSize
                  sortingOrder={['asc', 'desc']}
                  onRowClick={(data) => {
                    handleTableRowClicked(data.row.fullData.draftData.caseId)
                  }}
                  onCellClick={(params, event) => {
                    if (params.colDef.headerName === 'Actions') {
                      event.stopPropagation()
                    }
                  }}
                  onSelectionModelChange={(newSelection, details) => {
                    setSelectedDrafts(newSelection as string[])
                  }}
                  checkboxSelection
                  disableSelectionOnClick
                  disableColumnSelector
                />
              </Paper>
            </div>
          </Card>
        </Grid>
      </Grid>
      <ConfirmationDialog
        message="Are you sure you want to delete this draft?"
        title="Delete Draft"
        okOperationDialog={handleRemoveDraft}
        handleClose={() => {
          setIsConfirmDraftDeleteOpened(false)
        }}
        dialogState={isConfirmDraftDeleteOpened}
      />

      <ConfirmationDialog
        message="Are you sure you want to delete selected draft?"
        title="Delete Selected Drafts"
        okOperationDialog={handleBulkDelete}
        handleClose={() => {
          setIsConfirmBulkDraftDeleteOpened(false)
        }}
        dialogState={isConfirmBulkDraftDeleteOpened}
      />
    </div>
  )
}

export default DraftCasesPage
