import {
  Container,
  CssBaseline,
  Divider,
  Grid,
  Link,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import textLogo from '../../shared/assets/images/text-logo.png'
import ReferAFriend from '../Refer/Refer'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(4, 0, 6, 3),
    // backgroundColor: "#1ce456"
  },
  cardHeader: {
    // backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    backgroundColor: '#1cacc7',
    color: '#fff',
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    margin: theme.spacing(4),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  cardWhole: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'baseline',
    // marginBottom: theme.spacing(2),
    backgroundColor: '#1cacc7',
  },
  MainContent: {
    maxWidth: 500,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorText: {
    fontSize: 12,
    fontWeight: '500',
  },
}))

export function ReferFriendPage() {
  const classes = useStyles()

  return (
    <Container component="main" className={classes.MainContent}>
      <CssBaseline />
      <div className={classes.paper}>
        <div style={{ display: 'flex' }}>
          <img
            src={textLogo}
            alt="required"
            style={{ width: '100%', maxWidth: '350px' }}
          />
        </div>

        <Typography
          style={{ paddingBottom: 20, paddingTop: 15 }}
          component="h1"
          variant="h5"
        >
          Refer Thea Health to your friend
        </Typography>
        <Divider />

        <Typography
          style={{ paddingBottom: 0 }}
          component="h1"
          variant="subtitle1"
        >
          Invite other providers to join and you both earn a month of free
          subscription!
        </Typography>

        <Divider />

        <Container maxWidth="md" component="main">
          <Grid container spacing={0} alignItems="flex-end">
            <ReferAFriend />
          </Grid>
        </Container>

        <Grid container justifyContent="center">
          <Grid item style={{ marginTop: 30 }}>
            <Link style={{ padding: 0 }} href="/login" variant="body2">
              <Typography>Skip to your account/LogIn</Typography>
            </Link>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

ReferFriendPage.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth.userdata,
})

export default connect(mapStateToProps, null)(ReferFriendPage)
